import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from '../AnalyticsSteps'
import SalesforceWriteParams from './SalesforceWriteParams'
import SftpWriteParams from './SftpWriteParams'
import SftpReadParams from './SftpReadParams'

const SftpConnector: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {
    const [connectorAction, setConnectorAction] = useState('write')

    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                setConnectorAction(_model.config.params.analytics[stepIndex].params.connectorAction)
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='font-bold text-ai-700'>SFTP</p>
            {/* Connector Type & Action */}
            <div>
                <p className='text-[13px] text-ai'> Connector Action*</p>
                <div className='flex gap-5'>
                    <div className='relative flex items-center'>
                        <input
                            className='radio-parent peer'
                            name='connectorAction'
                            id='connectorActionRead'
                            type='radio'
                            value='read'
                            checked={connectorAction === 'read'}
                            onChange={e => {
                                setConnectorAction('read')
                            }}
                        />
                        <div className='radio' />
                        <label htmlFor='connectorActionRead' className='cursor-pointer truncate pl-3'>
                            Read
                        </label>
                    </div>

                    <div className='relative flex items-center'>
                        <input
                            className='radio-parent peer'
                            name='connectorAction'
                            id='connectorActionWrite'
                            type='radio'
                            value='write'
                            checked={connectorAction === 'write'}
                            onChange={e => {
                                setConnectorAction('write')
                            }}
                        />
                        <div className='radio' />
                        <label htmlFor='connectorActionWrite' className='cursor-pointer truncate pl-3'>
                            Write
                        </label>
                    </div>
                </div>
            </div>
            {connectorAction === 'read' && (
                <div className=''>
                    <SftpReadParams _model={_model} onSaveChanges={onSaveChanges} onCancel={onCancel} stepIndex={stepIndex} />
                </div>
            )}
            {connectorAction === 'write' && (
                <SftpWriteParams _model={_model} onSaveChanges={onSaveChanges} onCancel={onCancel} stepIndex={stepIndex} />
            )}
        </div>
    )
}

export default SftpConnector
