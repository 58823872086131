import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const useVersionUpgrade = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (variables: { modelId: string }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                await axios.post(`/upload/v1/models`, { parentId: variables.modelId }, options);
            }
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['workspace'])
            queryClient.invalidateQueries(['workspace', variables.modelId])
        }
    })
}

export default useVersionUpgrade
