import React from 'react'
import ModelDash from './ModelDash'
import { useParams } from 'react-router-dom'
import { useGetModel } from '../../features/queries'
import ModelDashGenerativeFeedback from './ModelDashGenerativeFeedback'

const ModelGenerativeFeedback: React.FC<{}> = () => {
    const { id } = useParams()
    const modelQuery = useGetModel(id)

    const model = modelQuery.data

    if (!model) {
        return null
    }

    return (
        <ModelDash>
            <ModelDashGenerativeFeedback model={model} />
        </ModelDash>
    )
}

export default ModelGenerativeFeedback