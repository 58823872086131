import React from 'react'
import StepProps from '../../features/types'
import Checkbox from '../Checkbox'
import Dropdown from '../Dropdown'

const methodOpts = ["bilinear", "lanczos2", "bicubic", "gaussian", "nearest", "mitchellcubic"]

const ResizeStep: React.FC<StepProps> = ({ _model, json, step, editing, index, onConfigChange }) => {

    const [params, setParams] = React.useState({
        size: [0, 0],
        method: '',
        preserveAspectRatio: false
    })

    React.useEffect(() => {
        if (editing) {
            setParams({
                size: step.params.size,
                method: step.params.method,
                preserveAspectRatio: step.params.preserveAspectRatio
            })
        }
    }, [step])

    if (!_model) {
        return null
    }

    const onAddStep = (e: any) => {
        let _config = { ...json }
        if (_model) {
            const step = {
                className: 'Resize',
                params
            }
            if (editing && index !== null && index !== undefined && _config.params.preprocessingSteps) {
                _config.params.preprocessingSteps[0].steps[index] = { ...step }
            } else {
                if (!_config.params.preprocessingSteps || _config.params.preprocessingSteps.length === 0) {
                    _config.params.preprocessingSteps = []
                    _config.params.preprocessingSteps.push({
                        className: 'ImagePreprocessor',
                        steps: []
                    })
                }
                _config.params.preprocessingSteps[0].steps.push(step)
            }
            onConfigChange(_config)
        }
    }

    const onCheckParamsChange = (e: any) => {
        setParams({
            ...params,
            [e.target.name]: e.target.checked
        })
    }

    const onHeightChange = (e: any) => {
        setParams({
            ...params,
            size: [e.target.value, params.size[1]]
        })
    }

    const onWidthChange = (e: any) => {
        setParams({
            ...params,
            size: [params.size[0], e.target.value]
        })
    }

    const onParamsChange = (e: any) => {
        setParams({
            ...params,
            [e.target.name]: e.target.value
        })
    }

    const onDropdownChange = (key: string, e: any) => {
        const selectedIndex = Number(e.target.value)
        setParams({
            ...params,
            [key]: methodOpts[selectedIndex]
        })
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='text-ai-700 font-bold'>Resize</p>
            <div>
                <label className='block px-2 text-ai text-[13px]'>Select method</label>
                <Dropdown items={methodOpts}  selectedIndex={methodOpts.indexOf(params.method)} handleDropdownChange={(e) => onDropdownChange("method", e)}/>
            </div>
            <hr className='border-ai-200'/>
            <div className='flex gap-2'>
                <div className='flex-1'>
                    <label className='block px-2 text-ai text-[13px]'>Height</label>
                    <input type={"text"} className="input w-full" value={params.size[0]} onChange={onHeightChange}/>
                </div>
                <div className='flex-1'>
                    <label className='block px-2 text-ai text-[13px]'>Width</label>
                    <input type={"text"} className="input w-full" value={params.size[1]} onChange={onWidthChange}/>
                </div>
            </div>
            <div className='flex-1 flex gap-2'>
                <Checkbox checked={params.preserveAspectRatio} name="preserveAspectRatio" onChange={onCheckParamsChange}/>
                <label className='block px-2 text-ai'>Preserve aspect ratio</label>
            </div>
            <hr className='border-ai-200'/>
            <div>
                <button color="primary" className='btn-primary' onClick={onAddStep}> {editing ? 'Edit Step' : 'Add Step'} </button>
            </div>
        </div>
    )
}

export default ResizeStep

