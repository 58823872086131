import React from 'react'
import ConvertToVocabulary from './ConvertToVocabulary';
import AddValueStep from './AddValueStep';
import ConvertToCaseStep from './ConvertToCase';
import ConvertToColor from './ConvertToColorStep';
import DivideValueStep from './DivideValueStep';
import MultiplyValueStep from './MultiplyValueStep';
import PadSequencesStep from './PadSequences';
import RemoveCharactersStep from './RemoveCharactersStep';
import ResizeStep from './ResizeStep';
import SubtractValueStep from './SubtractValueStep';
import TokenizeStep from './TokenizeStep';
import StepActions from './StepActions';
import { PREPROCESSING_STEPS } from '../../Utils/constants';
import { BasicStepsProps } from '../../features/types';
import Dropdown from '../Dropdown';
import { EditOutlined } from '@mui/icons-material';
import ContentviewMessage from '../ContentviewMessage';

interface PanelProps {
    children?: React.ReactNode;
    index: number;
    value: number | string;
}

const StepComponents = {
    AddValue: AddValueStep,
    SubtractValue: SubtractValueStep,
    DivideValue: DivideValueStep,
    MultiplyValue: MultiplyValueStep,
    Add: AddValueStep,
    Subtract: SubtractValueStep,
    Divide: DivideValueStep,
    Multiply: MultiplyValueStep,
    Resize: ResizeStep,
    Tokenize: TokenizeStep,
    ConvertToCase: ConvertToCaseStep,
    ConvertToColor: ConvertToColor,
    ConvertToVocabulary: ConvertToVocabulary,
    PadSequences: PadSequencesStep,
    RemoveCharacters: RemoveCharactersStep

}

const preprocessingOpts = [
    {label: "Image Preprocessor", value: "ImagePreprocessor"},
    {label: "Text Preprocessor", value: "TextPreprocessor"},
    {label: "Tabular Preprocessor", value: "TabularPreprocessor"}
]

const PreprocessingPanel: React.FC<BasicStepsProps> = ({ _model, onConfigChange }) => {

    const [preprocessingClass, setPreprocessingClass] = React.useState('')
    const [preprocessingStep, setPreprocessingStep] = React.useState('')
    const [canChangePreClass, setCanChangePreClass] = React.useState(true)
    const [editIndex, setEditIndex] = React.useState(-1)

    React.useEffect(() => {
        if (_model && _model.config?.params?.preprocessingSteps && _model.config?.params?.preprocessingSteps?.length > 0) {
            const _preprocessClass = _model.config?.params.preprocessingSteps[0].className
            setPreprocessingClass(_preprocessClass)
            setCanChangePreClass(false)
        } else {
            setCanChangePreClass(true)
        }
    }, [_model])
    
    if(!_model) { 
        return null
    }

    const renderParamsComponent = () => {
        const StepComponent = StepComponents[preprocessingStep as keyof typeof StepComponents]
        if(StepComponent) {
            return (
                <StepComponent onConfigChange={onSaveChanges} _model={_model} json={{ ..._model.config }} />
            );
        }
        return null;
    };

    const renderEditParamsComponent = (step: any, index: number) => {
        const StepComponent = StepComponents[preprocessingStep as keyof typeof StepComponents]
        if (StepComponent) {
            return (
                <StepComponent onConfigChange={onSaveChanges} _model={_model} json={{ ..._model.config }} step={step} index={index} editing />
            );
        }
        return null;
    }

    const onPreprocessingClassChange = (event: any) => {
        const selectedIndex = Number(event.target.value)
        setPreprocessingStep('')
        setPreprocessingClass(preprocessingOpts[selectedIndex].value);
    };

    const onPreprocessingStepChange = (event: any) => {
        const selectedIndex = Number(event.target.value)
        const steps = PREPROCESSING_STEPS[preprocessingClass as keyof typeof PREPROCESSING_STEPS] as any[]
        setPreprocessingStep(steps[selectedIndex].value);
    }

    const onRemoveClass = () => {
        if(_model) {
            let m = { ..._model }
            m.config.params.preprocessingSteps = null
            onConfigChange(m.config)
        }
    }

    const onSaveChanges = (config: any) => {
        setEditIndex(-1)
        setPreprocessingStep('');
        onConfigChange(config)
    }

    const steps = _model.config?.params?.preprocessingSteps && _model.config?.params?.preprocessingSteps?.length > 0 ? _model.config?.params?.preprocessingSteps[0].steps : []
    const classIndex = preprocessingOpts.findIndex((opt) => opt.value === preprocessingClass)
    const stepIndex = preprocessingClass.length > 0 ? 
        PREPROCESSING_STEPS[preprocessingClass as keyof typeof PREPROCESSING_STEPS].findIndex((opt) => opt.value === preprocessingStep) 
        : -1

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <p className="text-ai-700 text-[16px] font-bold mb-2">Preprocessing class</p>
                <div className='flex gap-2'>
                    <div className='flex-1'>
                        {canChangePreClass && <Dropdown selectedIndex={classIndex} items={preprocessingOpts.map((opt) => opt.label)} handleDropdownChange={onPreprocessingClassChange} placeholder="Choose preprocessing class"/>}
                        {!canChangePreClass && <p>{preprocessingClass.replace(/([A-Z])/g, " $1")}</p>}
                    </div>
                    <div className='flex-1'>
                    {preprocessingClass &&
                        <Dropdown selectedIndex={stepIndex} items={PREPROCESSING_STEPS[preprocessingClass as keyof typeof PREPROCESSING_STEPS].map((item) => item.label)} handleDropdownChange={onPreprocessingStepChange} placeholder="Choose step"/>
                    }
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                {editIndex === -1 && renderParamsComponent()}
            </div>
            <div>
                <table aria-label="Rendering steps" className="w-full border border-gray-300">
                    <thead className="bg-white text-left border-b border-b-gray-300">
                        <tr>
                            <th className="px-4 py-2 w-1">
                                <span className="text-[16px] text-ai-800 ">#</span>
                            </th>
                            <th className='w-full'>
                                <span className="text-[16px] text-ai-800 ">Step</span>
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="text-left">
                        {
                            steps.length === 0 &&
                            <tr>
                                <td colSpan={6}>
                                    <ContentviewMessage 
                                        title='No class added'
                                        message='Select a preprocessing class to start adding steps'/>
                                </td>
                            </tr>
                        }
                        {steps.map((step: any, index: number) => {
                            return (
                                <React.Fragment key={step.className + index}>
                                    <tr className={index % 2 === 0 ? 'bg-ai-200' : ''}>
                                        <td className="p-4">
                                            <span className="text-ai-800 ">
                                                {index + 1 + "."}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="text-ai-800 ">
                                                {step.className}
                                            </span>
                                        </td>
                                        <td>
                                            <button className="btn-icon"
                                                onClick={() => {
                                                    if(editIndex === index) {
                                                        setPreprocessingStep("");
                                                        setEditIndex(-1);
                                                    } else {
                                                        setPreprocessingStep(step.className);
                                                        setEditIndex(index);
                                                    }
                                                }}
                                            >
                                                <EditOutlined fontSize="small" />
                                            </button>
                                        </td>
                                        <td>
                                            <StepActions onConfigChange={onSaveChanges} _model={_model} step={step} index={index} firstStep={index === 0} lastStep={ _model.config.params.preprocessingSteps ? index === _model.config.params.preprocessingSteps[0].steps.length - 1 : false} />
                                        </td>
                                    </tr>
                                    {editIndex > -1 && editIndex === index &&
                                    <tr key={step.className + index + '-edit-view'}>
                                        <td colSpan={7}>
                                            <div className="p-3 rounded bg-white">
                                                {renderEditParamsComponent(step, index)}
                                            </div>
                                        </td>
                                    </tr> }
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <hr className='ai-border-200'/>
            <div className="flex justify-between">
                <button className="btn-primary" onClick={onSaveChanges.bind(this, {..._model.config})} disabled={canChangePreClass}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]"disabled={canChangePreClass} onClick={onRemoveClass}>Remove Class</button>
            </div>
        </div>
    )
}

export default PreprocessingPanel
