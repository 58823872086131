import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuth } from 'react-oidc-context'
import { endpoints } from '../endpoints'
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom'
import { ROUTER_PATHS } from '@/Utils/constants'

const useJobRequests = () => {
    const auth = useAuth()
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    const authtype = localStorage.getItem('authtype')
    const queryClient = useQueryClient()

    const testJob = useMutation({
        mutationFn: async (variables: { analytics: any[], modelName?: string }) => {
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                        'X-AIS-Tenant-Id': auth.user?.profile.tenant_id ?? '',
                    },
                }
                const promise = axios.post(endpoints.testJob, { analytics: variables.analytics }, options)
                toast.promise(promise, { loading: `Testing job ${variables.modelName && variables.modelName.length > 0 ? `for ${variables.modelName}` : ''}` });
                return promise
            }
        },
        onSuccess: (response) => {
            const data = response?.data?.data
            if(data) {
                const options = {
                    description: `Finished testing job with status: ${data.jobStatus}`,
                }
                switch (data.jobStatus) {
                    case 'successful':
                        toast.success('Finished running job', options)
                        break
                    case 'unsuccessful':
                        toast.error('Finished running job', options)
                        break
                    case 'warning':
                        toast.warning('Finished running job', options)
                        break
                    default:
                        toast('Finished running job', options)
                        break
                }
            } else {
                toast('Finished testing job')
            }
        },
        onError(e: any, variables, context) {
            toast.error('Error trying to test job', {
                description: e.message
            })
        },
    })

    const runJob = useMutation({
        mutationFn: async (variables: { modelId: string, modelName: string }) => {
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                        'X-AIS-Tenant-Id': auth.user?.profile.tenant_id ?? '',
                    },
                }
                const promise = axios.post(endpoints.runJob, { modelId: variables.modelId }, options)
                toast.promise(promise, { loading: `Running job for ${variables.modelName}` });
                return promise
            }
        },
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['jobs', variables.modelId])
            const data = response?.data?.data
            if(data) {
                const options = {
                    description: `Finished running job with status: ${data.jobStatus}`,
                    action: {
                        label: 'See logs',
                        onClick: () => {
                            navigate(`${ROUTER_PATHS.model.replace(':id', variables.modelId)}${ROUTER_PATHS.logs.replace(':jobId', data.job.id)}`)
                        },
                    },
                }
                switch (data.jobStatus) {
                    case 'successful':
                        toast.success('Finished running job', options)
                        break
                    case 'unsuccessful':
                        toast.error('Finished running job', options)
                        break
                    case 'warning':
                        toast.warning('Finished running job', options)
                        break
                    default:
                        toast('Finished running job', options)
                        break
                }
            } else {
                toast('Finished running job')
            }
        },
        onError(e: any, variables, context) {
            toast.error('Error trying to execute job', {
                description: e.message
            })
        },
    })

    return {
        testJob,
        runJob,
    }
}

export default useJobRequests
