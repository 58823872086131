import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { endpoints } from "../endpoints";

const useDeleteModel = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (variables: { modelId: string }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                await axios.delete(`${endpoints.models}/${variables.modelId}`, options);
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workspace"])
        }
    })
}

export default useDeleteModel
