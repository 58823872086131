import React from 'react'

interface CheckboxProps {
    name?: string;
    checked?: boolean;
    disabled?: boolean;
    id?: string;
    onChange?: (e: any) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ name, checked, disabled, id, onChange }) => {
    return (
        <div className="checkbox relative flex items-center justify-center">
            <input
                disabled={disabled ?? false}
                name={name ?? ""}
                type="checkbox"
                checked={checked}
                id ={id ?? ""}
                onChange={onChange}
                className="peer/checkbox z-10 cursor-pointer opacity-0"
            />
            <div className="absolute flex h-4 w-4 items-center justify-center rounded-[4px] border-2 border-ai peer-checked/checkbox:bg-ai">
                {checked && <span className="text-xs text-white">&#10003;</span>}
            </div>
        </div>
    )
}

export default Checkbox