import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoints } from "../endpoints";
import { ServiceResponse, UserRole } from "../types";
import { useAuth } from "react-oidc-context";

const useGetRoles = () => {
    const auth = useAuth()
    return useQuery(["users", "roles"], async () => {
        let rolesResponse: ServiceResponse<UserRole[]> | null = null;
        try {
            const token = localStorage.getItem("token")
            const authtype = localStorage.getItem("authtype")
            if(token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                        "X-AIS-Tenant-Id": auth.user?.profile.tenant_id ?? ""
                    }
                }
                const response = await axios.get<any>(endpoints.roles, options);
                rolesResponse = response.data
            }
        } catch (e) {
        } finally {
            return rolesResponse;
        }
    }, {
        staleTime: 1000 * 60 * 60 * 24 // 24 hours in client cache
    });
};

export default useGetRoles