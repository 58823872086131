import { Box, AvatarGroup, Avatar, Typography } from "@mui/material"
import React from "react"
import { useGetSharedUsers } from "../../features/queries";
import { AIRModel } from "../../features/types";

interface ModelDashUserShareProps {
    model: AIRModel;
}

const ModelDashUserShare: React.FC<ModelDashUserShareProps> = ({ model }) => {

    const truthyQuery = useGetSharedUsers(model.id, { shared: true })

    const modelShareTruthyUsers = truthyQuery.data
    if (!model || !modelShareTruthyUsers) {
        return null
    }

    if(modelShareTruthyUsers.length === 0) {
        return null
    }

    return (
        <Box>
            <Typography variant="subtitle1" fontWeight={'700'} sx={{
                marginBottom: '16px'
            }}>
                Shared with
            </Typography>
            <AvatarGroup max={100} sx={{
                flexWrap: 'wrap'
            }}>
                {
                    modelShareTruthyUsers.map((user, index) => {
                        return (
                            <Avatar key={user.id + index} sx={{ height: 34, width: 34, bgcolor: 'success.dark' }}>
                                <Typography variant="subtitle1" color={'white'}>
                                    {user.initials}
                                </Typography>
                            </Avatar>
                        )
                    })
                }
            </AvatarGroup>
        </Box>
    )
}

export default ModelDashUserShare