import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { ROUTER_PATHS } from "../../Utils/constants"
import { createAIRZipFromConfig } from "../../Utils/utils"
import {ModelConfigRoot} from "../types"
import { toast } from "sonner"

const useCreateModel = () => {

    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const uploadAirModel = useMutation({
        mutationFn: async (variables: { file: File | File[] }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                const files = Array.isArray(variables.file) ? variables.file : [variables.file]
                const result = files.map( async (_f) => {
                    const formData = new FormData()
                    formData.append('model', _f)
                    return await axios.post(`/upload/v1/models`, formData, options);
                })
                return Promise.all(result)
            }
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(["workspace"])
            const payload = variables.file
            if(Array.isArray(payload)) {
                toast.success("Models Created", {
                    description: `${payload.length} Models has been created.`
                })
            } else {
                toast.success("Model Created", {
                    description: `Model has been created.`
                })
            }
            navigate(ROUTER_PATHS.workspace)
        },
        onError(e: any, variables, context) {
            const payload = variables.file
            let description = ''
            if(Array.isArray(payload)) {
                description = 'One of the Models has a name that already exists.'
            } else {
                description = 'A Model with given name already exists.'
            }
            if (e.response.status === 409) {
                toast.warning("Model Already Exists", {
                    description
                })
            } else {
                toast.error("Unexpected Error " + e.response.status, {
                    description: 'Something went wrong while creating the model.'
                })
            }
        },
    })

    const createAirModel = useMutation({
        mutationFn: async (variables: { config: ModelConfigRoot, resources: File[], rootFolder: string | null }) => {
            let airFile = null
            try {
                airFile = await createAIRZipFromConfig(variables.config, variables.resources, variables.rootFolder)
            } catch {
                toast.warning("Error creating Model Card .air file", {
                    description: 'Unexpected error occurred while bundling .air file.'
                })
                return Promise.reject()
            }
            if (airFile) {
                const token = localStorage.getItem("token");
                const authtype = localStorage.getItem("authtype");
                if (token && authtype) {
                    const options = {
                        headers: {
                            authtype,
                            Authorization: `Bearer ${token}`,
                        }
                    };
                    const formData = new FormData()
                    formData.append('model', airFile)
                    return axios.post(`/upload/v1/models`, formData, options);
                }
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workspace"])
            toast.success("Model Created", {
                description: `Model has been created.`
            })
            navigate(ROUTER_PATHS.workspace)
        },
        onError(e: any, variables, context) {
            if (e.response.status === 409) {
                toast.warning("Model Already Exists", {
                    description: 'A Model with given name already exists.'
                })
            } else {
                toast.error("Unexpected Error " + e.response.status, {
                    description: 'Something went wrong while creating the model.'
                })
            }
        },
    })

    return {
        uploadAirModel,
        createAirModel
    }
}

export default useCreateModel
