import React from 'react'
import StepProps from '../../features/types'

const MultiplyValueStep: React.FC<StepProps> = ({ _model, json, step, editing, index, onConfigChange }) => {

    const [ params, setParams ] = React.useState({
        value: 0
    })

    React.useEffect(() => {
        if(editing) {
            setParams({
                value: step.params.value
            })
        }
    }, [step])

    if(!_model) {
        return null
    }

    const onAddStep = (e: any) => {
        let _config = { ...json }
        if(_model) {
            const step = {
                className: 'MultiplyValue',
                params
            }
            if(editing && index !== null && index !== undefined && _config.params.preprocessingSteps) {
                _config.params.preprocessingSteps[0].steps[index] = {...step}
            } else {
                if (!_config.params.preprocessingSteps || _config.params.preprocessingSteps.length === 0) {
                    _config.params.preprocessingSteps = []
                    _config.params.preprocessingSteps.push({
                        className: 'ImagePreprocessor',
                        steps: []
                    })
                }
                _config.params.preprocessingSteps[0].steps.push(step)
            }
            onConfigChange(_config)
        }
    }

    const onParamsChange = (e: any) => {
        setParams({
            ...params,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='text-ai-700 font-bold'>Multiply</p>
            <div className='flex-1'>
                <label className='block px-2 text-ai text-[13px]'>Value</label>
                <input type={"text"} className="input w-full" name="value" value={params.value} onChange={onParamsChange}/>
            </div>
            <hr className='border-ai-200'/>
            <div>
                <button color="primary" className='btn-primary' onClick={onAddStep}> {editing ? 'Edit Step' : 'Add Step'} </button>
            </div>
        </div>
    )
}

export default MultiplyValueStep

