import { Select, FormControl, InputLabel, MenuItem, Box } from "@mui/material"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import React from "react"
import { AIRModel } from "../../features/types"
import { useModelMetrics } from "../../features/queries"

interface DataShape {
    periodX: string;
    countsY: Number;
}

const ModelDashBinning: React.FC<{ model: AIRModel }> = ({ model }) => {

    const [period, setPeriod] = React.useState('weekly')
    const [processedMetrics, setProcessedMetrics] = React.useState<any[]>([])
    const binningOptions = ["hourly", "daily", "weekly", "monthly", "yearly"]
    const modelUsageQuery = useModelMetrics(model.id, { interval: period })
    const metrics = modelUsageQuery.data

    React.useEffect(() => {
        if(metrics && metrics.length > 0) {
            let _metrics = [...metrics]
            const dateObj = new Date(_metrics[0].periodX);
            const dayBefore = dateObj.getDate() - 1;
            const requestedMonth = dateObj.getMonth() + 1
            let processed = metrics?.map(data => processDateTimeData(data.periodX, data.countsY));
            processed.unshift({
                periodX: `${requestedMonth}/${dayBefore.toString()}`,
                countsY: 0
            })
            setProcessedMetrics(processed)
        }
    }, [metrics])

    const onBinningSelect = (e: any) => {
        setPeriod(e.target.value);
    }

    if (!model || !metrics) {
        return null
    }

    const processDateTimeData = (date: string, frequency: string) => {
        const dateObj = new Date(date);
        const requestedDate = dateObj.getDate();
        const requestedMonth = dateObj.getMonth() + 1
        return {
            periodX: `${requestedMonth}/${requestedDate.toString()}`,
            countsY: Number(frequency)
        }
    }

    return (

        <Box>
            <FormControl>
                <InputLabel id="metrics-period-select">Select Period</InputLabel>
                <Select
                    size="small"
                    labelId="Select Period"
                    id="period-select"
                    value={period}
                    label="Select Period"
                    onChange={onBinningSelect}
                    className="w-40 mb-2"
                >
                    {
                        binningOptions.map((opt, index) => {
                            return (
                                /*Key prop is react stuff thats needed when looping*/
                                <MenuItem key={opt + index.toString()} value={opt}>{opt}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <Box sx={{
                height: '290px'
            }}>
                <ResponsiveContainer>
                    <LineChart
                        data={processedMetrics}
                        height={200}
                        width={100}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="periodX" />
                        <YAxis dataKey="countsY" />
                        <Tooltip />
                        {/* <Legend /> */}
                        <Line type="monotone" dataKey="countsY" stroke="#00249C" />

                    </LineChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    )
}

export default ModelDashBinning
