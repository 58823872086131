import { useState } from 'react'
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    ColumnFiltersState,
    getFilteredRowModel,
    VisibilityState,
    getPaginationRowModel,
    Row,
} from '@tanstack/react-table'
import {
    DialogTable,
    Table,
    TableBody,
    TableCell,
    TableCellSmall,
    TableHead,
    TableHeadSmall,
    TableHeader,
    TableRow,
} from '@/Components/ui/table'
import { Button } from '@/Components/ui/button'
import { Input } from '@/Components/ui/input'
import { Download, Settings2 } from 'lucide-react'
import { DataTablePagination } from '../DataTablePaginationProps'

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[]
    data: TData[]
    pagination?: boolean
    searchBar?: boolean
    onRowClick?: (row: Row<any>) => void
}

export function DialogDataTable<TData, TValue>({
    columns,
    data,
    pagination = false,
    searchBar = false,
    onRowClick,
}: DataTableProps<TData, TValue>) {
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        ...(pagination ? { getPaginationRowModel: getPaginationRowModel() } : {}),
        state: {
            sorting,
            columnFilters,
            columnVisibility,
        },
    })

    return (
        <div className='flex w-full flex-col'>
            <div className='flex items-center justify-between'>
                {searchBar && (
                    <div className='flex items-center'>
                        <Input
                            placeholder='Filter Id...'
                            value={(table.getColumn('id')?.getFilterValue() as string) ?? ''}
                            onChange={event => table.getColumn('id')?.setFilterValue(event.target.value)}
                            className='w-80'
                        />
                    </div>
                )}
            </div>
            <div className='w-0 rounded-md lg:w-max lg:min-w-full'>
                <DialogTable>
                    <TableHeader>
                        {table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <TableHeadSmall key={header.id}>
                                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                        </TableHeadSmall>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody className=''>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map(row => (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && 'selected'}
                                    onClick={() => (onRowClick ? onRowClick(row) : null)}
                                    className='cursor-pointer duration-200 ease-in-out'
                                >
                                    {row.getVisibleCells().map(cell => (
                                        <TableCellSmall className='' key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCellSmall>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCellSmall colSpan={columns.length} className='text-center'>
                                    No results.
                                </TableCellSmall>
                            </TableRow>
                        )}
                    </TableBody>
                </DialogTable>
            </div>
            {pagination && <DataTablePagination table={table} />}
        </div>
    )
}
