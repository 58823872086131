import React from "react"
import ModalViewLayout from "./ModalViewLayout"
import { useLayout } from "../Store/Hooks";
import { useNavigate } from "react-router-dom";
import { useDeleteModel } from "../features/mutations";

const DeleteModelPopup: React.FC<{}> = () => {

    const deleteMutation = useDeleteModel()
    const { deleteParams, openDeletePopup } = useLayout()
    const navigate = useNavigate()

    if (!deleteParams.model) {
        return null
    }

    const onModelDelete = () => {
        if(deleteParams.model) {
            deleteMutation.mutate({modelId: deleteParams.model.id})
            openDeletePopup({
                open: false,
                model: null
            })
            if (deleteParams.redirectTo) {
                navigate(deleteParams.redirectTo)
            }
        }
    }

    const onPopupClose = () => {
        openDeletePopup({
            open: false,
            model: null
        })
    }

    return (
        <ModalViewLayout
            warning
            primaryLabel="Delete"
            open={deleteParams.open}
            title={"Delete " + deleteParams.model.config.params.name}
            subtitle={`This action cannot be undone`}
            onSubmit={onModelDelete}
            onClose={onPopupClose} />
    )
}

export default DeleteModelPopup