import React from "react";
import { motion } from "framer-motion";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

let searchDelay: NodeJS.Timeout | null = null;

interface Props {
    searchOnChange?: (e: any) => void;
    sortKeyOnChange?: (e: any) => void;
    sortOrderOnChange?: (e: any) => void;
}

const GroupFilterBar: React.FC<Props> = ({ searchOnChange, sortKeyOnChange, sortOrderOnChange }) => {
    const [sortKey, setSortKey] = React.useState("displayName");
    const [sortOrder, setSortOrder] = React.useState("ASC");
    const [openSorts, setOpenSorts] = React.useState(false);

    const onSortKeyChange = (e: any) => {
        setSortKey(e.target.value);
        if (sortKeyOnChange) {
            sortKeyOnChange(e);
        }
    };

    const onSortOrderChange = (e: any) => {
        setSortOrder(e.target.value);
        if (sortOrderOnChange) {
            sortOrderOnChange(e);
        }
    };

    return (
        <div
            className={`flex gap-4 rounded-md border border-ai-200 bg-white p-3 text-sm transition-[height] duration-500 ease-in-out ${
                openSorts ? "h-[10rem] lg:h-[8.5rem] flex-col" : "h-[4.1rem]"
            } `}>
            <div className="flex gap-4 w-full">
                <div className="flex max-h-10 w-full items-center rounded-md border border-ai-200 p-2 hover:border-ai focus:border-ai-400 lg:max-w-sm lg:flex-1">
                    <SearchOutlinedIcon className="text-ai" />
                    <input
                        className="w-full px-2 focus:outline-none"
                        placeholder="Search groups"
                        onChange={(e) => {
                            if (searchOnChange) {
                                searchOnChange(e);
                            }
                        }}
                    />
                </div>
                <button
                    className="flex max-h-10 min-w-max items-center space-x-2 rounded-md border border-ai-200 p-2 text-black lg:max-w-[7rem] lg:pr-3"
                    title="Sort By"
                    onClick={() => {
                        setOpenSorts(!openSorts);
                    }}>
                    <FilterListOutlinedIcon className="text-ai" />
                    <p className="hidden md:inline-block">Sort By</p>
                </button>
            </div>

            {openSorts && (
                <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.25, delay: 0.25 }} className="space-y-2 pb-2 text-black">
                    <p className="">Sort by</p>
                    <div className="flex flex-col space-y-2 lg:flex-row lg:items-center lg:space-x-8 lg:space-y-0">
                        <div className="flex flex-1 space-x-3">
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group1"
                                    type="radio"
                                    value="displayName"
                                    checked={sortKey === "displayName"}
                                    onChange={(e) => {
                                        onSortKeyChange(e);
                                    }}
                                />
                                <div className="radio" />
                                <label htmlFor="" className="pl-3 ">
                                    Name
                                </label>
                            </div>
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group1"
                                    type="radio"
                                    value="createdDate"
                                    checked={sortKey === "createdDate"}
                                    onChange={(e) => {
                                        onSortKeyChange(e);
                                    }}
                                />
                                <div className="radio" />
                                <label htmlFor="" className="pl-3">
                                    Created Date
                                </label>
                            </div>
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group1"
                                    type="radio"
                                    value="modifiedDate"
                                    checked={sortKey === "modifiedDate"}
                                    onChange={(e) => {
                                        onSortKeyChange(e);
                                    }}
                                />
                                <div className="radio" />
                                <label htmlFor="" className="pl-3">
                                    Last Updated
                                </label>
                            </div>
                        </div>

                        <div className="flex space-x-3">
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group2"
                                    type="radio"
                                    value="ASC"
                                    checked={sortOrder === "ASC"}
                                    onChange={(e) => {
                                        onSortOrderChange(e);
                                    }}
                                />
                                <div className="radio" />
                                <label htmlFor="" className="pl-3">
                                    Ascending
                                </label>
                            </div>
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group2"
                                    type="radio"
                                    value="DESC"
                                    checked={sortOrder === "DESC"}
                                    onChange={(e) => {
                                        onSortOrderChange(e);
                                    }}
                                />
                                <div className="radio " />
                                <label htmlFor="" className="pl-3">
                                    Descending
                                </label>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default GroupFilterBar;
