import React from 'react'
import StepProps from '../../features/types';

const ConvertToVocabulary: React.FC<StepProps> = ({ _model, json, step, editing, index, onConfigChange }) => {

    const [params, setParams] = React.useState({
        vocabulary: [] as any[],
        startCharacter: 1,
        oovCharacter: 2,
        maxVocab: 0
    })

    React.useEffect(() => {
        if (editing) {
            const vocab = Object.keys(step.params.vocabulary ?? {}).map((v) => {
                return {
                    word: v,
                    value: step.params.vocabulary[v]
                }
            })
            setParams({
                vocabulary: vocab,
                startCharacter: step.params.startCharacter,
                oovCharacter: step.params.oovCharacter,
                maxVocab: step.params.maxVocab,
            })
        }
    }, [step])

    if (!_model) {
        return null
    }

    const onAddStep = (e: any) => {
        let _config = { ...json }
        if(_model) {
            let _vocabulary: any = {}
            params.vocabulary.forEach((v) => {
                _vocabulary[v.word] = v.value
            })
            const _params = {
                ...params,
                maxVocab: params.maxVocab === 0 ? null : params.maxVocab,
                vocabulary: _vocabulary
            }
            const step = {
                className: 'ConvertToVocabulary',
                params: _params
            }
            if (editing && index !== null && index !== undefined && _config.params.preprocessingSteps) {
                _config.params.preprocessingSteps[0].steps[index] = { ...step }
            } else {
                if (!_config.params.preprocessingSteps || _config.params.preprocessingSteps.length === 0) {
                    _config.params.preprocessingSteps = []
                    _config.params.preprocessingSteps.push({
                        className: 'TextPreprocessor',
                        steps: []
                    })
                }
                _config.params.preprocessingSteps[0].steps.push(step)
            }
            onConfigChange(_config)
            setParams({
                vocabulary: [],
                startCharacter: 1,
                oovCharacter: 2,
                maxVocab: 0
            })
        }
    }

    const onParamsChange = (e: any) => {
        setParams({
            ...params,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='text-ai-700 font-bold'>Convert to vocabulary</p>
            <div className='flex gap-2'>
                <div className='flex-1'>
                    <label className='block px-2 text-ai text-[13px]'>Start character</label>
                    <input type={"text"} className="input w-full" name="startCharacter" value={params.startCharacter} placeholder="1" onChange={onParamsChange}/>
                </div>
                <div className='flex-1'>
                    <label className='block px-2 text-ai text-[13px]'>Out of Vocab. Character</label>
                    <input type={"text"} className="input w-full" name="oovCharacter" value={params.oovCharacter} placeholder="2" onChange={onParamsChange}/>
                </div>
                <div className='flex-1'>
                    <label className='block px-2 text-ai text-[13px]'>Max Vocab.</label>
                    <input type={"text"} className="input w-full" name="maxVocab" value={params.maxVocab} placeholder="0" onChange={onParamsChange}/>
                </div>
            </div>
            <hr className='border-ai-200'/>
            <div>
                <button color="primary" className='btn-primary' onClick={onAddStep}> {editing ? 'Edit Step' : 'Add Step'} </button>
            </div>
        </div>
    )
}

export default ConvertToVocabulary