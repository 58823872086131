import React from "react"
import { useGetCurrentUser } from "../features/queries"

const UpgradePremiumMessage: React.FC<{ message: string, description: string}> = ({ message, description }) => {

    const currentUserQuery = useGetCurrentUser()
    const user = currentUserQuery.data

    const onContactSales = () => {
        if(user) {
            window.open(`mailto:support@squared.ai?subject=${user.data.email} - Interested in Upgrading&body=Hello, AI Squared. I'm interested in upgrading my plan!`)
        }
    }

	return (
		<div id = "upgrade-premium-message" className="p-4 rounded-md bg-orange-300 flex items-center gap-4">
            <div className="flex-1">
                <p className="font-bold text-ai-700">{message}</p>
                <p className="text-sm text-ai-700">{description}</p>
            </div>
            <div>
                <button className="btn-primary p-2 border-none text-sm" onClick={onContactSales}>
                    Contact
                </button>
            </div>
		</div>
	)
}

export default UpgradePremiumMessage
