import React from "react";
import { RenderingProps } from "./RenderingSteps";
import Dropdown from "../Dropdown";
import Checkbox from "../Checkbox";

const locations = [
    "none",
    "top",
    "bottom",
    "left",
    "right",
    "top right",
    "top left",
    "bottom right",
    "bottom left",
];

const ObjectRenderingParams: React.FC<RenderingProps> = ({
    _model,
    stepIndex,
    onSaveChanges,
    onCancel
}) => {
    const [params, setParams] = React.useState({
        color: "",
        thickness: "",
        fontColor: "",
        fontSize: "",
        placement: "",
        badgeColor: "",
        includeProbability: false,
        canToggle: false,
    });

    React.useEffect(() => {
        if(stepIndex > -1 && _model.config.params.renderingSteps) {
            setParams(_model.config.params.renderingSteps[stepIndex].params)
        }
    }, [stepIndex])

    if (!_model) {
        return null;
    }

    const handleChecks = (event: any) => {
        setParams({ ...params, [event.target.name]: event.target.checked });
    };

    const handleFieldsOnChange = (event: any) => {
        setParams({ ...params, [event.target.name]: event.target.value });
    };

    const handleDropdownOnChange = (event: any) => {
        const index = Number(event.target.value)
        setParams({ ...params, placement: locations[index] })
    }

    const onSubmit = () => {
        // Need to preprocess params
        // remove whitespaces for placement; insert null as value if None selected
        let _params = { ...params };
        if (_params.placement === "none") {
            _params.placement = "";
        } else {
            _params.placement = _params.placement.replace(/\s/g, "");
        }
        onSaveChanges(_params);
    };

    return (
        <div className="flex flex-col gap-4">
            <p className="font-bold text-ai-700">
                Object Rendering
            </p>
            <hr className="border-ai-200"/>
            <p className="font-bold text-ai-700">
                Boundingbox
            </p>
            <div className="flex gap-2">
                <div className="flex-1">
                    <label htmlFor="bb-color" className="block px-2 text-[13px] text-ai">Color</label>
                    <input id="bb-color" className="input w-full" onChange={handleFieldsOnChange} value={params.color ?? ''}  placeholder="blue" name="color" />
                    <label htmlFor="bb-color" className="block px-2 text-[13px] text-ai-800">CSS values accepted (blue, red, #f3f3f3)</label>
                </div>
                <div className="flex-1">
                    <label htmlFor="bb-thickness" className="block px-2 text-[13px] text-ai">Thickness</label>
                    <input id="bb-thickness" className="input w-full" onChange={handleFieldsOnChange} value={params.thickness ?? ''}  placeholder="1" name="thickness" />
                    <label htmlFor="bb-thickness" className="block px-2 text-[13px] text-ai-800">Number represented in pixel (px)</label>
                </div>
            </div>
            <hr className="border-ai-200"/>
            <p className="font-bold text-ai-700">
                Label
            </p>
            <div className="flex gap-2">
                <div className="flex-1">
                    <label htmlFor="font-color" className="block px-2 text-[13px] text-ai">Font color</label>
                    <input id="font-color" className="input w-full" onChange={handleFieldsOnChange} value={params.fontColor ?? ''}  placeholder="blue" name="fontColor" />
                    <label htmlFor="font-color" className="block px-2 text-[13px] text-ai-800">CSS values accepted (blue, red, #f3f3f3)</label>
                </div>
                <div className="flex-1">
                    <label htmlFor="font-size" className="block px-2 text-[13px] text-ai">Font Size</label>
                    <input id="font-size" className="input w-full" onChange={handleFieldsOnChange} value={params.fontSize ?? ''}  placeholder="10" name="fontSize" />
                    <label htmlFor="font-size" className="block px-2 text-[13px] text-ai-800">Number represented in pixel (px)</label>
                </div>
            </div>
            <div className="flex gap-2">
                <div className="flex-1">
                    <label htmlFor="font-color" className="block px-2 text-[13px] text-ai">Placement</label>
                    <Dropdown id="placement" placeholder="Select location" selectedIndex={locations.indexOf(params.placement)} items={locations} handleDropdownChange={handleDropdownOnChange}/>
                </div>
                <div className="flex-1">
                    <label htmlFor="badge-color" className="block px-2 text-[13px] text-ai">Badge Color</label>
                    <input id="badge-color" className="input w-full" onChange={handleFieldsOnChange} value={params.badgeColor ?? ''}  placeholder="blue" name="badgeColor" />
                    <label htmlFor="badge-color" className="block px-2 text-[13px] text-ai-800">CSS values accepted (blue, red, #f3f3f3)</label>
                </div>
            </div>
            <div className="flex gap-4">
                <div className="flex items-center text-ai">
                    <span className='mr-2'>
                        <Checkbox id="include-probability" name="includeProbability" onChange={handleChecks} checked={params.includeProbability} />
                    </span>
                    <p className="text-ai-800">Include Probability</p>
                </div>
                {/* <div className="flex items-center text-ai">
                    <span className='mr-2'>
                        <Checkbox name="canToggle" onChange={handleChecks} checked={params.canToggle} />
                    </span>
                    <p className="text-ai-800">Togglable step</p>
                </div> */}
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button id="save-changes-button" className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button id="cancel-button" className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
};

export default ObjectRenderingParams;
