import React from "react"
import { HarvesterProps } from "./HarvesterSteps"
import { toast } from "sonner"

const TextHarvesterParams: React.FC<HarvesterProps> = ({ _model, onSaveChanges, onRemoveClass }) => {

    const [params, setParams] = React.useState({
        how: '',
        regex: '',
        flags: ''
    })

    React.useEffect(() => {
        if (_model && _model.config?.params?.harvestingSteps && _model.config?.params?.harvestingSteps?.length > 0 && _model.config?.params?.harvestingSteps[0].className === 'TextHarvester') {
            setParams({ ..._model.config?.params?.harvestingSteps[0].params })
        }
    }, [])

    if (!_model) {
        return null
    }

    const handleHowOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    };

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onSubmit = () => {
        if (params.how.toLowerCase() === "all") {
            params.regex = ''
            params.flags = ''
        } else if (params.how.toLowerCase() === 'regex') {
            if(params.regex.length === 0 || params.flags.length === 0) {
                toast.warning('Required Fields', {
                    description: `Please complete all required fields: ${["Regex pattern", "Regex flags"].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`
                })
                return
            }
        }
        onSaveChanges(params)
    }

    return (
        <div className="flex flex-col gap-4">
            <p id="text-harvester-how" className="text-ai-800  text-[16px]">How to harvest text?</p>
            <div className="flex w-full gap-4">
                <div className="relative flex items-center">
                    <input
                        className="radio-parent peer"
                        name="how"
                        type="radio"
                        value="all"
                        id="all-input"
                        checked={params.how === "all"}
                        onChange={handleHowOnChange}
                    />
                    <div className="radio" />
                    <label htmlFor="" className="pl-3 truncate">
                        All
                    </label>
                </div>
                <div className="ml-4 relative flex items-center">
                    <input
                        className="radio-parent peer"
                        name="how"
                        type="radio"
                        value="regex"
                        id="regex-input"
                        checked={params.how === "regex"}
                        onChange={handleHowOnChange}
                    />
                    <div className="radio" />
                    <label htmlFor="" className="pl-3 truncate">
                        Regex
                    </label>
                </div>
            </div>
            { params.how === 'regex' &&
            <div className="flex gap-2">
                <div className="flex-1">
                    <label htmlFor="regex-pattern" className="block px-2 text-[13px] text-ai">Regex pattern</label>
                    <input id="regex-pattern" className="input w-full" onChange={handleFieldsOnChange} value={params.regex ?? ''}  placeholder="C\d{5}" name="regex" />
                </div>
                <div className="flex-1">
                    <label htmlFor="regex-flags" className="block px-2 text-[13px] text-ai">Regex flags</label>
                    <input id='regex-flags' className="input w-full" onChange={handleFieldsOnChange} value={params.flags ?? ''}  placeholder="gu" name="flags" />
                </div>
            </div>}
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button id="save-changes-button" className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button id="cancel-button" className="btn-secondary border-none bg-transparent text-[#FF0000]" disabled={!_model.config.params.harvestingSteps || _model.config.params.harvestingSteps.length === 0} onClick={onRemoveClass}>Remove Class</button>
            </div>
        </div>
    )
}

export default TextHarvesterParams
