import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { User } from "../types";

const useShareModel = () => {

    const queryClient = useQueryClient()

    const shareWithUser = useMutation({
        mutationFn: async (variables: { modelId: string, userId: string, params: {share: boolean} }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                return await axios.put<{modelId: string, userId: string}>(`/api/v1/models/${variables?.modelId}/users/${variables.userId}?shared=${variables.params.share}`, {}, options);
            }
        },
        onSuccess: (data, variables) =>{
            queryClient.invalidateQueries(["models", variables.modelId, "users"])
        }
    })

    const unshareWithUser = useMutation({
        mutationFn: async (variables: { modelId: string, userId: string }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                return await axios.delete(`/api/v1/models/${variables?.modelId}/users/${variables.userId}`, options);
            }
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(["models", variables.modelId, "users"])
        }
    })

    const shareWithGroup = useMutation({
        mutationFn: async (variables: { modelId: string, groupId: string, params: {share: boolean} }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                return await axios.put<{modelId: string, userId: string}>(`/api/v1/models/${variables?.modelId}/groups/${variables.groupId}?shared=${variables.params.share}`, {}, options);
            }
        },
        onSuccess: (data, variables) =>{
            queryClient.invalidateQueries(["models", variables.modelId, "groups"])
        }
    })

    const unshareWithGroup = useMutation({
        mutationFn: async (variables: { modelId: string, groupId: string }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                return await axios.delete(`/api/v1/models/${variables?.modelId}/groups/${variables.groupId}`, options);
            }
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(["models", variables.modelId, "groups"])
        }
    })

    return {
        shareWithUser,
        unshareWithUser,
        shareWithGroup,
        unshareWithGroup
    }

}

export default useShareModel
