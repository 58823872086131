import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from './AnalyticsSteps'
import { toast } from 'sonner'

const LocalModelParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {

    const [connectorName, setConnectorName] = useState('')
    const [params, setParams] = useState({
        path: '',
        inputType: '',
    })

    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                setParams({
                    ...params,
                    ..._model.config.params.analytics[stepIndex].params,
                })
                setConnectorName(_model.config.params.analytics[stepIndex].label)
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onSubmit = () => {
        if (connectorName.length === 0) {
            toast.warning('Required Fields', {
                description: `Please complete all required fields: ${['Name', 'Executable Code'].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`
            })
            return
        }
        onSaveChanges(connectorName, { ...params })
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='font-bold text-ai-700'>Local Model</p>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                    id='local-model-name'
                />
            </div>
            <div>
                <p className='text-[13px] text-ai'>Input type</p>
                <div className='flex gap-5'>
                    <div className='relative flex items-center'>
                        <input
                            id = 'cv-radio-button'
                            className='radio-parent peer'
                            name='inputType'
                            type='radio'
                            value='cv'
                            checked={params.inputType === 'cv'}
                            onChange={handleFieldsOnChange}
                        />
                        <div className='radio' />
                        <label htmlFor='' className='truncate pl-3'>
                            CV
                        </label>
                    </div>
                    <div className='relative flex items-center'>
                        <input
                            id = 'text-radio-button'
                            className='radio-parent peer'
                            name='inputType'
                            type='radio'
                            value='text'
                            checked={params.inputType === 'text'}
                            onChange={handleFieldsOnChange}
                        />
                        <div className='radio' />
                        <label htmlFor='' className='truncate pl-3'>
                            Text
                        </label>
                    </div>
                </div>
            </div>
            <div className='flex gap-2'>
                <div className='flex-1'>
                    <label className='px-2 text-[13px] text-ai'>Path</label>
                    <input
                        id = 'local-model-path-input'
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.path ?? ''}
                        placeholder='Path to model file'
                        name='path'
                    />
                </div>
            </div>
            <hr className='border-ai-200' />
            <div className='flex justify-between'>
                <button id="save-changes-button" className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button
                    id="cancel-button"
                    className='btn-secondary border-none bg-transparent text-[#FF0000]'
                    onClick={onCancel}
                >
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default LocalModelParams
