import React from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import GroupLayout from "./GroupLayout";

const Users: React.FC<{}> = () => {

	return (
		<DashboardLayout title="Groups">
			<GroupLayout />
		</DashboardLayout>
	);
};

export default Users;
