import React from 'react'
import StepProps from '../../features/types'
import Checkbox from '../Checkbox'

const RemoveCharactersStep: React.FC<StepProps> = ({ _model, json, step, editing, index, onConfigChange }) => {

    const [params, setParams] = React.useState({
        removeDigits: false,
        removePunctuation: false
    })

    React.useEffect(() => {
        if (editing) {
            setParams({
                removeDigits: step.params.removeDigits,
                removePunctuation: step.params.removePunctuation
            })
        }
    }, [step])

    if (!_model) {
        return null
    }

    const onAddStep = (e: any) => {
        let _config = { ...json }
        if (_model) {
            const step = {
                className: 'RemoveCharacters',
                params
            }
            if (editing && index !== null && index !== undefined && _config.params.preprocessingSteps) {
                _config.params.preprocessingSteps[0].steps[index] = { ...step }
            } else {
                if (!_config.params.preprocessingSteps || _config.params.preprocessingSteps.length === 0) {
                    _config.params.preprocessingSteps = []
                    _config.params.preprocessingSteps.push({
                        className: 'TextPreprocessor',
                        steps: []
                    })
                }
                _config.params.preprocessingSteps[0].steps.push(step)
            }
            onConfigChange(_config)
        }
    }

    const onCheckParamsChange = (e: any) => {
        setParams({
            ...params,
            [e.target.name]: e.target.checked
        })
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='text-ai-700 font-bold'>Remove Characters</p>
            <div className='flex gap-4'>
                <div className='flex gap-2'>
                    <Checkbox checked={params.removeDigits} name="removeDigits" onChange={onCheckParamsChange}/>
                    <label className='block px-2 text-ai'>Remove digits</label>
                </div>
                <div className='flex gap-2'>
                    <Checkbox checked={params.removePunctuation} name="removePunctuation" onChange={onCheckParamsChange}/>
                    <label className='block px-2 text-ai'>Remove punctuaction</label>
                </div>
            </div>
            <hr className='border-ai-200'/>
            <div>
                <button color="primary" className='btn-primary' onClick={onAddStep}> {editing ? 'Edit Step' : 'Add Step'} </button>
            </div>
        </div>
    )
}

export default RemoveCharactersStep

