import React from "react"
import ModalViewLayout from "./ModalViewLayout"
import { useLayout } from "../Store/Hooks"
import { ArrowRightAlt } from "@mui/icons-material"
import { useConfigUpdate, usePromoteModel } from "../features/mutations"

const PromoteModelPopup: React.FC<{}> = () => {
    const configMutation = useConfigUpdate()
    const promoteMutation = usePromoteModel()
    const { promotionParams, openPromotePopup } = useLayout()
    const { open, stage } = promotionParams
    const [versionValidation, setVersionValidation] = React.useState<any>({
        hasPromotedModel: false,
        version: null,
    })

    React.useEffect(() => {
        if (promotionParams.model && promotionParams.model.history) {
            setVersionValidation(checkIfModelHasPromotion())
        }
    }, [promotionParams])

    const onPromoteModel = () => {
        if (promotionParams.model) {
            if (stage === "staging" || stage === "production") {
                if (versionValidation.hasPromotedModel) {
                    configMutation.mutate({
                        modelId: versionValidation.version.id,
                        config: {
                            ...promotionParams.model.config,
                            params: {
                                ...promotionParams.model.config.params,
                                stage: "experimental",
                            },
                        },
                    })
                }
            }
            promoteMutation.mutate({
                modelId: promotionParams.model.id,
                config: {
                    ...promotionParams.model.config,
                    params: {
                        ...promotionParams.model.config.params,
                        stage: promotionParams.stage,
                    },
                },
            })
            openPromotePopup({ ...promotionParams, open: false })
        }
    }

    const checkIfModelHasPromotion = () => {
        let validation: any = {
            hasPromotedModel: false,
            version: null,
        }
        if (promotionParams.model && promotionParams.model.history) {
            if (promotionParams.model.history.length > 0) {
                for (const version of promotionParams.model.history) {
                    if ((version.stage === "staging" || version.stage === "production") && version.id !== promotionParams.model.id) {
                        validation = {
                            hasPromotedModel: true,
                            version,
                        }
                        break
                    }
                }
            }
        }
        return validation
    }

    const onPopupClose = () => {
        openPromotePopup({ ...promotionParams, open: false })
    }

    if (!promotionParams.model) {
        return null
    }

    return (
        <ModalViewLayout open={open} title={"Model Stage Management"} subtitle={`Moving model stage to ${promotionParams.stage}`} onSubmit={onPromoteModel} onClose={onPopupClose}>
            <div className="grid gap-2">
                <span className="font-bold">{promotionParams.model.config.params.name}</span>
                <div className="flex bg-ai-50 p-3">
                    <>
                        <p className="font-bold">v.{promotionParams.model.config.params.version ?? "1"}</p>
                        <div className="flex flex-1 items-center justify-center gap-3 text-xs text-white [&>p]:rounded-full [&>p]:p-2 [&>p]:px-3">
                            <p className="bg-red-600">{promotionParams.model.config.params.stage}</p>
                            <ArrowRightAlt className="text-black" />
                            <p className="bg-orange-400 text-black">{stage}</p>
                        </div>
                    </>
                    {versionValidation && versionValidation.hasPromotedModel && (
                        <>
                            <p className="font-bold">v.{versionValidation.version.version ?? "1"}</p>
                            <div className="flex flex-1 items-center justify-center gap-3 text-xs text-white [&>p]:rounded-full [&>p]:p-2 [&>p]:px-3">
                                <p className="bg-red-600">{versionValidation.version.stage}</p>
                                <ArrowRightAlt className="text-black" />
                                <p className="bg-orange-400 text-black">experimental</p>
                            </div>
                        </>
                    )}
                </div>
                <div className="px-16 py-4">
                    {(stage === "staging" || stage === "production") && (
                        <div className="grid gap-4 [&>p]:text-gray-600 [&>p]:before:content-['-_']">
                            <p>The model will be available to the public</p>
                            <p>The model will no longer be editable</p>
                            <p>The model's status will now be set to {stage}</p>
                        </div>
                    )}
                    {stage === "staging" && (
                        <div className="mt-4 grid gap-4 [&>p]:text-gray-600 [&>p]:before:content-['-_']">
                            <p>Users with the correct permissions can review this model and potentially move it to a production status</p>
                        </div>
                    )}
                    {stage === "archived" && (
                        <div className="grid gap-4 [&>p]:text-gray-600 [&>p]:before:content-['-_']">
                            <p>This model version will be in a disabled state</p>
                            <p>Model metrics won't take this model version into account.</p>
                        </div>
                    )}
                    {stage === "experimental" && (
                        <div className="grid gap-4 [&>p]:text-gray-600 [&>p]:before:content-['-_']">
                            <p>The model will be removed from the Registry</p>
                            <p>The model will be set back to experimental</p>
                            <p>It will need to go under review again if wanted back into production</p>
                        </div>
                    )}
                </div>
            </div>
        </ModalViewLayout>
    )
}

export default PromoteModelPopup
