import { Archive, CopyAllOutlined, Delete, Download, Timeline, Unarchive, UpgradeOutlined, ViewInAr } from "@mui/icons-material"
import { Box, Button } from "@mui/material"
import React from "react"
import { useDownloadModel } from "../../features/mutations";
import { AIRModel } from "../../features/types";
import ProtectedAction from "../../Layouts/ProtectedAction";
import { useLayout } from "../../Store/Hooks"

interface ModelDashActionsProps {
    model: AIRModel;
}

const ModelDashActions: React.FC<ModelDashActionsProps> = ({ model }) => {

    const downloadMutations = useDownloadModel()
    const { openClonePopup, openUpgradePopup, openPromotePopup, openDeletePopup } = useLayout()

    if (model == null) {
        return null
    }

    const onDownloadModel = () => {
        downloadMutations.model.mutate({ modelId: model.id, name: model.config.params.name })
    }

    const onDownloadFeedbacks = () => {
        downloadMutations.feedback.mutate({modelId: model.id, name: `Prediction_Feedbacks_${Math.floor(Date.now() / 1000)}`});
    }

    const onExportMetrics = () => {
        downloadMutations.metrics.mutate({modelId: model.id, name: `Usage_Metrics_${Math.floor(Date.now() / 1000)}`})
    }

    const onPromote = () =>{
        openPromotePopup({open: true, stage: 'staging', model})
    }

    const onUpgrade = () =>{
        openUpgradePopup({open: true, model})
    }

    const onClone = () =>{
        openClonePopup({open: true, model})
    }

    const onArchive = () => {
        openPromotePopup({open: true, stage: 'archived', model})
    }

    const onUnarchive = () => {
        openPromotePopup({open: true, stage: 'experimental', model})
    }

    const onDelete = () =>{
        openDeletePopup({open: true, redirectTo: '/workspace', model})
    }

    return (
        <Box>
            <ProtectedAction permission="update model">
                {model.isOwner && model.config.params.stage === 'experimental' &&
                <Box sx={{
                    paddingBottom: '8px'
                }}>
                    <Button onClick={onPromote} variant="text" startIcon={<ViewInAr />}>Promote to Registry</Button>
                </Box>}
            </ProtectedAction>
            <Box sx={{
                paddingBottom: '8px'
            }}>
                <Button onClick={onUpgrade} variant="text" startIcon={<UpgradeOutlined />}>Upgrade Version</Button>
            </Box>
            <Box sx={{
                paddingBottom: '8px'
            }}>
                <Button onClick={onClone} variant="text" startIcon={<CopyAllOutlined />}>Clone Model</Button>
            </Box>
            <Box sx={{
                paddingBottom: '8px'
            }}>
                <Button variant="text" startIcon={<Timeline />} onClick={onExportMetrics}> Export Metrics</Button>
            </Box>
            <Box sx={{
                paddingBottom: '8px'
            }}>
                <Button variant="text" startIcon={<Download />} onClick={onDownloadModel}>Download</Button>
            </Box>
            <Box sx={{
                paddingBottom: '8px'
            }}>
                <Button variant="text" startIcon={<Download />} onClick={onDownloadFeedbacks}>Download Feedback</Button>
            </Box>
            <ProtectedAction permission="update model">
                {model.isOwner && model.config.params.stage !== "archived" &&
                <Box sx={{
                    paddingBottom: '8px'
                }}>
                    <Button variant="text" startIcon={<Archive />} onClick={onArchive}>Archive Model</Button>
                </Box>}
                {model.isOwner && model.config.params.stage === "archived" &&
                <Box sx={{
                    paddingBottom: '8px'
                }}>
                    <Button variant="text" startIcon={<Unarchive />} onClick={onUnarchive}>Unarchive Model</Button>
                </Box>}
            </ProtectedAction>
            <ProtectedAction permission="delete model">
                {model.isOwner && model.config.params.stage === 'experimental' &&
                <Box sx={{
                    paddingBottom: '8px'
                }}>
                    <Button color='error' variant="text" startIcon={<Delete />} onClick={onDelete}>Delete Model</Button>
                </Box>}
            </ProtectedAction>
        </Box>
    )
}

export default ModelDashActions
