import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { endpoints } from "../endpoints";
import { ModelConfigRoot } from "../types";

const usePromoteModel = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (variables: { modelId: string, config: ModelConfigRoot }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                const response = await axios.patch(`${endpoints.models}/${variables.modelId}`, { config: variables.config }, options);
                return response
            }
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['workspace'])
            queryClient.invalidateQueries(['models', variables.modelId])
        }
    })
}

export default usePromoteModel
