import React from 'react'
import StepProps from '../../features/types'
import Checkbox from '../Checkbox'

const TokenizeStep: React.FC<StepProps> = ({ _model, json, step, editing, index, onConfigChange }) => {

    const [ params, setParams ] = React.useState({
        splitSentences: false,
        splitWords: false,
        tokenPattern: ''
    })
    
    React.useEffect(() => {
        if(editing) {
            setParams({
                splitSentences: step.params.splitSentences,
                splitWords: step.params.splitWords,
                tokenPattern: step.params.tokenPattern
            })
        }
    }, [json])

    if(!_model) {
        return null
    }

    const onAddStep = (e: any) => {
        let _config = { ...json }
        if(_model) {
            const step = {
                className: 'Tokenize',
                params
            }
            if(editing && index !== null && index !== undefined && _config.params.preprocessingSteps) {
                _config.params.preprocessingSteps[0].steps[index] = {...step}
            } else {
                if (!_config.params.preprocessingSteps || _config.params.preprocessingSteps.length === 0) {
                    _config.params.preprocessingSteps = []
                    _config.params.preprocessingSteps.push({
                        className: 'TextPreprocessor',
                        steps: []
                    })
                }
                _config.params.preprocessingSteps[0].steps.push(step)
            }
            onConfigChange(_config)
        }
    }

    const onCheckParamsChange = (e: any) => {
        setParams({
            ...params,
            [e.target.name]: e.target.checked
        })
    }

    const onParamsChange = (e: any) => {
        setParams({
            ...params,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='text-ai-700 font-bold'>Add</p>
            <div className='flex gap-4'>
                <div className='flex gap-2'>
                    <Checkbox checked={params.splitSentences} name="splitSentences" onChange={onCheckParamsChange}/>
                    <label className='block px-2 text-ai'>Split sentences</label>
                </div>
                <div className='flex gap-2'>
                    <Checkbox checked={params.splitWords} name="splitWords" onChange={onCheckParamsChange}/>
                    <label className='block px-2 text-ai'>Split words</label>
                </div>
            </div>
            <hr className='border-ai-200'/>
            <div className='flex-1'>
                <label className='block px-2 text-ai text-[13px]'>Token pattern</label>
                <input type={"text"} className="input w-full" name="tokenPattern" value={params.tokenPattern} onChange={onParamsChange}/>
            </div>
            <hr className='border-ai-200'/>
            <div>
                <button color="primary" className='btn-primary' onClick={onAddStep}> {editing ? 'Edit Step' : 'Add Step'} </button>
            </div>
        </div>
    )
}

export default TokenizeStep

