import React from 'react'
import { AnalyticsProps } from '../AnalyticsSteps'
import AwsS3ReadParams from './AwsS3ReadParams'

const AwsS3Connector: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {

    if (!_model) {
        return null
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='font-bold text-ai-700'>AWS S3</p>
            <AwsS3ReadParams _model={_model} onSaveChanges={onSaveChanges} onCancel={onCancel} stepIndex={stepIndex} />
        </div>
    )
}

export default AwsS3Connector
