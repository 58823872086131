import { InfoOutlined } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import React from "react"
import { AIRModel } from "../../features/types";

interface ModelDashAboutProps {
    model: AIRModel;
}

const ModelDashAbout: React.FC<ModelDashAboutProps> = ({ model }) => {

    if (!model) {
        return null
    }

    return (
        <Box>
            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                marginBottom: '16px'
            }}>
                <Box sx={{
                    marginRight: '8px'
                }}>
                    <InfoOutlined color="primary" fontSize="small" />
                </Box>
                <Box flex={'1 0'}>
                    <Typography variant="subtitle2">
                        About
                    </Typography>
                </Box>
            </Box>
            <Typography variant="subtitle1">{model.config.params.name}</Typography>
            <Typography variant="subtitle1">{(model.config.params.description && model.config.params.description !== '') ? model.config.params.description : 'No Description'}</Typography>
            <Box sx={{
                display: 'flex',
                paddingTop: '16px'
            }}>
                <Box>
                    <Typography variant="subtitle2">Author</Typography>
                </Box>
            </Box>
            <Box sx={{
                marginBottom: '8px'
            }}>
                <Typography variant="subtitle1">{(model.config.params.author && model.config.params.author !== '') ? model.config.params.author : 'No author...'}</Typography>
            </Box>
            <Box sx={{
                display: 'flex'
            }}>
                <Box flex={'1 0'}>
                    <Typography variant="subtitle2" textAlign={'left'}>{`Created`}</Typography>
                    <Typography variant="subtitle1" textAlign={'left'}>{`${new Date(model.createdAt).toLocaleDateString()}`}</Typography>
                </Box>
                <Box flex={'1 0'}>
                    <Typography variant="subtitle2" textAlign={'right'}>{`Updated`}</Typography>
                    <Typography variant="subtitle1" textAlign={'right'}>{`${new Date(model.modifiedAt).toLocaleDateString()}`}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default ModelDashAbout