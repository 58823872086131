import React from "react"
import { useAuth } from "react-oidc-context"
import LogoSpinner from "../../Components/LogoSpinner";
import { Navigate } from "react-router-dom";
import { ROUTER_PATHS } from "../../Utils/constants";

const Landing: React.FC<{}> = () => {

    const auth = useAuth()

    if (auth.isLoading) {
        switch (auth.activeNavigator) {
            case "signinRedirect":
                return (
                    <div className='flex h-full w-full flex-col items-center justify-center bg-ai-700 bg-cover bg-center bg-no-repeat'>
                        <LogoSpinner message="Redirecting to sign in page..."/>
                    </div>
                )
            case "signoutRedirect":
                return (
                    <div className='flex h-full w-full flex-col items-center justify-center bg-ai-700 bg-cover bg-center bg-no-repeat'>
                        <LogoSpinner message="Signing you out..."/>
                    </div>
                )
        }
        return (
            <div className='flex h-full w-full flex-col items-center justify-center bg-ai-700 bg-cover bg-center bg-no-repeat'>
                <LogoSpinner message="Redirecting..." />
            </div>
        )
    }

    if (!auth.isLoading && !auth.isAuthenticated) {
        auth.signinRedirect();
    }

    if (!auth.isLoading && auth.isAuthenticated) {
        return <Navigate to={ROUTER_PATHS.workspace} replace />
    }

    return (
        <div className='flex h-full w-full flex-col items-center justify-center bg-ai-700 bg-cover bg-center bg-no-repeat'>
            <LogoSpinner />
        </div>
    )
}

export default Landing