import React from 'react'
import useGetGroups from '../../features/queries/useGetGroups'
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined'
import Dropdown from '../../Components/Dropdown'
import GroupFilterBar from './GroupFilterBar'
import { User } from '../../features/types'
import { useNavigate } from 'react-router-dom'
import { ROUTER_PATHS } from '../../Utils/constants'

let searchTimeout: any = null
const GroupCards: React.FC<{}> = () => {
    const [pageSize, setPageSize] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(0)
    const [searchTerm, setSearchTerm] = React.useState('')
    const [sortColumn, setSortColumn] = React.useState('displayName')
    const [sortDirection, setSortDirection] = React.useState('ASC')
    const groupsQuery = useGetGroups({ pageSize, page: currentPage, searchTerm, sortColumn, sortDirection })

    const [openedMenudId, setOpenedMenuId] = React.useState('')
    const handleMenuClick = (groupId: string) => {
        setOpenedMenuId(openedMenudId === groupId ? '' : groupId)
    }

    const handleSearchInput = (e: any) => {
        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }
        searchTimeout = setTimeout(() => {
            setSearchTerm(e.target.value)
        }, 500)
    }

    const handleSortChange = (e: any) => {
        setSortColumn(e.target.value)
    }

    const handleDirectionChange = (e: any) => {
        setSortDirection(e.target.value)
    }

    const handlePageSizeChange = (e: any) => {
        setPageSize(e.target.value as number)
    }

    const pages = Array.from(Array(groupsQuery.data?.totalPages ?? 0).keys())

    const navigate = useNavigate()
    const onSelectedTab = (path: string) => {
        navigate(path)
    }

    const LoadingGroups = () => (
        <>
            <div className='flex h-56 w-full flex-col items-center justify-center gap-2 text-ai-800'>
                <h1 className='text-base font-bold'>Fetching list of groups</h1>
                <div className='flex items-center justify-center'>
                    <div className='mt-2 flex h-10 w-10 animate-spin items-center justify-center rounded-full bg-gradient-to-tr from-ai-800 via-ai-500 to-ai-300'>
                        <div className='h-8 w-8 rounded-full bg-ai-100'></div>
                    </div>
                </div>
            </div>
        </>
    )

    return (
        <>
            <GroupFilterBar
                searchOnChange={handleSearchInput}
                sortKeyOnChange={handleSortChange}
                sortOrderOnChange={handleDirectionChange}
            />
            <div className='grid grid-rows-[1fr_auto] gap-4 '>
                <div className='grid h-min w-full gap-3 sm:grid-cols-[repeat(auto-fit,minmax(300px,1fr))]'>
                    {groupsQuery.isLoading && <LoadingGroups />}
                    {!groupsQuery.isLoading && (!groupsQuery.data || (groupsQuery.data && groupsQuery.data.content.length === 0)) && (
                        <>
                            <div className='flex h-56 w-full flex-col items-center justify-center gap-2 text-ai-800'>
                                <h1 className='text-base font-bold'>There are no groups to display</h1>
                            </div>
                        </>
                    )}
                    {!groupsQuery.isLoading &&
                        groupsQuery.data &&
                        groupsQuery.data.content.map(group => (
                            <div className='relative space-y-2 rounded-md bg-white p-4 shadow-sm' key={group.id}>
                                <div className='flex justify-between'>
                                    <div
                                        className='flex-1 cursor-pointer truncate text-lg font-bold text-ai decoration-2 underline-offset-4 hover:underline'
                                        onClick={onSelectedTab.bind(this, ROUTER_PATHS.groups + `/${group.id}`)}
                                    >
                                        {group.displayName}
                                    </div>
                                    {/* <div onClick={() => handleMenuClick(group.id)} onBlur={() => setOpenedMenuId("")} tabIndex={1} className="relative">
                                    <MoreVertIcon className="cursor-pointer text-ai" />
                                    {openedMenudId === group.id && (
                                        <ul className="absolute right-0 z-10  w-max select-none rounded-md border  border-ai-200 bg-white text-sm shadow-sm">
                                            <li className="cursor-pointer px-4 py-2 hover:bg-ai-50">
                                                <DeleteOutlineIcon className="mr-2  text-ai" />
                                                Delete Group
                                            </li>
                                        </ul>
                                    )}
                                </div> */}
                                </div>
                                <hr className='my-0 -mx-4 h-[2px] bg-ai-200' />
                                <div className='flex flex-col gap-4 pt-2'>
                                    <div className='flex items-center gap-2 text-xs'>
                                        <div className='flex items-center -space-x-3'>
                                            {group.members && group.members.length > 0 ? (
                                                group.members.slice(0, 5).map((member: User, index: number) => (
                                                    <p
                                                        key={index}
                                                        className='flex h-8 w-8  items-center justify-center rounded-full border-2 border-white bg-ai text-white'
                                                    >
                                                        {member.givenName.charAt(0).toUpperCase()}
                                                        {member.familyName.charAt(0).toUpperCase()}
                                                    </p>
                                                ))
                                            ) : (
                                                <div className='flex h-8 w-8  items-center justify-center rounded-full border-2 border-white bg-ai text-sm text-white'>
                                                    <PersonOffOutlinedIcon fontSize='inherit' />
                                                </div>
                                            )}
                                        </div>
                                        {group.members && group.members.length > 0 ? (
                                            <span className='underline-offset-2 hover:cursor-pointer hover:underline'>
                                                <p onClick={onSelectedTab.bind(this, ROUTER_PATHS.groups + `/${group.id}`)} className=''>
                                                    {group.members.length} Members
                                                </p>
                                            </span>
                                        ) : (
                                            <span className='flex items-center'>No Members found</span>
                                        )}
                                    </div>
                                    <div className='flex space-x-6 text-sm'>
                                        <div>
                                            <p>Created</p>
                                            <p className='font-bold'>
                                                {group.createdDate ? new Date(group.createdDate).toLocaleDateString() : '---'}
                                            </p>
                                        </div>
                                        <div>
                                            <p>Updated</p>
                                            <p className='font-bold'>
                                                {group.modifiedDate ? new Date(group.modifiedDate).toLocaleDateString() : '---'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

                {/* Pagination  & Qty Button */}
                <div className='flex max-h-12 w-full text-sm'>
                    <div className='relative left-[52px] mx-auto flex flex-1 items-center justify-center gap-2'>
                        {pages.map(page => {
                            return (
                                <button
                                    key={'users-page-' + page + 1}
                                    onClick={() => {
                                        setCurrentPage(page)
                                    }}
                                    className={`h-10 w-10  rounded-md ${
                                        currentPage === page ? 'bg-ai text-white' : 'bg-white hover:bg-ai hover:text-white'
                                    }`}
                                >
                                    {page + 1}
                                </button>
                            )
                        })}
                    </div>
                    <div className='relative flex items-center justify-center gap-2'>
                        <p>Show</p>
                        <Dropdown
                            selectedIndex={['10', '25', '50'].indexOf(pageSize.toString())}
                            handleDropdownChange={handlePageSizeChange}
                            items={['10', '25', '50']}
                            direction='up'
                            size='sm'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupCards
