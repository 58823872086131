import React, { useRef, useState } from 'react'
import logo from '../../Assets/logo.png'
import { Navigate } from 'react-router-dom'
import { ROUTER_PATHS } from '../../Utils/constants'
import ThirdPartyLogins from './ThirdPartyLogins'
import { useLogin } from '../../features/mutations'
import { useGetCurrentUser } from '../../features/queries'
import { useGetAuthModes } from '../../features/queries'
import LogoSpinner from '../../Components/LogoSpinner'
import { TwoSeventyRing } from 'react-svg-spinners'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useAuth } from 'react-oidc-context'

const Login: React.FC<{}> = () => {
    const auth = useAuth();
    // const email = useRef<HTMLInputElement>(null)
    // const password = useRef<HTMLInputElement>(null)
    // const loginMutation = useLogin()
    const verifyQuery = useGetCurrentUser()
    // const authModes = useGetAuthModes()
    // const [visibility, setVisibility] = useState(false)

    // const handleSubmit = (e: any) => {
    //     e.preventDefault()
    //     const params = {
    //         email: email.current?.value,
    //         password: password.current?.value,
    //     }
    //     onFormSubmit(params)
    // }

    // const onPasswordVisibility = (e: any) => {
    //     e.preventDefault()
    //     setVisibility(!visibility)
    // }

    // const onFormSubmit = (data: any) => {
    //     loginMutation.mutate(data)
    // }

    const onLoginSubmit = (e: any) => {
        e.preventDefault()
        auth.signinRedirect()
    }

    if (verifyQuery.isFetched && !verifyQuery.isLoading && verifyQuery.data?.data) {
        return <Navigate to={ROUTER_PATHS.workspace} replace />
    }

    return (
        <div className='flex h-full w-full flex-col items-center justify-center bg-ai-700 bg-cover bg-center bg-no-repeat'>
            {verifyQuery.isLoading ? (
                <LogoSpinner />
            ) : (
                <form onSubmit={e => onLoginSubmit(e)} className='max-w-md space-y-6 rounded-lg bg-white p-10 shadow-lg'>
                    <img src={logo} alt='AI Sqaured logo' className='contain mx-auto mb-4 w-3/5' />
                    {/* {!authModes.isLoading && authModes.data && authModes.data.includes('local') && (
                        <>
                            <input
                                type='text'
                                className='input w-full'
                                placeholder='Email'
                                ref={email}
                                required
                                onInvalid={e =>
                                    (e.target as HTMLInputElement).setCustomValidity(
                                        (e.target as HTMLInputElement).willValidate ? '' : 'Please enter a valid email address.',
                                    )
                                }
                            />
                            <div className='relative flex'>
                                <input
                                    type={visibility ? 'text' : 'password'}
                                    className='input w-full'
                                    placeholder='Password'
                                    ref={password}
                                    required
                                    onInvalid={e =>
                                        (e.target as HTMLInputElement).setCustomValidity(
                                            (e.target as HTMLInputElement).willValidate ? '' : 'Please provide your password.',
                                        )
                                    }
                                />
                                <button
                                    type='button'
                                    className='icon-btn absolute right-2 top-2 text-ai-500'
                                    onClick={onPasswordVisibility}
                                >
                                    {visibility ? <VisibilityOff /> : <Visibility />}
                                </button>
                            </div>
                            <button type='submit' className='btn-primary w-full' name='local-login-btn'>
                                {loginMutation.isLoading ? <TwoSeventyRing color='white' /> : 'Login'}
                            </button>
                        </>
                    )} */}
                    {/* {!authModes.isLoading && authModes.data && authModes.data.includes('okta') && ( */}
                        <>
                            <hr className='my-0 h-px rounded-full bg-ai-200' />
                            <button className="w-full hover:bg-ai p-3 rounded-md hover:text-white transition-all duration-300">
                                <span className="font-bold">Log in</span>
                            </button>
                        </>
                    {/* )} */}
                </form>
            )}
        </div>
    )
}

export default Login
