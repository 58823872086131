import React, { useState } from 'react'
import DashboardLayout from '@/Layouts/DashboardLayout'

const GettingStarted: React.FC<{}> = () => {

    return (
        <DashboardLayout title='Getting Started'>
            <div className='flex h-full w-full flex-col gap-2 p-10'>
                <p className='font-bold text-2xl text-ai-700'>Hello!</p>
            </div>
        </DashboardLayout>
    )
}

export default GettingStarted
