import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetGroup } from "../../features/queries";
import { Group } from "../../features/types";
import DashboardLayout from "../../Layouts/DashboardLayout";
import { ROUTER_PATHS } from "../../Utils/constants";
import GroupsIcon from "@mui/icons-material/Groups";

const GroupDetails: React.FC<{}> = () => {
    const { id } = useParams();
    const groupQuery = useGetGroup(id ?? "");
    const group = groupQuery.data as Group;

    const navigate = useNavigate();

    const handleBackToGroups = () => {
        navigate(ROUTER_PATHS.groups);
    };

    if (!group) {
        return null;
    }

    return (
        <DashboardLayout title={"Group Management"}>
            {/* Top Section/Navigation */}
            {id && (
                <div className="mb-6 flex items-center">
                    <p className="cursor-pointer text-sm text-ai-800 hover:underline" onClick={handleBackToGroups}>
                        Groups
                    </p>
                    <p className="mx-2 text-sm text-ai-800">/</p>
                    <p className="text-sm font-bold text-ai-800">{`${group.displayName}`}</p>
                </div>
            )}
            {/* Bottom Section/Container */}
            <div className="flex bg-white p-4">
                {/* Left Section */}
                <div className="grid w-1/2 gap-2 bg-ai-50 p-6">
                    <h1 className="text-[24px] font-bold text-ai-700">{id && `${group.displayName}`}</h1>
                    <hr className=" h-px border-0 bg-gray-300" />
                    <div className="grid gap-2">
                        <h2 className="font-bold text-ai-800">Group Details</h2>
                        <p className="text-[12px] capitalize">Group Name</p>
                        <p className="rounded border border-gray-300 bg-ai-100 p-3 text-ai-800">{group.displayName}</p>
                        <p className="text-[12px] capitalize">Role name</p>
                        <p className="rounded border border-gray-300 bg-ai-100 p-3 text-ai-800">{group.roleName}</p>
                        <p className="text-[12px] capitalize">Created Date</p>
                        <p className="rounded border border-gray-300 bg-ai-100 p-3 text-ai-800">{group.createdDate ? new Date(group.createdDate).toLocaleDateString() : "---"}</p>
                        <hr className="my-5 h-px border-0 bg-gray-300" />
                    </div>
                    <h2 className="font-bold text-ai-700">Group Privileges</h2>
                    <p className="rounded border border-gray-300 bg-ai-100 p-3 text-ai-800">{group.permissions ? group.permissions : "No privileges assigned"}</p>
                </div>

                {/* Right Section */}
                <div className="w-1/2 bg-ai-100 p-4">
                    <div className="flex items-center justify-between">
                        <p className="mb-8 font-bold text-ai-800">Groups Members</p>
                        <p className="mb-8 text-sm text-ai-800">{group.members.length} Members</p>
                    </div>
                    <div className="">
                        {group.members && group.members.length > 0 ? (
                            group.members.map((member) => {
                                return <p key={member.id} className="my-2 rounded border border-gray-300 bg-ai-50 p-3 text-ai-800">{`${member.givenName} ${member.familyName}`}</p>;
                            })
                        ) : (
                            <div className="">
                                <p className="w-full text-center">No members to show</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default GroupDetails;
