import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import { Box, Chip, Collapse, List, ListItem, ListItemButton, Button, Typography } from "@mui/material"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AIRModel } from "../../features/types"
import ProtectedAction from "../../Layouts/ProtectedAction"
import { useLayout } from "../../Store/Hooks"
import { ROUTER_PATHS } from "../../Utils/constants"
import { getMUIColorForStage } from "../../Utils/utils"

interface ModelDashVersionProps {
    model: AIRModel;
}

const ModelDashVersions: React.FC<ModelDashVersionProps> = ({ model }) => {

    const navigate = useNavigate()
    const location = useLocation()
    const { openPromotePopup } = useLayout()

    // Version collapse
    const [collapseVersions, setCollapseVersions] = React.useState(false)

    const onVersionSelect = (versionId: string) => {
        if (location.pathname.includes('insights')) {
            navigate(ROUTER_PATHS.model.replace(':id', versionId) + '/insights')
        }
        if (location.pathname.includes('configuration')) {
            navigate(ROUTER_PATHS.model.replace(':id', versionId) + '/configuration')
        }
    }

    const onPromote = () => {
        openPromotePopup({open: true, stage: 'production', model})
    }

    const onDowngrade = () => {
        openPromotePopup({open: true, stage: 'experimental', model})
    }

    if (!model) {
        return null
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px'
            }}>
                <Box flex={'0 0'}>
                    <Button variant="contained" color="primary" endIcon={collapseVersions ? <ArrowDropUp /> : <ArrowDropDown />} onClick={() => { setCollapseVersions(!collapseVersions) }}>
                        v.{model.config.params.version}
                    </Button>
                </Box>
                <Box flex={'1 0'} display='flex' flexDirection={'row-reverse'}>
                    <Chip label={model.config.params.stage} color={getMUIColorForStage(model.config.params.stage)} />
                </Box>
            </Box>
            <Collapse in={collapseVersions} sx={{
                bgcolor: 'info.main'
            }}>
                <List>
                    {
                        model.history.map((h, index) => {
                            return (
                                <ListItem key={h.version + index} sx={{
                                    padding: 0
                                }}>
                                    <ListItemButton onClick={onVersionSelect.bind(this, h.id)}>
                                        <Box sx={{
                                            display: 'flex',
                                            width: '100%'
                                        }}>
                                            <Box sx={{
                                                flex: '1 0'
                                            }}>
                                                <Typography variant="subtitle2">
                                                    - v.{h.version}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Chip variant="outlined" label={h.stage} color={getMUIColorForStage(h.stage)} size="small" />
                                            </Box>
                                        </Box>
                                    </ListItemButton>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Collapse>
            <ProtectedAction permission="update model">
                { 
                model.config.params.stage === 'staging' &&
                model.isOwner &&
                <Box padding={'24px 0'}>
                    <Button variant="contained" fullWidth onClick={onPromote}>
                        Promote to Production  
                    </Button>
                </Box>}
                { 
                model.config.params.stage === 'production' &&
                model.isOwner &&
                <Box padding={'24px 0'}>
                    <Button variant="outlined" color="error" fullWidth onClick={onDowngrade}>
                        Remove from Production 
                    </Button>
                </Box>
                }
                { 
                model.config.params.stage === 'staging' &&
                model.isOwner &&
                <Box padding={'24px 0'}>
                    <Button variant="outlined" color="error" fullWidth onClick={onDowngrade}>
                        Remove from Staging 
                    </Button>
                </Box>
                }
            </ProtectedAction>
        </Box>
    )
}

export default ModelDashVersions