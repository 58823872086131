import React from "react"
import ModalViewLayout from "./ModalViewLayout"
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useLayout } from "../Store/Hooks";
import { useCloneModel } from "../features/mutations";

const CloneModelPopup: React.FC<{}> = () => {

    const cloneMutation = useCloneModel()
    const { cloneParams, openClonePopup } = useLayout()
    const [cloneName, setCloneName] = React.useState('');
    const [cloneDescription, setCloneDescription] = React.useState('');

    if (!cloneParams.model) {
        return null
    }

    const onCloneModel = () => {
        if (cloneParams.model) {
            cloneMutation.mutate({ modelId: cloneParams.model.id, name: cloneName, description: cloneDescription })
            openClonePopup({
                open: false,
                model: null
            })
        }
    }

    const onPopupClose = () => {
        openClonePopup({
            open: false,
            model: null
        })
    }

    return (
        <ModalViewLayout
            open={cloneParams.open}
            title="Clone Model"
            subtitle={`Creates a new v1 model from ${cloneParams.model.config.params.name}'s configuration and content`}
            onSubmit={onCloneModel}
            onClose={onPopupClose}>
            <Typography variant='subtitle2'>
                Model Name
            </Typography>
            <TextField value={cloneName} onChange={(e) => { setCloneName(e.target.value) }} fullWidth variant="outlined" size='small' />
            <Typography variant="subtitle2" sx={{ marginTop: '8px' }}>Description</Typography>
            <TextField value={cloneDescription} onChange={(e) => { setCloneDescription(e.target.value) }} fullWidth multiline rows={3} />
        </ModalViewLayout>
    )
}

export default CloneModelPopup