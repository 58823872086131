import React, { useEffect, useState } from 'react'
import { JSONTree } from 'react-json-tree'
import { JSONTreeTheme } from '../../../Utils/constants'
import { useAnalyticPreview } from '../../../features/mutations'
import { useLayout } from '../../../Store/Hooks'
import { Editor } from '@monaco-editor/react'
import { AIRModel } from '@/features/types'
import { DataTable } from '@/Components/tables/DataTable'
import { DataTableColumnHeader } from '@/Components/DataTableColumnHeaderProps'
import _ from 'lodash'

export const defaultParams = {
    code: '',
    dataSource: '',
    bucket: '',
    column: ' ',
    connectorType: '',
    connectorAction: '',
    fileNames: [] as string[],
    fileName: '',
    filterByColumns: [] as ColumnFilter[],
    inputType: 'text',
    soql: '',
    arn: '',
    dbName: '',
    dbUser: '',
    clusterName: '',
    query: '',
    account: '',
    password: '',
    warehouse: '',
    schema: '',
    schedule: null as { timezone: string, cron: string } | null,
    organization: '',
    token: '',
    host: '',
    port: '',
    path: '',
    dbTable: '',
    dataMap: [] as DataMap[],
    role: '',
    clientId: '',
    clientSecret: '',
    authenticationType: '', //UPCredentials
    syncKeys: {
        source: '',
        destination: '',
    },
    overwrite: false as boolean,
}

export interface ColumnFilter {
    inputType: string
    columnName: string
    columnValue: string
}

export interface DataMap {
    input: string
    output: string
}

export const queryUsesInputVariable = (query: string): boolean => {
    return query.includes('{{input}}')
}

const options = {
    readOnly: false,
    minimap: { enabled: false },
}

const getSchema = (val: any, keys: any[] = []): any => {
    return _.isObject(val) ? // if it's an object or array
      _.flatMap(val, (v, k) => getSchema(v, [...keys, k])) // iterate it and call fn with the value and the collected keys
      :
      keys.join('.') // return the joined keys
}

export const AnalyticPreviewResults: React.FC<{ preview: any[] }> = ({ preview }) => {

    const [columns, setColumns] = React.useState<any[]>([])

    useEffect(() => {
        if(preview && preview.length > 0) {
            const keys = getSchema(preview[0])
            let _columns: any[] = []
            for(const key of keys) {
                _columns.push({ accessorKey: key, header: key.split('.').slice(-1) })
            }
            setColumns(_columns)
        }
    }, [preview])

    return (
        <div className='grid grid-cols-1'>
            <div className='col-span-1 overflow-x-auto overflow-y-auto rounded-md py-0 scrollbar-thin text-sm'>
                <DataTable columns={columns} data={preview} />
            </div>
        </div>
    )
}

export const ConnectorQuery: React.FC<{ _model: AIRModel, connectorName: string, params: any, queryLanguage: string, previewValidations: string[], onQueryChange: (query: string | undefined) => void }> = ({ _model, connectorName, params, queryLanguage, previewValidations, onQueryChange }) => {

    const { configEditing, setAnalyticPreview } = useLayout()
    const previewMutation = useAnalyticPreview()
    const [previewReady, setPreviewReady] = useState(false)
    const [inputExample, setInputExample] = useState('')
    const [key, setKey] = useState(0)

    useEffect(() => {
        setKey(key + 1)
        return () => {
            setInputExample('')
            setAnalyticPreview(null)
        }
    }, [])

    useEffect(() => {
        let valid = true
        for(const field of previewValidations) {
            if(params[field].length === 0) {
                valid = false
                break
            }
        }
        setPreviewReady(valid)
    }, [params, previewValidations])

    const handleInputExample = (e: any) => {
        setInputExample(e.target.value)
    }

    const fetchPreviewData = (e: any) => {
        e.preventDefault()
        if (previewReady) {
            previewMutation.mutate({
                modelId: _model.id === 'new' ? undefined : _model.id,
                step: { className: 'ReverseMLWorkflow', params, label: connectorName },
                inputExample: (!queryUsesInputVariable(params.query) || inputExample.length === 0) ? undefined : inputExample
            })
        }
    }

    return (
        <>
            <div>
                <label className='px-2 text-[13px] text-ai'>Query*</label>
                <div className='bg-ai-50 p-2'>
                    <Editor
                        key={key}
                        options={options}
                        height='300px'
                        defaultLanguage={queryLanguage}
                        defaultValue={params.query || ''}
                        onChange={onQueryChange}
                    />
                    <p className='my-3 text-[13px] text-ai'>
                        Use <span className='rounded-md bg-ai-200 py-[2px] px-2 font-bold'>"{"WHERE <column-name> = {{input}}"}"</span> to filter data by harvested input.
                    </p>
                </div>
            </div>
            <div>
                { queryUsesInputVariable(params.query) &&    
                    <>
                        <label className='flex-1 px-2 text-[13px] text-ai'>Input Example</label>
                        <input
                            className='input w-full'
                            type={'text'}
                            onChange={handleInputExample}
                            value={inputExample}
                        />
                        <p className='my-3 text-[13px] text-ai'>
                            If using the input variable, provide an input example to enable data preview.
                        </p>
                    </>
                }
                <button disabled={(previewReady && queryUsesInputVariable(params.query) && inputExample.length === 0) ? true : previewReady ? false : true} className='btn-secondary py-1 px-2 text-sm' onClick={fetchPreviewData} id='fetch-preview-data-button'>
                    Fetch Preview Data
                </button>
            </div>
            <div className='flex w-full flex-col items-center justify-center'>
                {previewMutation.isLoading && <p className='font-[20px] text-ai'>Fetching data preview...</p>}
                {previewMutation.isError && (
                    <p className='text-ai'>Could not retrieve preview of the data - Review connector parameters</p>
                )}
                {!previewMutation.isLoading && configEditing.analyticsPreview && (
                    <div className='w-full flex-1 bg-ai-50 p-4'>
                        <p className='font-bold text-ai-700 text-sm'>Data Sample Preview</p>
                        <p className='text-sm'>Will fetch up to 10 rows of data.</p>
                        <AnalyticPreviewResults preview={configEditing.analyticsPreview} />
                    </div>
                )}
            </div>
        </>
    )
}
