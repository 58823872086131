import React from "react";
import { RenderingProps } from "./RenderingSteps";
import Dropdown from "../Dropdown";
import StyleParams from "./StyleParams";
import { getAnalyticPreviewKeys, getContainerIds } from "../../Utils/utils";
import ContentviewMessage from "../ContentviewMessage";
import { useLayout } from "../../Store/Hooks";
import { toast } from "sonner";

const availableTags = [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "span",
    "div",
    "br",
    "strong",
];

const elementMap = {
    h1: (content: string, style: any, childrenOpts: any | null) => { return <h1 style={{...style}}>{childrenOpts.injection === "prepend" && childrenOpts.children}{content}{childrenOpts.injection === "append" && childrenOpts.children}</h1> },
    h2: (content: string,style: any, childrenOpts: any | null) => { return <h2 style={{...style}}>{childrenOpts.injection === "prepend" && childrenOpts.children}{content}{childrenOpts.injection === "append" && childrenOpts.children}</h2> },
    h4: (content: string,style: any, childrenOpts: any | null) => { return <h3 style={{...style}}>{childrenOpts.injection === "prepend" && childrenOpts.children}{content}{childrenOpts.injection === "append" && childrenOpts.children}</h3> },
    h3: (content: string,style: any, childrenOpts: any | null) => { return <h4 style={{...style}}>{childrenOpts.injection === "prepend" && childrenOpts.children}{content}{childrenOpts.injection === "append" && childrenOpts.children}</h4> },
    h5: (content: string,style: any, childrenOpts: any | null) => { return <h5 style={{...style}}>{childrenOpts.injection === "prepend" && childrenOpts.children}{content}{childrenOpts.injection === "append" && childrenOpts.children}</h5> },
    h6: (content: string,style: any, childrenOpts: any | null) => { return <h6 style={{...style}}>{childrenOpts.injection === "prepend" && childrenOpts.children}{content}{childrenOpts.injection === "append" && childrenOpts.children}</h6> },
    p: (content: string,style: any, childrenOpts: any | null) => { return <p style={{...style}}>{childrenOpts.injection === "prepend" && childrenOpts.children}{content}{childrenOpts.injection === "append" && childrenOpts.children}</p> },
    span: (content: string,style: any, childrenOpts: any | null) => { return <span style={{...style}}>{childrenOpts.injection === "prepend" && childrenOpts.children}{content}{childrenOpts.injection === "append" && childrenOpts.children}</span> },
    div: (content: string,style: any, childrenOpts: any | null) => { return <div style={{...style}}>{childrenOpts.injection === "prepend" && childrenOpts.children}{content}{childrenOpts.injection === "append" && childrenOpts.children}</div> },
    br: (content: string,style: any, childrenOpts: any | null) => { return <br style={{...style}}>{childrenOpts.injection === "prepend" && childrenOpts.children}{content}{childrenOpts.injection === "append" && childrenOpts.children}</br> },
    strong: (content: string,style: any, childrenOpts: any | null) => { return <strong style={{...style}}>{childrenOpts.injection === "prepend" && childrenOpts.children}{content}{childrenOpts.injection === "append" && childrenOpts.children}</strong> },
}

const HtmlTagRenderingParams: React.FC<RenderingProps> = ({
    _model,
    stepIndex,
    onSaveChanges,
    onCancel,
}) => {

    const { configEditing } = useLayout()
    const [availableColumns, setAvailableColumns] = React.useState<string[]>([]);
    const [containerIds, setContainerIds] = React.useState<string[]>([]);
    const [tag, setTag] = React.useState("h1");
    const [extraTag, setExtraTag] = React.useState("h1");
    const [tagType, setTagType] = React.useState("static");
    const [injectAction, setInjectAction] = React.useState("prepend");
    const [params, setParams] = React.useState({
        id: "",
        containerId: "",
        htmlContent: "",
        predictionNameKey: "",
        predictionNameValue: "",
        predictionValueKey: "",
        content: "",
        injectionAction: "append",
        extraContentTag: "",
        yOffset: "",
        xOffset: "",
        canToggle: false,
        style: {
            content: {
                border: "none",
                margin: "0",
                padding: "8px",
                background: "transparent",
                borderRadius: "0",
                color: "#000000",
                fontSize: '16px',
                textAlign: 'left',
                textDecoration: 'none',
                textTransform: 'none',
                fontWeight: '',
                width: 'auto',
                height: 'auto',
            },
            extraContent: {
                border: "none",
                margin: "0",
                padding: "8px",
                background: "transparent",
                borderRadius: "0",
                color: "#000000",
                fontSize: '16px',
                textAlign: 'left',
                textDecoration: 'none',
                textTransform: 'none',
                fontWeight: '',
                width: 'auto',
                height: 'auto',
            }
        }
    });

    React.useEffect(() => {
        if(_model.config.params.renderingSteps) {
            setContainerIds(getContainerIds(_model))
            if (stepIndex > -1) {
                setParams({...params, ..._model.config.params.renderingSteps[stepIndex].params} );
                const _params =
                    _model.config.params.renderingSteps[stepIndex].params;
                // They exist and not empty
                if (_params.injectionAction && _params.injectionAction.length > 0) {
                    setInjectAction(_params.injectionAction);
                }
                if (_params.extraContentTag && _params.extraContentTag.length > 0) {
                    setExtraTag(_params.extraContentTag);
                }
                if (
                    _params.predictionNameKey &&
                    _params.predictionNameValue &&
                    _params.predictionValueKey &&
                    _params.predictionNameKey.length > 0 &&
                    _params.predictionNameValue.length > 0 &&
                    _params.predictionValueKey.length > 0
                ) {
                    setTagType("prediction");
                } else {
                    setTagType("static");
                }
                for (const tag of availableTags) {
                    if (_params.htmlContent.includes(`<${tag}`)) {
                        setTag(tag);
                    }
                }
            }
        }
    }, [stepIndex]);

    React.useEffect(() => {
        if(configEditing.analyticsPreview) {
            setAvailableColumns(getAnalyticPreviewKeys(configEditing.analyticsPreview))
        }
    }, [configEditing.analyticsPreview])

    if (!_model) {
        return null;
    }

    const handleTagSelection = (event: any) => {
        const index = Number(event.target.value)
        setTag(availableTags[index]);
    };

    const onContainerSelect = (event: any) => {
        const index = Number(event.target.value)
        setParams({...params, containerId: containerIds[index]});
    };

    const handleExtraTagSelection = (event: any) => {
        const index = Number(event.target.value)
        setExtraTag(availableTags[index]);
    };

    const handleRadioOnChange = (event: any) => {
        setTagType(event.target.value);
    };

    const handleInjectionRadioOnChange = (event: any) => {
        setInjectAction(event.target.value);
    };

    const handleFieldsOnChange = (event: any) => {
        if(event.target.type === "checkbox") {
            setParams({ ...params, [event.target.name]: event.target.checked });
        } else {
            setParams({ ...params, [event.target.name]: event.target.value });
        }
    };

    const handleStyleChange = (element: any, attribute: any, value: string | null) => {
        // Fixes issues with read-only errors
        let _p: any = {
            ...params,
            style: {
                ...params.style,
                [element]: {
                    ...params.style[element as keyof typeof params.style]
                }
            }
        }
        if(value !== null) {
            _p.style[element][attribute] = value
        } else {
            delete _p.style[element][attribute]
        }
        setParams(_p)
    }

    const previewElement = () => {
        const elem = elementMap[tag as keyof typeof elementMap]
        if(elem) {
            return elem(params.content, params.style.content, {injection: injectAction, children: previewExtraElement()})
        }
        return null
    }

    const previewExtraElement = () => {
        const elem = elementMap[extraTag as keyof typeof elementMap]
        if(tagType === "prediction" && elem) {
            const content = configEditing.analyticsPreview ? configEditing.analyticsPreview[params.predictionNameValue as keyof typeof configEditing.analyticsPreview] : "Data from prediction"
            return elem(content, params.style.extraContent, {injection: injectAction, children: null})
        }
        return null
    }

    const onSubmit = () => {
        let _params: any = { ...params };
        if(tag.length === 0 ||
            _params.id.length === 0 ||
            _params.content.length === 0) {
                toast.warning('Required Fields', {
                    description: `Please complete all required fields: ${["Tag", "ID", "Static content"].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`
                })
                return
        }
        if(_params.containerId.length === 0 &&
            (_params.yOffset.length === 0 || _params.xOffset.length === 0)) {
                toast.warning('Required Fields', {
                    description: `If no container was specified, please fill out the following fields: ${["X Offset", "Y Offset"].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`
                })
                return
        }
        if(tagType === 'prediction' &&
            (extraTag.length === 0 ||
            _params.predictionNameKey.length === 0 ||
            _params.predictionNameValue === 0) ||
            _params.predictionValueKey === 0 ||
            injectAction.length === 0) {
                toast.warning('Required Fields', {
                    description: `For extra data from prediction, please fill the following fields: ${["Prediction name key", "Prediction name value", "Prediction value key"].join(', ')}`
                })
                return
        } else {
            _params.extraContentTag = extraTag;
        }
        let htmlContent = `<${tag} id="${params.id}">${params.content}</${tag}>`;
        _params.htmlContent = htmlContent;
        _params.injectionAction = injectAction;
        if (tagType === "static") {
            delete _params.predictionNameKey
            delete _params.predictionNameValue
            delete _params.predictionValueKey
            delete _params.extraContentTag
        }
        onSaveChanges(_params);
    };

    return (
        <div className="flex flex-col gap-4">
            <p className="font-bold text-ai-700">HTML tag rendering</p>
            <div>
                { containerIds.length > 0 &&
                    <>
                        <label htmlFor="container-id" className="block text-ai text-[13px] px-2">Container ID</label>
                        <Dropdown selectedIndex={containerIds.indexOf(params.containerId)} placeholder="Select container" items={containerIds} handleDropdownChange={onContainerSelect}/>
                    </>
                }
                {
                    containerIds.length === 0 &&
                    <ContentviewMessage title="No Containers" message="Create containers for specific injections"/>
                }
            </div>
            <hr className="border-ai-200"/>
            <p className="font-bold text-ai-700">Identifiers</p>
            <div className="w-1/2">
                <p className="block text-ai text-[13px] px-2">Select Tag</p>
                <Dropdown id="identifier-tag" selectedIndex={availableTags.indexOf(tag)} placeholder="Select tag" items={availableTags} handleDropdownChange={handleTagSelection}/>
            </div>
            <div className="flex gap-2">
                <div className="flex-1">
                    <label htmlFor="element-id" className="block text-ai text-[13px] px-2">ID</label>
                    <input
                        id="element-id"
                        className="input w-full"
                        name="id"
                        value={params.id}
                        placeholder="tag-id"
                        onChange={handleFieldsOnChange}
                    />
                </div>
            </div>
            {params.containerId.length === 0 &&
                <>
                    <hr className="border-ai-200"/>
                    <p className="font-bold text-ai-700">Coordinates</p>
                    <div className="flex gap-2">
                        <div className="flex-1">
                            <p className="px-2 text-[13px] text-ai">X Coordinate</p>
                            <input
                                id={"x-coordinate"}
                                className="input w-full"
                                type="text"
                                name="xOffset"
                                placeholder="50"
                                value={params.xOffset ?? ""}
                                onChange={handleFieldsOnChange}
                            />
                            <p className="px-2 text-[13px] text-ai-800">
                                Number is represented in %
                            </p>
                        </div>
                        <div className="flex-1">
                            <p className="px-2 text-[13px] text-ai">Y Coordinate</p>
                            <input
                                id={"y-coordinate"}
                                className="input w-full"
                                type="text"
                                name="yOffset"
                                placeholder="50"
                                value={params.yOffset ?? ""}
                                onChange={handleFieldsOnChange}
                            />
                            <p className="px-2 text-[13px] text-ai-800">
                                Number is represented in %
                            </p>
                        </div>
                    </div>
                </>
            }
            <hr className="border-ai-200"/>
            <p className="font-bold text-ai-700">Content Type</p>
            <div>
                <div className="flex gap-5">
                    <div className="relative flex items-center">
                        <input
                            id={"static-radio-button"}
                            className="radio-parent peer"
                            name="position"
                            type="radio"
                            value="static"
                            checked={tagType === "static"}
                            onChange={handleRadioOnChange}
                        />
                        <div className="radio" />
                        <label htmlFor="" className="pl-3 truncate">
                            Only static content
                        </label>
                    </div>
                    <div className="relative flex items-center">
                        <input
                            id={"prediction-radio-button"}
                            className="radio-parent peer"
                            name="position"
                            type="radio"
                            value="prediction"
                            checked={tagType === "prediction"}
                            onChange={handleRadioOnChange}
                        />
                        <div className="radio" />
                        <label htmlFor="" className="pl-3 truncate">
                            Add data from prediction
                        </label>
                    </div>
                </div>
            </div>
            <div>
                <p className="px-2 text-[13px] text-ai">Static content</p>
                <input
                    id={"static-content"}
                    className="input w-full"
                    type="text"
                    name="content"
                    placeholder="Hello world"
                    value={params.content ?? ""}
                    onChange={handleFieldsOnChange}
                />
            </div>
            {tagType === "prediction" && (
                <div className="flex flex-col gap-4">
                    <hr className="border-ai-200"/>
                    <p className="font-bold text-ai-700">Content from prediction value</p>
                    <div>
                        <div className="w-1/2">
                            <p className="block text-ai text-[13px] px-2">Select Tag</p>
                            <Dropdown id="prediction-tag" selectedIndex={availableTags.indexOf(extraTag)} placeholder="Select tag" items={availableTags} handleDropdownChange={handleExtraTagSelection}/>
                        </div>
                    </div>
                    <div className="flex gap-5">
                        <div className="relative flex items-center">
                            <input
                                id={"before-radio-button"}
                                className="radio-parent peer"
                                type="radio"
                                value="prepend"
                                checked={injectAction === "prepend"}
                                onChange={handleInjectionRadioOnChange}
                            />
                            <div className="radio" />
                            <label htmlFor="" className="pl-3 truncate">
                                Before static content
                            </label>
                        </div>
                        <div className="relative flex items-center">
                            <input
                                id={"after-radio-button"}
                                className="radio-parent peer"
                                type="radio"
                                value="append"
                                checked={injectAction === "append"}
                                onChange={handleInjectionRadioOnChange}
                            />
                            <div className="radio" />
                            <label htmlFor="" className="pl-3 truncate">
                                After static content
                            </label>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <div className="flex-1">
                            <label htmlFor="prediction-name-key" className="block px-2 text-[13px] text-ai">Column Key</label>
                            <input
                                className="input w-full"
                                id="prediction-name-key"
                                placeholder="name"
                                name="predictionNameKey"
                                value={params.predictionNameKey}
                                onChange={handleFieldsOnChange}
                            />
                        </div>
                        <div className="flex-1">
                            <label htmlFor="prediction-name-key" className="block px-2 text-[13px] text-ai">Column Value</label>
                            {availableColumns.length === 0 &&
                            <input
                                className="input w-full"
                                id="prediction-name-value"
                                placeholder="LEAD_NAME"
                                name="predictionNameValue"
                                value={params.predictionNameValue}
                                onChange={handleFieldsOnChange}
                            />}
                            {availableColumns.length > 0 &&
                            <Dropdown
                                selectedIndex={availableColumns.indexOf(params.predictionNameValue)}
                                items={availableColumns}
                                placeholder="Select key"
                                handleDropdownChange={(e: any) => {
                                    const index = Number(e.target.value)
                                    setParams({...params, predictionNameValue: availableColumns[index]});
                                }} />
                            }
                        </div>
                    </div>
                    <div>
                        <label htmlFor="prediction-name-key" className="block px-2 text-[13px] text-ai">Value Key</label>
                        <input
                            className="input w-full"
                            id="prediction-value-key"
                            placeholder="value"
                            name="predictionValueKey"
                            value={params.predictionValueKey}
                            onChange={handleFieldsOnChange}
                        />
                    </div>
                </div>
            )}
            {/* <div className="flex items-center text-ai">
                <span className="mr-2">
                    <Checkbox
                        name="canToggle"
                        onChange={handleFieldsOnChange}
                        checked={params.canToggle}
                    />
                </span>
                <p className="text-ai-800">Togglable step</p>
            </div> */}
            <hr className="border-ai-200"/>
            <p className="text-ai-700 font-bold">Styling</p>
            <div className="w-full bg-ai-50 flex">
                <div className="flex-1">
                    <StyleParams style={params.style} onStyleChange={handleStyleChange}/>
                </div>
                <div className="flex-1 w-4/5 bg-ai-100 p-2 flex flex-col gap-2">
                    <p className="text-ai-700 font-bold">Preview</p>
                    <hr className="border-ai-200"/>
                    <div className="flex-1 flex flex-col justify-center gap-2 ">
                        {previewElement()}
                    </div>
                </div>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button id="save-changes-button" className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button id="cancel-button" className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
};

export default HtmlTagRenderingParams;
