import React from "react";
import { Link } from "react-router-dom";
import { AIRModel } from "../../features/types";
import { ROUTER_PATHS } from "../../Utils/constants";

interface RegistryListProps {
    registry: AIRModel[]
}

const RegistryList: React.FC<RegistryListProps> = ({ registry }) => {

    return (
        <div className=" bg-ai-100 p-2 text-ai text-sm">
            <table className="w-full table-auto border border-ai-200 text-left ">
                <thead className="bg-white ">
                    <tr className="capitalize [&>*]:px-2 [&>*]:py-[6px]">
                        <th>
                            <span className="">Model Name</span>
                        </th>
                        <th>
                            <span className="">Version</span>
                        </th>
                        <th>
                            <span className="">Upload</span>
                        </th>
                        <th>
                            <span className="">Updated</span>
                        </th>
                        <th>
                            <span className="">Stage</span>
                        </th>
                        <th>
                            <span className="">Author</span>
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-ai-200">
                    {registry.map((model, index) => (
                        <tr key={model.id} className="border [&>*]:truncate [&>td]:bg-ai-50 [&>td]:p-2 [&>td]:text-ai-800 [&>td]:w-auto">
                            <td>
                                <Link to={ROUTER_PATHS.model.replace(':id', model.id) + '/insights'}>{model.config?.params.name}</Link>
                            </td>
                            <td><p className="truncate w-3/4">{model.config.params.version}</p></td>
                            <td>{new Date(model.createdAt).toLocaleDateString()}</td>
                            <td>{new Date(model.modifiedAt).toLocaleDateString()}</td>
                            <td>{model.config.params.stage}</td>
                            <td>{model.config.params.author}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default RegistryList