import { stageMUIColorMap } from "./constants"
import JSZip from "jszip"
import { AIRModel, ModelConfigRoot } from "../features/types"

export const getContainerIds = (model: AIRModel): string[] => {
    let arr: string[] = []
    if(model.config.params.renderingSteps) {
        const containers = model.config.params.renderingSteps.filter((x) => x.className === "ContainerRendering")
        arr = containers.map((x) => x.params.id)
    }
    return arr
}

export const getAnalyticPreviewKeys = (preview: object): string[] => {
    let arr: string[] = []
    if(preview) {
        arr = Object.keys(preview)
    }
    return arr
}

export const getRandomColorFromPalette = (): string => {
    let color = 'primary.main'
    const possibleColors = [
        'primary.main',
        'primary.light',
        'secondary.main',
        'secondary.light',
        'error.main',
        'error.light',
        'warning.main',
        'warning.light',
        'success.dark'
    ]
    color = possibleColors[Math.floor(Math.random() * possibleColors.length)];
    return color
}

export const validateRequiredFields = (params: any, required: string[]): boolean => {
    let valid = true
    for (const req of required) {
        valid = !(
            params[req] === undefined ||
            params[req] === null ||
            // checks for empty required string
            params[req] === '' ||
            // checks for empty required arrays
            (Array.isArray(params[req]) && params[req].length === 0)
        )
    }
    return valid
}

export const createAIRZipFromConfig = async (config: ModelConfigRoot, resources: File[], rootFolder: string | null): Promise<File | null> => {
    const zip = new JSZip();
    let airFile: File | null = null
    try {
        zip.file("config.json", JSON.stringify(config))
        for(const file of resources) {
            if(rootFolder) {
                const folder = zip.folder(rootFolder.replace('/', ""))
                if(folder) {
                    folder.file(file.name, file)
                }
            } else {
                zip.file(file.name, file)
            }
        }
        const content = await zip.generateAsync({ type: 'blob' })
        if(content) {
            airFile = new File([content], `${config.params.name}.air`);
        }
    } catch (e) {
        console.log('error zipping config file')
        console.log(e)
    } finally {
        return airFile
    }
}

export const extractContentFromAIRZip = async (file: File): Promise<any | null> => {
    let config: any | null = null
    let files: File[] = []
    let rootFolder: string | null = null
    try {
        const zip = new JSZip();
        let content = await zip.loadAsync(file)
        if (content && content.files && content.files["config.json"]) {
            const configStr = await content.file("config.json")?.async("string");
            if(configStr) {
                config = await JSON.parse(configStr)
            }
            delete content.files["config.json"]
            for(const key of Object.keys(content.files)) {
                if(content.files[key].dir) {
                    rootFolder = content.files[key].name
                } else {
                    const blob = await content.file(key)?.async("blob")
                    if(blob) {
                        const fileNameArr = content.files[key].name.split('/')
                        files.push(new File([blob], fileNameArr[fileNameArr.length - 1]))
                    }
                }
            }
        }
    } catch (e) {
        console.log('error zipping config file')
        console.log(e)
    } finally {
        return {config, files, rootFolder}
    }
}

export const getMUIColorForStage = (stage: string): "default" | "success" | "error" | "info" | "primary" | "secondary" | "warning" | undefined => {
    return stageMUIColorMap[stage as keyof typeof stageMUIColorMap] as "default" | "success" | "error" | "info" | "primary" | "secondary" | "warning" | undefined
}

export const downloadModelFromBuffer = async (bufferArray: any, fileName: string) => {
    const data = Uint8Array.from(bufferArray);
    const content = new Blob([data.buffer], { type: 'octet/stream' });

    const encodedUri = window.URL.createObjectURL(content);
    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.air`);

    link.click();
    link.remove()
}

export const downloadFileFromBuffer = async (bufferArray: any, fileName: string) => {
    const data = Uint8Array.from(bufferArray);
    const content = new Blob([data.buffer], { type: 'octet/stream' });

    const encodedUri = window.URL.createObjectURL(content);
    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}`);

    link.click();
    link.remove()
}

export const isValidUrl = (urlString: string) => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
return !!urlPattern.test(urlString);
}

export const swapItems = (arr: any, a: any, b: any) => {
    arr[a] = arr.splice(b, 1, arr[a])[0];
    return arr;
}