import React from "react";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

interface Props {
    handleDropdownChange: (e: any) => void;
    selectedIndex?: number; // sets the selected active index if provided. if -1 uses the placeholder
    items: string[]; // expects an array of  strings ["item 1", "item 2", "item 3"]
    placeholder?: string; // expects a placeholder (optional): string, defaults to first item in 'items' array
    direction?: string; // expects a direction (optional): string, "up" or "down", defaults to "down"
    size?: string; // expects a size (optional): string, defaults to full
    id?: string; // expects a name (optional): string, defaults to ""
    label?: string; // expects a title (optional): string, defaults to ""
}

const Dropdown: React.FC<Props> = ({ items, placeholder, selectedIndex, direction = "down", size, handleDropdownChange, id = "", label }) => {
    const [isMenuOpen, setMenuOpen] = React.useState(false);
    const [defaultMenuItem, setDefaultMenuItem] = React.useState(placeholder);

    const defaultOpenDirection = direction && direction === "up" ? true : direction && direction === "down" ? false : false;

    React.useEffect(() => {
        if(selectedIndex !== undefined) {
            if(selectedIndex > -1) {
                setDefaultMenuItem(items[selectedIndex])
            } else {
                setDefaultMenuItem(placeholder ?? "Select")
            }
        }
    }, [items, selectedIndex])

    const handleMenuItem = (e: any) => {
        setDefaultMenuItem(e.target.innerText);
        handleDropdownChange(e);
    };

    return (
        <div
            id={id}
            className={`border border-ai-200 p-1 pl-2 rounded-md relative flex items-center select-none cursor-pointer h-10 bg-white ${size && size.length > 0 ? size : 'w-full'}`}
            onClick={() => setMenuOpen(!isMenuOpen)}
            onBlur={() => setMenuOpen(false)}
            tabIndex={1}>
            <span className={`w-full`}>{defaultMenuItem}</span>

            {defaultOpenDirection ? (
                <div>
                    <ArrowDropDownOutlinedIcon className={`transition duration-150 ease-in-out ${isMenuOpen ? "transform scale-y-100" : "transform -scale-y-100"}`} />
                </div>
            ) : (
                <div>
                    <ArrowDropDownOutlinedIcon className={`transition duration-150 ease-in-out ${isMenuOpen ? "transform -scale-y-100" : null}`} />
                </div>
            )}

            <p className="absolute -top-[10px] left-2 px-2 text-[10px]  bg-white">{label ? label : ""}</p>

            {isMenuOpen && (
                <div
                    className={`flex absolute z-50 bg-white  left-0 border border-ai-200 ${
                        defaultOpenDirection ? "bottom-8 rounded-t-md border-b-0 flex-col-reverse" : "top-8 rounded-b-md border-t-0 flex-col"
                    } w-full round scale-x-[1.02] italic text-sm1`}>
                    <ul className="overflow-x-scroll max-h-60 w-auto">
                        {items.map((item, i) => {
                            console.log(`${id}-${item}`)
                            return (
                                <li
                                    value={i}
                                    id={`${id}-${item.replace(" ", "")}`}
                                    key={i}
                                    className="= hover:bg-ai-50 py-2 px-4 cursor-pointer"
                                    onClick={(e) => {
                                        handleMenuItem(e);
                                    }}>
                                    {item}
                                </li>
                            )
                        } )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
