import React, { useEffect, useState } from 'react'
import { AnalyticsProps } from '../AnalyticsSteps'
import { ConnectorQuery, defaultParams } from './ReverseMLUtils'
import Checkbox from '../../Checkbox'
import 'react-js-cron/dist/styles.css'
import CronJobSelect from '@/Components/CronJobSelect'
import { toast } from 'sonner'

const DatabricksReadParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {

    const [connectorName, setConnectorName] = useState('')
    const [scheduled, setScheduled] = useState(false)
    const [params, setParams] = useState({ ...defaultParams, connectorType: 'databricks', connectorAction: 'read' })

    useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                const _params = _model.config.params.analytics[stepIndex].params
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setParams({
                    ...params,
                    ..._params,
                })
                if (_params.schedule) {
                    setScheduled(true)
                }
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const handleFieldsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onScheduledChange = (e: any) => {
        if (!e.target.checked) {
            setParams({ ...params, schedule: null })
        } else {
            setParams({ ...params, schedule: { timezone: '', cron: '* * * * *' } })
        }
        setScheduled(!scheduled)
    }

    const handleTimezoneOnChange = (value: string) => {
        if(!params.schedule) {
            setParams({ ...params, schedule: { timezone: value, cron: '' } })
        } else {
            setParams({ ...params, schedule: { ...params.schedule, timezone: value } })
        }
    }

    const handleCronOnChange = (value: string) => {
        if(!params.schedule) {
            setParams({ ...params, schedule: { timezone: '', cron: value } })
        } else {
            setParams({ ...params, schedule: { ...params.schedule, cron: value } })
        }
    }

    const onSubmit = () => {
        let _params = { ...params }
        if (
            connectorName.length === 0 ||
            _params.host.length === 0 ||
            _params.path.length === 0 ||
            _params.token.length === 0 ||
            _params.dbName.length === 0 ||
            _params.schema.length === 0 ||
            _params.query.length === 0
        ) {
            toast.warning('Required Fields', {
                description: `Please complete all required fields: ${['Name', 'Host', 'Path', 'Token', 'Name', 'Schema', 'Query'].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`
            })
            return
        }
        onSaveChanges(connectorName, _params)
    }

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                    disabled={stepIndex > -1}
                    id='databricks-read-name'
                />
            </div>
            <div className='grid grid-cols-6 gap-2'>
                <div className='col-span-full xl:col-span-2'>
                    <label className='px-2 text-[13px] text-ai'>Token*</label>
                    <input id='databricks-token' className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.token ?? ''} name='token' />
                </div>
                <div className='col-span-full xl:col-span-2'>
                    <label className='px-2 text-[13px] text-ai'>Host*</label>
                    <input id='databricks-host' className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.host ?? ''} name='host' />
                </div>
                <div className='col-span-full xl:col-span-2'>
                    <label className='px-2 text-[13px] text-ai'>Path*</label>
                    <input id='databricks-path' className='input w-full' type={'text'} onChange={handleFieldsOnChange} value={params.path ?? ''} name='path' />
                </div>
            </div>

            <div className='grid grid-cols-6 gap-2'>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Name*</label>
                    <input
                        id='databricks-database'
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.dbName ?? ''}
                        name='dbName'
                    />
                </div>
                <div className='col-span-full xl:col-span-3'>
                    <label className='px-2 text-[13px] text-ai'>Schema*</label>
                    <input
                        id='databricks-schema'
                        className='input w-full'
                        type={'text'}
                        onChange={handleFieldsOnChange}
                        value={params.schema ?? ''}
                        name='schema'
                    />
                </div>
            </div>
            <hr className='border-ai-200' />
            <ConnectorQuery _model={_model} connectorName={connectorName} params={params} queryLanguage='mysql' previewValidations={['token', 'host', 'path', 'schema', 'query']} onQueryChange={(query) => setParams({...params, query: query ?? ''})} />
            <hr className='border-ai-200' />
            {(_model.config.params.analytics?.length === 0 || stepIndex === 0) &&
                <>
                    <div className='flex flex-1 gap-2'>
                        <Checkbox checked={scheduled} onChange={onScheduledChange} />
                        <label className='block px-2 text-ai'>Schedule ETL?</label>
                    </div>
                    {scheduled && (
                        <CronJobSelect timezone={params.schedule?.timezone ?? ''} schedule={params.schedule?.cron ?? ''} onScheduleChange={handleCronOnChange} onTimezoneChange={handleTimezoneOnChange}/>
                    )}
                </>
            }
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit} id='save-changes-button'>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel} id='cancel-button'>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default DatabricksReadParams
