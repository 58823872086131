import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoints } from "../endpoints";
import { ServiceResponse, Group } from "../types";
import Qs from "qs";
import { useAuth } from "react-oidc-context";

interface GroupListQueryParams { 
    page: number,
    pageSize: number,
    searchTerm: string,
    sortColumn: string, 
    sortDirection: string
}

const useGetGroups = (params: GroupListQueryParams) => {
    const auth = useAuth()

    return useQuery(
        ["groups", { ...params }],
        async () => {
            let groupsResponse: ServiceResponse<Group[]> | null = null;
            try {
                const token = localStorage.getItem("token");
                const authtype = localStorage.getItem("authtype");
                if (token && authtype) {
                    const options = {
                        headers: {
                            authtype,
                            Authorization: `Bearer ${token}`,
                            "X-AIS-Tenant-Id": auth.user?.profile.tenant_id ?? ""
                        },
                        params,
                        paramsSerializer: (params: any) => {
                            return Qs.stringify(params);
                        },
                    };
                    const response = await axios.get<ServiceResponse<Group[]>>(endpoints.groups, options)
                    groupsResponse = response.data;
                }
            } catch (e) {
            } finally {
                return groupsResponse;
            }
        },
        {
            staleTime: 1000 * 60 * 60 * 24, // 24 hours in client cache
        }
    );
};

export default useGetGroups;
