import React from "react";
import { AIRModel } from "../../features/types";
import {
    AddCircleOutline,
    ArrowDownward,
    ArrowUpward,
    Remove,
} from "@mui/icons-material";
import Checkbox from "../Checkbox";
import { useLayout } from "../../Store/Hooks";
import Dropdown from "../Dropdown";
import { getAnalyticPreviewKeys } from "../../Utils/utils";

interface BasicChartProps {
    _model: AIRModel;
    params: any;
    onAddKey?: (column: string) => void;
    onRemoveKey?: (index: number) => void;
    handleFieldsOnChange: (event: any) => void;
    onDatasourceEdit: (datasource: any[]) => void;
}

const BasicChartRenderingParams: React.FC<BasicChartProps> = ({
    _model,
    params,
    onAddKey,
    onRemoveKey,
    handleFieldsOnChange,
    onDatasourceEdit,
}) => {
    const { configEditing } = useLayout()
    const [availableColumns, setAvailableColumns] = React.useState<string[]>([]);
    const [newColumn, setNewColumn] = React.useState("");
    const [selectedDatasource, setSelectedDatasource] = React.useState(-1);

    React.useEffect(() => {
        if(configEditing.analyticsPreview) {
            setAvailableColumns(getAnalyticPreviewKeys(configEditing.analyticsPreview))
        }
    }, [configEditing.analyticsPreview])

    if (!_model) {
        return null;
    }

    const handleDatasourceEdit = (e: any) => {
        let _datasource = [...params.datasource];
        if (e.target.type === "checkbox") {
            _datasource[selectedDatasource][e.target.name] = e.target.checked;
        } else {
            _datasource[selectedDatasource][e.target.name] = e.target.value;
        }
        if (onDatasourceEdit) {
            onDatasourceEdit(_datasource);
        }
    };

    const handleDatasourceSelect = (e: any) => {
        let _datasource = [...params.datasource];
        _datasource[selectedDatasource][e.target.id] = availableColumns[e.target.value];
        if (onDatasourceEdit) {
            onDatasourceEdit(_datasource);
        }
    };

    const handleNewRow = () => {
        if (newColumn.length === 0) return;
        if (onAddKey) {
            onAddKey(newColumn);
        }
    };

    const handleRemoveRow = (index: number) => {
        if (onRemoveKey) {
            onRemoveKey(index);
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <p className="font-bold text-ai-700">Identifiers</p>
            <div>
                <p className="px-2 text-[13px] text-ai">Chart ID</p>
                <input
                    id = 'chart-id'
                    className="input w-full"
                    type="text"
                    placeholder={`chart-id`}
                    name="id"
                    value={params.id ?? ""}
                    onChange={handleFieldsOnChange}
                />
            </div>
            <div className="flex gap-2">
                <div className="flex-1">
                    <p className="px-2 text-[13px] text-ai">Chart name</p>
                    <input
                        id = 'chart-name'
                        className="input w-full"
                        type="text"
                        name="chartName"
                        value={params.chartName ?? ""}
                        onChange={handleFieldsOnChange}
                    />
                </div>
            </div>
            <hr className="border-ai-200" />
            <p className="font-bold text-ai-700">Dimensions</p>
            <div className="flex gap-2">
                <div className="flex-1">
                    <p className="px-2 text-[13px] text-ai">Width</p>
                    <input
                        id = 'width'
                        className="input w-full"
                        type="text"
                        name="width"
                        placeholder="50"
                        value={params.width ?? ""}
                        onChange={handleFieldsOnChange}
                    />
                </div>
                <div className="flex-1">
                    <p className="px-2 text-[13px] text-ai">Height</p>
                    <input
                        id = 'height'
                        className="input w-full"
                        type="text"
                        name="height"
                        placeholder="50"
                        value={params.height ?? ""}
                        onChange={handleFieldsOnChange}
                    />
                </div>
            </div>
            { params.containerId.length === 0 &&
                <>
                    <hr className="border-ai-200" />
                    <p className="font-bold text-ai-700">Coordinates</p>
                    <div className="flex gap-2">
                        <div className="flex-1">
                            <p className="px-2 text-[13px] text-ai">X Coordinate</p>
                            <input
                                id = 'x-coordinate'
                                className="input w-full"
                                type="text"
                                name="xOffset"
                                placeholder="50"
                                value={params.xOffset ?? ""}
                                onChange={handleFieldsOnChange}
                            />
                            <p className="px-2 text-[13px] text-ai-800">
                                Number is represented in %
                            </p>
                        </div>
                        <div className="flex-1">
                            <p className="px-2 text-[13px] text-ai">Y Coordinate</p>
                            <input
                                id = 'y-coordinate'
                                className="input w-full"
                                type="text"
                                name="yOffset"
                                placeholder="50"
                                value={params.yOffset ?? ""}
                                onChange={handleFieldsOnChange}
                            />
                            <p className="px-2 text-[13px] text-ai-800">
                                Number is represented in %
                            </p>
                        </div>
                    </div>
                </>
            }
            <hr className="border-ai-200" />
            <p className="font-bold text-ai-700">Chart data settings</p>
            <div className="flex flex-col gap-3 rounded-sm border border-ai-200 bg-ai-100 p-2">
                <div className="flex items-center gap-2">
                    <div className="flex-1">
                        <label className="block px-2 text-[13px] text-ai">
                            Column Value
                        </label>
                        {availableColumns.length === 0 &&
                        <input
                            id = 'column-value'
                            className="input w-full"
                            type="text"
                            placeholder="Visits"
                            onChange={(e) => {
                                setNewColumn(e.target.value);
                            }}
                        />}
                        {availableColumns.length > 0 &&
                        <Dropdown
                            items={availableColumns}
                            placeholder="Select key"
                            handleDropdownChange={(e: any) => {
                                const index = Number(e.target.value)
                                setNewColumn(availableColumns[index]);
                            }} />
                        }
                        <label className="block px-2 text-[13px] text-ai-800">
                            Further editing can be done after adding column
                        </label>
                    </div>
                    <div>
                        <button
                            id = 'add'
                            className="btn-secondary flex items-center border border-ai p-3 text-[14px] font-bold"
                            onClick={handleNewRow}
                        >
                            <AddCircleOutline sx={{ fontSize: "16px" }} />
                            <span className="px-1">Add</span>
                        </button>
                    </div>
                </div>
                <div>
                    <table aria-label="datasources" className="border border-ai-200">
                        <thead className="border-b border-b-ai-200 bg-white text-left">
                            <tr>
                                <th className="w-1 px-4 py-2">
                                    <span className="text-[16px]  text-ai-800">
                                        #
                                    </span>
                                </th>
                                <th className="w-full">
                                    <span className="text-[16px]  text-ai-800">
                                        Column
                                    </span>
                                </th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="text-left">
                            {params.datasource.length === 0 && (
                                <tr>
                                    <td colSpan={4}>
                                        <div className="w-full p-6 text-center font-[16px] text-ai-800">
                                            There are no data sources yet.
                                        </div>
                                    </td>
                                </tr>
                            )}

                            {params.datasource.map((
                            source: {
                                predictionNameValue: string;
                                predictionNameKey: string;
                                predictionValueKey: string;
                                consolidateRows: boolean;
                                labelsKey: string | null;
                                group: boolean;
                                frequency: boolean;
                            },
                            index: number) => {
                                return (
                                    <React.Fragment key={source.predictionNameKey + index}>
                                        <tr key={source.predictionNameKey + index} className={"bg-ai-50"}>
                                            <td className="p-4">
                                                <span className=" text-ai-800">
                                                    {index + 1 + "."}
                                                </span>
                                            </td>
                                            <td>
                                                <span id='column-entry' className=" text-ai-800">
                                                    {source.predictionNameValue}
                                                </span>
                                            </td>
                                            <td>
                                                <button className="btn-icon"
                                                    onClick={() => setSelectedDatasource(selectedDatasource === index ? -1 : index) }>
                                                    {selectedDatasource ===
                                                    index ? (
                                                        <ArrowUpward color="primary" />
                                                    ) : (
                                                        <ArrowDownward />
                                                    )}
                                                </button>
                                            </td>
                                            <td>
                                                <button id="remove-entry" className="btn-icon"
                                                    onClick={() =>
                                                        handleRemoveRow(index)
                                                    }
                                                >
                                                    <Remove color="error" />
                                                </button>
                                            </td>
                                        </tr>
                                        { index !== params.datasource.length - 1 &&
                                        <tr>
                                            <td colSpan={4}>
                                                <hr className="border-ai-200"/>
                                            </td>
                                        </tr>}
                                        {index === selectedDatasource &&
                                            <tr key={source.predictionNameKey + index + '-edit-view'}>
                                                <td colSpan={4} className="bg-white">
                                                    <div className="flex flex-col gap-2 p-3">
                                                        <div className="flex justify-between">
                                                            <div className="flex items-center text-ai">
                                                                <span className='mr-2'>
                                                                    <Checkbox name="consolidateRows" onChange={handleDatasourceEdit} checked={source.consolidateRows} />
                                                                </span>
                                                                <p className="text-ai-800 text-sm">Consolidate Data</p>
                                                            </div>
                                                            <div className="flex items-center text-ai">
                                                                <span className='mr-2'>
                                                                    <Checkbox name="group" onChange={handleDatasourceEdit} checked={source.group} disabled={ !source.consolidateRows }/>
                                                                </span>
                                                                <p className="text-ai-800 text-sm">Group Data</p>
                                                            </div>
                                                            <div className="flex items-center text-ai">
                                                                <span className='mr-2'>
                                                                    <Checkbox name="frequency" onChange={handleDatasourceEdit} checked={source.frequency} disabled={ !source.consolidateRows && !source.group }/>
                                                                </span>
                                                                <p className="text-ai-800 text-sm">Show Data Frequency Only</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex gap-2">
                                                            <span className="flex-1">
                                                                <label className="block text-[13px] text-ai">Coloumn Key</label>
                                                                <input
                                                                    type={"text"}
                                                                    className="input w-full"
                                                                    value={ source.predictionNameKey }
                                                                    name="predictionNameKey"
                                                                    onChange={ handleDatasourceEdit }
                                                                />
                                                            </span>
                                                            <span className="flex-1">
                                                                {/* Prediction Name Value */}
                                                                <label className="block text-[13px] text-ai">Coloumn Value</label>
                                                                {availableColumns.length === 0 &&
                                                                    <input
                                                                    type={"text"}
                                                                    className="input w-full"
                                                                    value={ source.predictionNameValue }
                                                                    name="predictionNameValue"
                                                                    onChange={ handleDatasourceEdit }
                                                                />}
                                                                {availableColumns.length > 0 &&
                                                                <Dropdown
                                                                    selectedIndex={availableColumns.indexOf(source.predictionNameValue)}
                                                                    items={availableColumns}
                                                                    placeholder="Select key"
                                                                    id="predictionNameValue"
                                                                    handleDropdownChange={handleDatasourceSelect} />
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="flex gap-2">
                                                            <span className="flex-1">
                                                                 <label className="block text-[13px] text-ai">Value Key</label>
                                                                <input
                                                                    type={"text"}
                                                                    className="input w-full"
                                                                    value={ source.predictionValueKey }
                                                                    name="predictionValueKey"
                                                                    onChange={ handleDatasourceEdit }
                                                                />
                                                            </span>
                                                            <span className="flex-1">
                                                                <label className="block text-[13px] text-ai">Labels Key</label>
                                                                {availableColumns.length === 0 &&
                                                                <input
                                                                    type={"text"}
                                                                    className="input w-full"
                                                                    value={ source.labelsKey ?? '' }
                                                                    name="labelsKey"
                                                                    onChange={ handleDatasourceEdit }/>
                                                                }
                                                                {availableColumns.length > 0 &&
                                                                <Dropdown
                                                                    selectedIndex={availableColumns.indexOf(source.labelsKey ?? '')}
                                                                    items={availableColumns}
                                                                    placeholder="Select key"
                                                                    id="labelsKey"
                                                                    handleDropdownChange={handleDatasourceSelect} />
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </ React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="flex gap-4">
                <div className="flex items-center text-ai">
                    <span className="mr-2">
                        <Checkbox
                            id = 'display-legend'
                            name="displayLegend"
                            onChange={handleFieldsOnChange}
                            checked={params.displayLegend}
                        />
                    </span>
                    <p className="text-ai-800">Display legend</p>
                </div>
                {/* <div className="flex items-center text-ai">
                    <span className="mr-2">
                        <Checkbox
                            name="canToggle"
                            onChange={handleFieldsOnChange}
                            checked={params.canToggle}
                        />
                    </span>
                    <p className="text-ai-800">Togglable step</p>
                </div> */}
            </div>
        </div>
    );
};

export default BasicChartRenderingParams;
