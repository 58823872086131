import React from "react"
import { RenderingProps } from "./RenderingSteps"
import Checkbox from "../Checkbox"

const DocumentRenderingParams: React.FC<RenderingProps> = ({ _model, stepIndex, onSaveChanges, onCancel }) => {

    const [params, setParams] = React.useState({
        predictionKey: 'className',
        includeProbability: false,
        probabilityKey: 'probability',
        underlineColor: '',
        canToggle: false,
        thresholdKey: '',
        thresholdValue: ''
    })

    React.useEffect(() => {
        if(stepIndex > -1 && _model.config.params.renderingSteps) {
            setParams(_model.config.params.renderingSteps[stepIndex].params)
        }
    }, [stepIndex])

    if(!_model) {
        return null
    }

    const handleChecks = (event: any) => {
        setParams({ ...params, [event.target.name]: event.target.checked })
    };

    const handleFieldsOnChange = (event: any) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const onSubmit = () => {
        onSaveChanges(params)
    }

    return (
        <div className="flex flex-col gap-4">
            <p className="font-bold text-ai-700">
                Document Rendering
            </p>
            <div className="flex gap-2">
                <div>
                    <label htmlFor="prediction-key" className="block px-2 text-[13px] text-ai">Prediction Key</label>
                    <input id="prediction-key" className="input w-full" onChange={handleFieldsOnChange} value={params.predictionKey ?? ''}  placeholder="name" name="predictionKey" />
                </div>
                <div>
                    <label htmlFor="probability-key" className="block px-2 text-[13px] text-ai">Probability Key</label>
                    <input id="probability-key" className="input w-full" onChange={handleFieldsOnChange} value={params.probabilityKey ?? ''}  placeholder="probability" name="probabilityKey" />
                </div>
            </div>
            <div>
                <label htmlFor="badge-color" className="block px-2 text-[13px] text-ai">Badge Color</label>
                <input id="badge-color" className="input w-full" onChange={handleFieldsOnChange} value={params.underlineColor ?? ''}  placeholder="blue" name="underlineColor" />
                <label htmlFor="badge-color" className="block px-2 text-[13px] text-ai-800">CSS values accepted (blue, red, #f3f3f3)</label>
            </div>
            <div className="flex gap-2">
                <div>
                    <label htmlFor="threshold-key" className="block px-2 text-[13px] text-ai">Threshold Key</label>
                    <input id="threshold-key" className="input w-full" onChange={handleFieldsOnChange} value={params.thresholdKey ?? ''}  placeholder="name" name="thresholdKey" />
                </div>
                <div>
                    <label htmlFor="threshold-value" className="block px-2 text-[13px] text-ai">Threshold Value</label>
                    <input id="threshold-value" className="input w-full" onChange={handleFieldsOnChange} value={params.thresholdValue ?? ''}  placeholder="value" name="thresholdValue" />
                </div>
            </div>
            <div className="flex gap-4">
                <div className="flex items-center text-ai">
                    <span className='mr-2'>
                        <Checkbox name="includeProbability" onChange={handleChecks} checked={params.includeProbability} />
                    </span>
                    <p className="text-ai-800">Include Probability</p>
                </div>
                {/* <div className="flex items-center text-ai">
                    <span className='mr-2'>
                        <Checkbox name="canToggle" onChange={handleChecks} checked={params.canToggle} />
                    </span>
                    <p className="text-ai-800">Togglable step</p>
                </div> */}
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button id="save-changes-button" className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    )
}

export default DocumentRenderingParams