import React, { useState } from 'react'
import ModelDash from './ModelDash'
import { useNavigate, useParams } from 'react-router-dom'
import { useExportJobLogs, useGetJobs, useGetModel } from '../../features/queries'
import { ColumnDef } from '@tanstack/react-table'
import { DataTable } from '@/Components/tables/DataTable'
import { Button } from '@/Components/ui/button'
import { MoreHorizontal, ArrowDown01 } from 'lucide-react'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/Components/ui/dropdown-menu'
import { DataTableColumnHeader } from '@/Components/DataTableColumnHeaderProps'
import { ETLJobStatus } from '@/features/types'
import { Badge } from '@/Components/ui/badge'
import LogoSpinner from '@/Components/LogoSpinner'
import useJobRequests from '@/features/mutations/useJobRequests'
import { TwoSeventyRing } from 'react-svg-spinners'

const ModelJobs: React.FC<{}> = () => {
    const { id } = useParams()
    const exportJobLogs = useExportJobLogs()
    const modelQuery = useGetModel(id)
    const model = modelQuery.data
    const etlJobsQuery = useGetJobs(model?.id)
    const jobs = etlJobsQuery.data
    const [isHovered, setIsHovered] = useState(false)
    const navigate = useNavigate()
    const { runJob } = useJobRequests()

    const getStatusLabel = (status: 'running' | 'successful' | 'unsuccessful' | 'warning') => {
        let result = { label: 'Successful', className: 'bg-500' }
        switch (status) {
            case 'running':
                result = { label: 'Running', className: 'bg-ai-500 hover:bg-ai-500' }
                break
            case 'successful':
                result = { label: 'Completed', className: 'bg-green-600 hover:bg-green-600' }
                break
            case 'unsuccessful':
                result = { label: 'Failed', className: 'bg-red-500 hover:bg-red-500' }
                break
            case 'warning':
                result = { label: 'Warning', className: 'bg-amber-500 hover:bg-amber-500' }
                break
            default:
                result = { label: 'Running', className: 'bg-ai-500 hover:bg-ai-500' }
                break
        }
        return result
    }

    const downloadLogs = (etlStatusId: string, fileName: string) => {
        exportJobLogs.mutate({ etlStatusId, fileName })
    }

    const columns: ColumnDef<ETLJobStatus>[] = [
        {
            id: 'index',
            header: ({ column }) => {
                return (
                    <Button
                        className='-mx-2 flex w-10 items-center justify-center px-2'
                        variant='ghost'
                        onClick={() => column.toggleSorting(column.getIsSorted() !== 'asc')}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {isHovered ? <ArrowDown01 className='h-4 w-4' /> : <p>#</p>}
                    </Button>
                )
            },
            accessorKey: 'index',
            cell: cell => {
                return <div className='-ml-1.5 flex w-full items-center justify-center'>{cell.row.index + 1}</div>
            },
        },
        {
            header: ({ column }) => <DataTableColumnHeader column={column} title='ID' />,
            accessorKey: 'id',
            cell: cell => {
                const value = cell.getValue() as string
                return <div className='font-bold'>{value}</div>
            },
        },
        {
            header: ({ column }) => <DataTableColumnHeader column={column} title='Started at' />,
            accessorKey: 'startedAt',
            cell: cell => {
                const date = new Date(cell.getValue() as Date)
                return date.toLocaleString()
            },
        },
        {
            header: ({ column }) => <DataTableColumnHeader column={column} title='Finished at' />,
            accessorKey: 'finishedAt',
            cell: cell => {
                const date = new Date(cell.getValue() as string | number | Date)
                return date.toLocaleString()
            },
        },
        {
            header: ({ column }) => <DataTableColumnHeader column={column} title='Status' />,
            accessorKey: 'status',
            cell: cell => {
                const statusValue = cell.getValue() as 'running' | 'successful' | 'unsuccessful' | 'warning'
                const { label, className } = getStatusLabel(statusValue)
                return <Badge className={`rounded-sm ${className}`}>{label}</Badge>
            },
        },
        {
            id: 'actions',
            cell: ({ row }) => {
                const cell = row.original

                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant='ghost' className='h-8 w-8 p-0'>
                                <span className='sr-only'>Open menu</span>
                                <MoreHorizontal className='h-4 w-4' />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align='end'>
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem>View Job Details</DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={downloadLogs.bind(this, cell.id, 'ai2-logs')}>Download CSV</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    const handleRowClick = (row: ETLJobStatus) => {
        if (row && model) {
            navigate(`/models/${model?.id}/jobs/${row?.id}`)
        }
        return
    }

    const onRunJob = () => {
        if(!model) return
        runJob.mutate({ modelId: model.id, modelName: model.config.params.name })
    }

    return (
        <ModelDash hideVersionSelect>
            { (modelQuery.isLoading || etlJobsQuery.isLoading) &&
             <div className='flex h-full w-full flex-col gap-4 items-center justify-center'>
                <LogoSpinner />
            </div>
            }
            { !modelQuery.isLoading && !etlJobsQuery.isLoading &&
             <div className='flex h-full w-full flex-col gap-4'>
                <div className='flex'>
                    <p className='flex-1 text-lg font-bold text-ai'>History</p>
                    <div className='flex flex-row-reverse gap-3'>
                        <button disabled={runJob.isLoading} className='btn-primary py-1 text-sm' onClick={onRunJob}>
                            {runJob.isLoading ? <TwoSeventyRing height={20} width={20} color='#F1F3F8'/> : 'Run Job'}
                        </button>
                    </div>
                </div>
                <hr className='border-ai-200'/>
                <DataTable columns={columns} data={jobs ?? []} onRowClick={row => handleRowClick(row.original)} pagination searchBar />
            </div>
            }
        </ModelDash>
    )
}

export default ModelJobs
