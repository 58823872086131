import { useSelector } from 'react-redux'
import store from '../store'
import { setConfigAnalyticPreview, selectOpenClone, selectDeleteParams, selectPromotionParams, selectOpenVersion, selectSmallTabs, setOpenClone, setOpenDelete, setOpenPromote, setOpenVersion, setSmallTabs, PromotionParams, DeleteParams, PopupParams, ConfigEditingParams, selectConfigEditingParams } from '../Slices/layoutSlice'

const useLayout = () => {

  const smallTabs = useSelector(selectSmallTabs)
  const cloneParams = useSelector(selectOpenClone)
  const versionParams = useSelector(selectOpenVersion)
  const promotionParams = useSelector(selectPromotionParams)
  const deleteParams = useSelector(selectDeleteParams)
  const configEditing = useSelector(selectConfigEditingParams)

  const setSmallTabsState = (switchToSmall: boolean) => {
    store.dispatch(setSmallTabs(switchToSmall))
  }

  const openClonePopup = (params: PopupParams) => {
    store.dispatch(setOpenClone(params))
  }

  const openUpgradePopup = (params: PopupParams) => {
    store.dispatch(setOpenVersion(params))
  }

  const openPromotePopup = (params: PromotionParams) => {
    store.dispatch(setOpenPromote(params))
  }

  const openDeletePopup = (params: DeleteParams) => {
    store.dispatch(setOpenDelete(params))
  }

  const setAnalyticPreview = (preview: object | null) => {
    store.dispatch(setConfigAnalyticPreview(preview))
  }

  return {
    configEditing,
    smallTabs,
    cloneParams,
    versionParams,
    promotionParams,
    deleteParams,
    setSmallTabsState,
    openClonePopup,
    openUpgradePopup,
    openPromotePopup,
    openDeletePopup,
    setAnalyticPreview
  }
}

export default useLayout