import { useState } from 'react'
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    ColumnFiltersState,
    getFilteredRowModel,
    VisibilityState,
    getPaginationRowModel,
    Row,
} from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/Components/ui/table'
import { Button } from '@/Components/ui/button'
import { Input } from '@/Components/ui/input'
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/Components/ui/dropdown-menu'
import { Settings2 } from 'lucide-react'
import { DataTablePagination } from '../DataTablePaginationProps'

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[]
    data: TData[]
    pagination?: boolean
    searchBar?: boolean
    columnToggler?: boolean
    onRowClick?: (row: Row<any>) => void
}

export function DataTable<TData, TValue>({ columns, data, pagination = false, searchBar = false, columnToggler = false, onRowClick }: DataTableProps<TData, TValue>) {
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        ...(pagination ? { getPaginationRowModel: getPaginationRowModel() } : {}),
        state: {
            sorting,
            columnFilters,
            columnVisibility,
        },
    })

    return (
        <div className='flex flex-col gap-4'>
            <div className='flex items-center justify-between'>
                {searchBar && (
                    <div className='flex items-center'>
                        <Input
                            placeholder='Filter Id...'
                            value={(table.getColumn('id')?.getFilterValue() as string) ?? ''}
                            onChange={event => table.getColumn('id')?.setFilterValue(event.target.value)}
                            className='w-80'
                        />
                    </div>
                )}
                { columnToggler && <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant='outline' className='ml-auto flex gap-2'>
                            <Settings2 size={16} /> View
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align='end'>
                        <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        {table
                            .getAllColumns()
                            .filter(column => column.getCanHide())
                            .filter(column => column.id !== 'index' && column.id !== 'actions')
                            .map(column => {
                                return (
                                    <DropdownMenuCheckboxItem
                                        key={column.id}
                                        className='capitalize'
                                        checked={column.getIsVisible()}
                                        onCheckedChange={value => column.toggleVisibility(!!value)}
                                    >
                                        {column.id}
                                    </DropdownMenuCheckboxItem>
                                )
                            })}
                    </DropdownMenuContent>
                </DropdownMenu>}
            </div>
            <div className='rounded-md border text-sm bg-white'>
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <TableHead key={header.id} className='text-sm text-ai-600'>
                                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                        </TableHead>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    id={'insight-log-row'} // Assigning ID based on row index starting from 1
                                    data-state={row.getIsSelected() && 'selected'}
                                    onClick={() => (onRowClick ? onRowClick(row) : null)}
                                    className='cursor-pointer duration-200 ease-in-out'
                                >
                                    {row.getVisibleCells().map(cell => (
                                        <TableCell className='' key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className='h-24 text-center'>
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            {pagination && <DataTablePagination table={table} />}
        </div>
    )
}
