import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    typography: {
        fontFamily: [
            'Manrope',
            'sans-serif',
        ].join(','),
        h5: {
            fontSize: '24px',
            fontWeight: '700'
        },
        h6: {
            fontSize: '18px',
            fontWeight: '700'
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: '400',
            color: '#686868'
        },
        subtitle2: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#3B4256'
        },
        button: {
            textTransform: 'none'
        }
    },
    palette: {
        primary: { main: '#162EA2' },
        secondary: { main: '#001451' },
        info: { main: '#F7F7F8' },
        warning: { main: '#f0a000' },
        error: { main: '#f00540' },
        success: { main: '#88e31e' }
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'text' },
                    style: {
                        padding: '4px'
                    }
                }
            ],
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    padding: '4px 14px',
                    fontWeight: '700'
                }
            },
        },
        MuiTextField: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        backgroundColor: 'white'
                    }
                }
            ],
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    backgroundColor: 'white'
                }
            }
        }
    }
})