import React from "react"
import ModalViewLayout from "./ModalViewLayout"
import { useLayout } from "../Store/Hooks";
import { useVersionUpgrade } from "../features/mutations";

const UpgradeModelPopup: React.FC<{}> = () => {

    const versionMutation = useVersionUpgrade()
    const { versionParams, openUpgradePopup } = useLayout()

    if (!versionParams.model) {
        return null
    }

    const onUpgradeModel = () => {
        if(!versionParams.model) return
        versionMutation.mutate({modelId: versionParams.model.id})
        openUpgradePopup({
            open: false,
            model: null
        })
    }

    const onPopupClose = () => {
        openUpgradePopup({
            open: false,
            model: null
        })
    }

    return (
        <ModalViewLayout
            open={versionParams.open}
            title={"Upgrade Version"}
            subtitle={`Upgrade ${versionParams.model.config.params.name} from v${versionParams.model.config.params.version} to v${parseInt(versionParams.model.config.params.version ?? '1') + 1}`}
            onSubmit={onUpgradeModel}
            onClose={onPopupClose}
        />
    )
}

export default UpgradeModelPopup