import React from "react"

interface ContentviewMessageProps {
    title: string,
    message: string
}

const ContentviewMessage: React.FC<ContentviewMessageProps> = ({ title, message }) => {
    return (
        <div className="flex items-center justify-center bg-ai-100 p-2 h-full w-full">
            <div className="text-center">
                <p className="text-ai font-bold">
                    {title}
                </p>
                <p className="text-ai-800  text-sm">
                    {message}
                </p>
            </div>
        </div>
    )
}

export default ContentviewMessage