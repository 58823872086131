import React from "react"
import SimpleFeedbackParams from "./SimpleFeedbackParams"
import BinaryFeedbackParams from "./BinaryFeedbackParams"
import MulticlassFeedbackParams from "./MulticlassFeedbackParams"
import RegressionFeedbackParams from "./RegressionFeedbackParams"
import ModelFeedbackParams from "./ModelFeedbackParams"
import { validateRequiredFields } from "../../Utils/utils"
import { AIRModel, BasicStepsProps } from "../../features/types"
import Dropdown from "../Dropdown"
import StepFeedbackParams from "./StepFeedbackParams"
import { EditOutlined, Remove } from "@mui/icons-material"
import { toast } from "sonner"

export interface FeedbackProps {
    _model: AIRModel,
    onSaveChanges: (params: any) => void,
    onCancel: () => void,
    index: number
}

const feedbackOptions = [
    {
        option: "Simple Feedback",
        className: "SimpleFeedback",
        required: []
    },
    {
        option: "Regression Feedback",
        className: "RegressionFeedback",
        required: []
    },
    {
        option: "Binary Feedback",
        className: "BinaryFeedback",
        required: ['labelMap']
    },
    {
        option: "Multiclass Feedback",
        className: "MulticlassFeedback",
        required: ['labelMap']
    },
    {
        option: "Step Feedback",
        className: "StepFeedback",
        required: ['questions', 'step']
    },
    {
        option: "Model Feedback",
        className: "ModelFeedback",
        required: ['questions']
    }
]

const feedbackClassComponents = {
    SimpleFeedback: SimpleFeedbackParams,
    BinaryFeedback: BinaryFeedbackParams,
    MulticlassFeedback: MulticlassFeedbackParams,
    RegressionFeedback: RegressionFeedbackParams,
    StepFeedback: StepFeedbackParams,
    ModelFeedback: ModelFeedbackParams,
}

type ObjectKey = keyof typeof feedbackClassComponents;

const FeedbackSteps: React.FC<BasicStepsProps> = ({ _model, onConfigChange }) => {

    const [editIndex, setEditIndex] = React.useState(-1);
    const [optionClass, setOptionClass] = React.useState('')
    const [selectedOpt, setSelectedOpt] = React.useState<any>(null)
    const [modelFeedbackIndex, setModelFeedbackIndex] = React.useState<number | null>(null)
    const [feedbackIndex, setFeedbackIndex] = React.useState<number | null>(null)
    const [stepFeedbackIndex, setStepFeedbackIndex] = React.useState<number | null>(null)
    const [tabIndex, setTabIndex] = React.useState(0)

    React.useEffect(() => {
        setOptionClass('')
        setModelFeedbackIndex(null)
        const feedbackSteps = _model.config.params.feedbackSteps
        if(feedbackSteps) {
            feedbackSteps.forEach((step, i) => {
                switch (step.className) {
                    case 'ModelFeedback':
                        setModelFeedbackIndex(i)
                        break
                    case 'StepFeedback':
                        setStepFeedbackIndex(i)
                        break
                    default:
                        setFeedbackIndex(i)
                        break
                }

            })
        }
    }, [_model])

    if (_model === null) {
        return null
    }

    const onOptionChange = (event: any) => {
        setEditIndex(-1);
        let selectedIndex = Number(event.target.value);
        setOptionClass(feedbackOptions[selectedIndex].className);
        const selected = feedbackOptions.filter(
            (a) => a.className === feedbackOptions[selectedIndex].className
        )[0];
        setSelectedOpt(selected);
    };

    const onSaveChanges = (params: any) => {
        let required = selectedOpt?.required;
        let config = { ..._model.config };
        let steps = config.params.feedbackSteps ?? [];
        if (!required && editIndex > -1) {
            const possibleOption = feedbackOptions.find(
                (opts) => opts.className === steps[editIndex].className
            );
            if (possibleOption) {
                required = possibleOption.required;
            }
        }
        if (!validateRequiredFields(params, required)) {
            toast.warning('Required Fields', {
                description: `Please complete all required fields: ${selectedOpt.required.join(", ").replace(/, (?=[^,]*$)/, ' & ')}`
            })
            return;
        }
        if (editIndex > -1) {
            steps[editIndex].params = params;
            setEditIndex(-1);
        } else {
            steps.push({ className: optionClass, params });
            setOptionClass("");
        }
        config.params.feedbackSteps = steps;
        onConfigChange(config)
    }

    const onRemoveClass = (index: number) => {
        let steps = _model.config.params.feedbackSteps
            ? [..._model.config.params.feedbackSteps]
            : [];
        steps.splice(index, 1);
        let config = { ..._model.config };
        config.params.feedbackSteps = steps;
        onConfigChange(config)
        if (index === editIndex) {
            setEditIndex(-1);
        }
    };

    const onTabsClick = (index: number, className: string) => {
        setTabIndex(index)
        setOptionClass(className)
    }

    const renderParamsComponent = () => {
        const Component = feedbackClassComponents[optionClass as ObjectKey]
        if (Component) {
            return <Component _model={_model} onSaveChanges={onSaveChanges} index={-1} onCancel={() => {
                setSelectedOpt(null);
                setOptionClass("");
                setEditIndex(-1);
            }} />
        }
        return null
    }

    const renderEditParamsComponent = () => {
        if(_model.config.params.feedbackSteps && editIndex > -1) {
            const Component = feedbackClassComponents[(_model.config.params.feedbackSteps[editIndex].className as ObjectKey)];
            if (Component) {
                return (
                    <Component
                        _model={_model}
                        index={editIndex}
                        onSaveChanges={onSaveChanges}
                        onCancel={() => {
                            setSelectedOpt(null);
                            setOptionClass("");
                            setEditIndex(-1);
                        }}
                    />
                );
            }
        }
        return null;
    }

    const feedbackSteps = _model.config.params.feedbackSteps ?? [];
    const selectedClassIndex = optionClass.length > 0 ? feedbackOptions.findIndex((a) => a.className === optionClass) : -1

    return (
        <div>
            <p className="text-ai-700 text-[16px] font-bold mb-2">Feedback class</p>
            <Dropdown
                id={'feedback-class-dropdown'}
                items={feedbackOptions.map((opt) => opt.option) as string[]}
                selectedIndex={selectedClassIndex}
                handleDropdownChange={onOptionChange}
                size="w-1/2"
                placeholder={"Choose feedback class"}
            />
            <div className="my-6 flex flex-col gap-2">
                {renderParamsComponent()}
            </div>
            <div>
                <table aria-label="Rendering steps" className="w-full border border-gray-300">
                    <thead className="bg-white text-left border-b border-b-gray-300">
                        <tr>
                            <th className="px-4 py-2 w-1">
                                <span className="text-[16px] text-ai-800 ">#</span>
                            </th>
                            <th>
                                <span className="text-[16px] text-ai-800 ">Label</span>
                            </th>
                            <th>
                                <span className="text-[16px] text-ai-800 ">Class name</span>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="text-left">
                        {
                            feedbackSteps.length === 0 &&
                            <tr>
                                <td colSpan={7}>
                                    <div className="w-full p-6 text-ai-800 text-center font-[16px]">
                                        There are no classes yet.
                                    </div>
                                </td>
                            </tr>
                        }
                        {feedbackSteps.map((step: any, index: number) => {
                            return (
                                <React.Fragment key={step.className + index}>
                                    <tr className={index % 2 === 0 ? 'bg-ai-200' : ''}>
                                        <td className="p-4">
                                            <span className="text-ai-800 ">
                                                {index + 1 + "."}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="text-ai-800 ">
                                                {step.className ?? "N/A"}
                                            </span>
                                        </td>
                                        <td>
                                            <span id = {step.className} className="text-ai-800 ">
                                                {step.className}
                                            </span>
                                        </td>
                                        <td>
                                            <button id="edit" className="btn-icon"
                                                onClick={() => {
                                                    setSelectedOpt(null);
                                                    setOptionClass("");
                                                    if(editIndex === index) {
                                                        setEditIndex(-1);
                                                    } else {
                                                        setEditIndex(index);
                                                    }
                                                }}
                                            >
                                                <EditOutlined fontSize="small" />
                                            </button>
                                        </td>
                                        <td>
                                            <button id="remove" className="btn-icon"
                                                onClick={() => {
                                                    onRemoveClass(index);
                                                }}
                                            >
                                                <Remove color="error" />
                                            </button>
                                        </td>
                                    </tr>
                                    {editIndex > -1 && editIndex === index &&
                                    <tr key={step.className + index + '-edit-view'}>
                                        <td colSpan={7}>
                                            <div className="p-3 rounded bg-white">
                                                {renderEditParamsComponent()}
                                            </div>
                                        </td>
                                    </tr>}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FeedbackSteps
