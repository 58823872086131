import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { downloadFileFromBuffer, downloadModelFromBuffer } from "../../Utils/utils";

const useDownloadModel = () => {
    const model = useMutation({
        mutationFn: async (variables: { modelId: string, name: string }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                const res = await axios.get(`/download/v1/models/${variables.modelId}`, options);
                return await downloadModelFromBuffer(res.data.data.data, variables.name ?? `AIRModel_${new Date().toLocaleDateString()}`)
            }
        }
    })

    const feedback = useMutation({
        mutationFn: async (variables: { modelId: string, name: string }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                let predictionFeedbackCsvResp = await axios.get(`/download/v1/feedback/predictions/${variables.modelId}`, options);
                return await downloadFileFromBuffer(predictionFeedbackCsvResp.data.data.data, variables.name+'.csv');
            }
        }
    })

    const metrics = useMutation({
        mutationFn: async (variables: { modelId: string, name: string }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                const res = await axios.get(`/download/v1/usageMetrics/?entityId=${variables.modelId}`, options);
                return await downloadFileFromBuffer(res.data.data.data, variables.name+'.csv');
            }
        }
    })

    return {
        model,
        feedback,
        metrics
    }
}

export default useDownloadModel
