import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Book, Combine, DatabaseBackup } from 'lucide-react'
import { Card, CardFooter, CardContent } from '@/Components/ui/card'
import DashboardLayout from '../../Layouts/DashboardLayout'
import { ROUTER_PATHS } from '../../Utils/constants'
import { useGetCurrentUser } from '../../features/queries'
import { useWorkspace } from '../../features/queries'
import { TwoSeventyRing } from 'react-svg-spinners'
import UpgradePremiumMessage from '@/Components/UpgradePremiumMessage'

const ModelTypeSelection: React.FC = () => {
    const navigate = useNavigate()
    const currentUserQuery = useGetCurrentUser()
    const workspaceQuery = useWorkspace({ userOnly: 'true' })
    const maxModels = useMemo(() => {
        if (currentUserQuery.data) {
            if(currentUserQuery.data.data?.tenantSettings?.max_models_per_user) {
                return parseInt(currentUserQuery.data.data.tenantSettings.max_models_per_user)
            }
        }
    }, [currentUserQuery.data])
    const usersModels = useMemo(() => {
        if (workspaceQuery.data) {
            return workspaceQuery.data.length
        }
    }, [workspaceQuery.data])
    const limitReached = useMemo(() => {
        if (maxModels && usersModels) {
            return usersModels >= maxModels
        }
    }, [maxModels, usersModels])
    const userRole = useMemo(()=>{
        if(currentUserQuery.data?.data){
            return currentUserQuery.data?.data.roleName === "Community User"
        }
    }, [currentUserQuery.data?.data])

    const handleSelection = (modelCardType: 'leanai' | 'aiautomation') => {
        navigate(ROUTER_PATHS.create + `?modelCardType=${modelCardType}`)
    }

    return (
        <DashboardLayout title='New Model Card'>
            <div className='flex h-full w-full flex-col gap-2 p-6'>
                <p className='text-lg font-bold text-ai'>Model Card Type</p>
                <div className='flex items-end gap-2'>
                    <p className='text-sm text-ai-700'>
                        Model Cards empower you by enhancing your daily workflow via low-code configuration.
                    </p>
                    <a className='w-52' href='https://docs.squared.ai/guides/our-technology' target='_blank'>
                        <Book size={14} />
                        Learn more
                    </a>
                </div>
                <hr className='border-ai-200' />
                {userRole && limitReached && <UpgradePremiumMessage message='Maximum Models Reached' description='You have reached maximum limit for creating a Model Cards. Please contact a Sales Representative to upgrade your plan.' /> }
                <div className='selection-screen m-auto grid items-center gap-4 sm:grid-cols-1 md:grid-cols-2'>
                    {(currentUserQuery.isLoading || workspaceQuery.isLoading) && (
                        <>
                            <TwoSeventyRing color='#00249C' height={40} width={40} />
                        </>
                    )}
                    {!currentUserQuery.isLoading && !workspaceQuery.isLoading && (
                        <>
                            <Card id='aiautomation-card-select' className='col-span-1 flex h-full max-w-md flex-col'>
                                <CardContent className='flex-1 py-5'>
                                    <div className='flex h-full flex-col items-center justify-center gap-2'>
                                        <DatabaseBackup size={50} className='text-ai-700' />
                                        <div className='text-xl font-bold text-ai'>AI-Driven Data Orchestration</div>
                                        <p className='flex-1 text-justify text-sm text-ai-700'>
                                            AI-Driven Data Orchestration Model Cards can help you keep data products up to date by reading
                                            from data sources, transforming it, and writing back to a destination.
                                        </p>
                                        <a className='self-end' href='https://docs.squared.ai/guides/our-technology' target='_blank'>
                                            <Book size={14} />
                                            Learn more
                                        </a>
                                    </div>
                                </CardContent>
                                <CardFooter>
                                    <button
                                        id='aiautomation-button-select'
                                        onClick={() => handleSelection('aiautomation')}
                                        className='btn-primary w-full'
                                        disabled={userRole && limitReached}
                                    >
                                        Get Started
                                    </button>
                                </CardFooter>
                            </Card>
                            <Card id='leanai-card-select' className='col-span-1 flex h-full max-w-md flex-col'>
                                <CardContent className='flex-1 py-5'>
                                    <div className='flex h-full flex-col items-center justify-center gap-2'>
                                        <Combine size={50} className='text-ai-700' />
                                        <div className='text-xl font-bold text-ai'>Lean AI</div>
                                        <p className='flex-1 text-justify text-sm text-ai-700'>
                                            With our browser extension, Lean AI Model Cards augment your web applications by rendering data
                                            components based off harvests and analytic results.{' '}
                                        </p>
                                        <a className='self-end' href='https://docs.squared.ai/guides/our-technology' target='_blank'>
                                            <Book size={14} />
                                            Learn more
                                        </a>
                                    </div>
                                </CardContent>
                                <CardFooter>
                                    <button
                                        id='leanai-button-select'
                                        onClick={() => handleSelection('leanai')}
                                        className='btn-primary w-full'
                                        disabled={userRole && limitReached}
                                    >
                                        Get Started
                                    </button>
                                </CardFooter>
                            </Card>
                        </>
                    )}
                </div>
            </div>
        </DashboardLayout>
    )
}

export default ModelTypeSelection
