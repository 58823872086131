import React from "react";
import { FunctionComponent } from "react";

interface Props {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
}

const Modal: FunctionComponent<Props> = ({ children, isOpen, onClose }) => {
    return (
        <div className={` fixed inset-0 z-50 flex items-center justify-center bg-black/[0.05] pb-4  ${isOpen ? "opacity-100" : "pointer-events-none opacity-0"}`}>
            <div className="fixed inset-0 transition-opacity" onClick={onClose}></div>

            <div className={`transform overflow-hidden bg-white transition-all`} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div className="flex items-center justify-center gap-4 p-8 px-10 text-center ">
                    <div className="flex flex-col gap-6">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
