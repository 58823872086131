import React, { useState } from 'react'
import { AnalyticsProps } from './AnalyticsSteps'
import Editor from '@monaco-editor/react'
import { defaultParams } from './ReverseMLOpts/ReverseMLUtils'
import { toast } from 'sonner'

const CustomAnalyticParams: React.FC<AnalyticsProps> = ({ _model, onSaveChanges, onCancel, stepIndex }) => {
    const [connectorName, setConnectorName] = useState('')
    const [scheduled, setScheduled] = useState(false)
    const [params, setParams] = useState<typeof defaultParams>({...defaultParams,
        code: '',
        inputType: 'text'
    })
    const [key, setKey] = React.useState(0)

    const options = {
        readOnly: false,
        minimap: { enabled: false },
    }

    React.useEffect(() => {
        if (_model.config.params.analytics) {
            if (stepIndex > -1) {
                setParams({
                    ...params,
                    ..._model.config.params.analytics[stepIndex].params,
                })
                setConnectorName(_model.config.params.analytics[stepIndex].label)
                setKey(key + 1)
            }
        }
    }, [stepIndex])

    if (!_model) {
        return null
    }

    const handleConnectorNameOnchange = (e: any) => {
        setConnectorName(e.target.value)
    }

    const onSubmit = () => {
        if (connectorName.length === 0 || params.code.length === 0) {
            toast.warning('Required Fields', {
                description: `Please complete all required fields: ${['Name', 'Executable Code'].join(', ').replace(/, (?=[^,]*$)/, ' & ')}`
            })
            return
        }
        onSaveChanges(connectorName, { ...params })
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='font-bold text-ai-700'>Custom Analytic</p>
            <div>
                <label className='px-2 text-[13px] text-ai'>Name</label>
                <input
                    className='input w-full'
                    type={'text'}
                    onChange={handleConnectorNameOnchange}
                    value={connectorName}
                    placeholder='Give this connector a name'
                    name='label'
                    id='custom-analytic-name'
                />
            </div>
            <label className='px-2 pl-0 text-[13px] text-ai'>Executable JS Code*</label>
            <Editor
                key={key}
                options={options}
                height='300px'
                defaultLanguage='javascript'
                defaultValue={params.code || '// Place executable js code here'}
                onChange={js => {
                    setParams({ ...params, code: js ?? '' })
                }}
            />
            <hr className='border-ai-200' />
            <div className='flex justify-between'>
                <button className='btn-primary' onClick={onSubmit} id='save-changes-button'>
                    Save Changes
                </button>
                <button className='btn-secondary border-none bg-transparent text-[#FF0000]' onClick={onCancel} id='cancel-button'>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default CustomAnalyticParams
