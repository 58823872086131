export const endpoints = {
    verify: "/api/v1/auth/login/verify",
    login: "/api/v1/auth/login",
    logout: "/api/v1/auth/logout",
    oktaLogin: "/api/v1/auth/login",
    oktaLogout: "/api/v1/auth/logout",
    models: "/api/v1/models",
    users: "/userservice/v1/user",
    roles: "/userservice/v1/role",
    groups: "/groupservice/v1/group",
    authModes: "/api/v1/auth/modes",
    me: "/userservice/v1/user/me",
    jobStatus: "/api/v1/models/jobStatus",
    etlStatus: "/reverse-ml/v1/job/history",
    etlLogs: "/reverse-ml/v1/job/logs",
    exportEtlLogs: "/reverse-ml/v1/job/logs/export",
    testJob: "/reverse-ml/v1/job/test",
    runJob: "/reverse-ml/v1/job/run",
}