import { useQuery } from "@tanstack/react-query"
import axios from "axios";
import { endpoints } from "../endpoints";
import { useAuth } from "react-oidc-context";

const useGetSharedGroups = (modelId: string, params: {shared?: boolean}) => {
    const auth = useAuth()

    return useQuery(["models", modelId, "groups", params], async () => {
        const token = localStorage.getItem("token");
        const authtype = localStorage.getItem("authtype");
        if (token && authtype) {
            const options = {
                headers: {
                    authtype,
                    Authorization: `Bearer ${token}`,
                    "X-AIS-Tenant-Id": auth.user?.profile.tenant_id ?? ""
                }
            };
            const url = params.shared !== undefined || params.shared !== null ?
                `${endpoints.models}/${modelId}/groups?shared=${params.shared}` :
                `${endpoints.models}/${modelId}/groups`
            const res = await axios.get<{data: any[]}>(url, options);
            return res.data.data
        }
    }, {
        enabled: !!modelId || modelId.length !== 0
    })

}

export default useGetSharedGroups
