import './App.css'
import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { theme } from './Theme/theme'
import ProtectedRoute from './Layouts/ProtectedRoute'
import Models from './Views/Workspace'
import Registry from './Views/Registry'
import Users from './Views/Users/Users'
import Groups from './Views/Groups/Groups'
import { ModelInsights, ModelConfiguration } from './Views/Model'
import ModelEdit from './Views/Workspace/ModelEdit'
import { ROUTER_PATHS } from './Utils/constants'
import ModelPermissions from './Views/Model/ModelPermissions'
import UserDetails from './Views/Users/UserDetails'
import GroupDetails from './Views/Groups/GroupDetails'
import Landing from './Views/Landing/Landing'
import ModelPredictionFeedback from './Views/Model/ModelPredictionFeedback'
import ModelGenerativeFeedback from './Views/Model/ModelGenerativeFeedback'
import Login from './Views/Login'
import Credentials from './Views/Machine-learning/Credentials'
import Tuning from './Views/Machine-learning/Tuning'
import Serving from './Views/Machine-learning/Serving'
import TuningCreate from './Views/Machine-learning/TuningCreate'
import ServingCreate from './Views/Machine-learning/ServingCreate'
import ModelJobs from './Views/Model/ModelJobs'
import ModelJobsLogs from './Views/Model/ModelJobsLogs'
import ModelTypeSelection from './Views/Workspace/SelectModelCard'
import { GettingStarted } from './Views/GettingStarted'

function App() {

    return (
        // add "debug-screens" string to the div with className bellow to see the TailwindCSS screen sizes
        <div className='h-full'>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path={ROUTER_PATHS.gettingStarted} element={
                            <ProtectedRoute>
                                <GettingStarted />
                            </ProtectedRoute>
                        } />
                        <Route path={ROUTER_PATHS.model}>
                            <Route
                                path={'insights'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelInsights />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'predfeedback'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelPredictionFeedback />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'genfeedback'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelGenerativeFeedback />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'permissions'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelPermissions />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'configuration'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelConfiguration />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'jobs'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelJobs />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'jobs/:jobId'}
                                element={
                                    <ProtectedRoute permission={'read model'}>
                                        <ModelJobsLogs />
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route
                            path={ROUTER_PATHS.modelSelect}
                            element={
                                <ProtectedRoute permission={'create model'}>
                                    <ModelTypeSelection />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.workspaceEdit}
                            element={
                                <ProtectedRoute permission={'update model'}>
                                    <ModelEdit />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.create}
                            element={
                                <ProtectedRoute permission={'create model'}>
                                    <ModelEdit create />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.workspace}
                            element={
                                <ProtectedRoute permission={'list model'}>
                                    <Models />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.models}
                            element={
                                <ProtectedRoute permission={'list model'}>
                                    <Registry />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.users}
                            element={
                                <ProtectedRoute permission={['List All Groups', 'List All Users']}>
                                    <Users />
                                </ProtectedRoute>
                            }
                        />
                        <Route path={ROUTER_PATHS.users}>
                            <Route
                                path={':id'}
                                element={
                                    <ProtectedRoute permission={['List All Groups', 'List All Users']}>
                                        <UserDetails />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={'profile'}
                                element={
                                    <ProtectedRoute>
                                        <UserDetails />
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route
                            path={ROUTER_PATHS.groups}
                            element={
                                <ProtectedRoute permission={['List All Groups', 'List All Users']}>
                                    <Groups />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ROUTER_PATHS.group}
                            element={
                                <ProtectedRoute permission={['List All Groups', 'List All Users']}>
                                    <GroupDetails />
                                </ProtectedRoute>
                            }
                        />
                        <Route path={ROUTER_PATHS.landing} element={<Landing />} />
                        <Route path={ROUTER_PATHS.login} element={<Login />} />
                        {/* <Route path={ROUTER_PATHS.credentials} element={
              <ProtectedRoute >
                <Credentials />
              </ProtectedRoute>
            } />
            <Route path={ROUTER_PATHS.tuning} element={
              <ProtectedRoute >
                <Tuning />
              </ProtectedRoute>
            } />
            <Route path={ROUTER_PATHS.tuningCreate} element={
              <ProtectedRoute >
                <TuningCreate />
              </ProtectedRoute>
            } />
            <Route path={ROUTER_PATHS.serving} element={
              <ProtectedRoute >
                <Serving />
              </ProtectedRoute>
            } />
            <Route path={ROUTER_PATHS.servingCreate} element={
              <ProtectedRoute >
                <ServingCreate />
              </ProtectedRoute>
            } /> */}
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    )
}

export default App
