import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoints } from "../endpoints";
import { User } from "../types";
import { useAuth } from "react-oidc-context";

const useGetUser = (id: string) => {
    const auth = useAuth()

    return useQuery(
        ["users", id],
        async () => {
            let userResponse: User | null = null;
            try {
                const token = localStorage.getItem("token");
                const authtype = localStorage.getItem("authtype");
                if (token && authtype) {
                    const options = {
                        headers: {
                            authtype,
                            Authorization: `Bearer ${token}`,
                            "X-AIS-Tenant-Id": auth.user?.profile.tenant_id ?? ""
                        }
                    };
                    const response = await axios.get<User>(`${endpoints.users}/${id}`, options);
                    userResponse = response.data;
                }
            } catch (e) {
            } finally {
                return userResponse;
            }
        },
        {
            enabled: !(id === undefined) && id.length > 0,
            staleTime: 1000 * 60 * 60 * 24, // 24 hours in client cache
        }
    );
};

export default useGetUser;
