import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { UsageMetric } from "../types";
import { useAuth } from "react-oidc-context";

const useModelMetrics = (id: string, params: { interval: string } ) => {
    const auth = useAuth()

    return useQuery(["usageMetrics", id, { interval: params.interval }], async () => {
        let metrics: UsageMetric[] = [];
        try {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                        "X-AIS-Tenant-Id": auth.user?.profile.tenant_id ?? ""
                    }
                };
                let interval = params.interval;
                let modelid = id;

                let parameters = interval;
                parameters += (modelid == null) ? "" : "&entityId=" + modelid;
                const res = await axios.get(`/api/v1/usage-metrics?period=` + parameters, options);
                metrics = res.data.data.plotXYData;
            }
        } catch {
        } finally {
            return metrics;
        }
    })
}

export default useModelMetrics
