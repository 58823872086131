import React from "react"
import { RenderingProps } from "./RenderingSteps"
import { toast } from "sonner"

const DashboardReplacementRenderingParams: React.FC<RenderingProps> = ({ _model, stepIndex, onSaveChanges, onCancel }) => {

    const [injectionType, setInjectionType] = React.useState<'replace' | 'inject'>('replace')
    const [params, setParams] = React.useState({
        whereReplace: '',
        anchorSelector: 'body',
        canToggle: false,
    })

    React.useEffect(() => {
        if(stepIndex > -1 && _model.config.params.renderingSteps) {
            setParams(_model.config.params.renderingSteps[stepIndex].params)
        }
    }, [stepIndex])

    React.useEffect(() => {
        if(params.whereReplace && params.whereReplace.length > 0) {
            setInjectionType('inject')
        }
    }, [params])

    if (!_model) {
        return null
    }

    const onInjectionType = (type: 'replace' | 'inject') => {
        setInjectionType(type)
    }

    const handleFieldsOnChange = (event: any) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const validateForm = () => {
        if(injectionType === 'inject') {
            if(!params.anchorSelector || params.anchorSelector === '') {
                return false
            }
        }
        if(injectionType === 'replace') {
            if(!params.whereReplace || params.whereReplace === '') {
                return false
            }
        }
        return true
    }

    const handleChecks = (event: any) => {
        setParams({ ...params, [event.target.name]: event.target.checked })
    };

    const onSubmit = () => {
        if(!validateForm()) {
            toast.warning('Required Fields', {
                description: `Please fill out at least either an injection or replacement config`
            })
            return
        }
        let _params: any = { ...params }
        if(injectionType === 'inject') {
            _params.whereReplace = null
        }
        if(injectionType === 'replace') {
            _params.anchorSelector = null
        }
        onSaveChanges(_params)
    }

    return (
        <div className="flex flex-col gap-4">
            <p className="font-bold text-ai-700">Dashboard Replacement Rendering</p>
            <div className="flex gap-5">
                <div className="relative flex items-center">
                    <input
                        id={"simple-injection"}
                        className="radio-parent peer"
                        type="radio"
                        checked={injectionType === "inject"}
                        onChange={onInjectionType.bind(this, "inject")}
                    />
                    <div className="radio" />
                    <label htmlFor="" className="truncate pl-3">
                        Simple Injection
                    </label>
                </div>
                <div className="relative flex items-center">
                    <input
                        id={"container-replacement"}
                        className="radio-parent peer"
                        type="radio"
                        checked={injectionType === "replace"}
                        onChange={onInjectionType.bind(this, "replace")}
                    />
                    <div className="radio" />
                    <label htmlFor="" className="truncate pl-3">
                        Container Replacement
                    </label>
                </div>
            </div>
            {injectionType === "replace" && (
                <div>
                    <p className="text-ai text-[13px]">
                        Container query selector
                    </p>
                    <input
                        id={"container-query-selector"}
                        className="input w-full"
                        type="text"
                        placeholder={`[data-id="tabpanel-SUMMARY_TAB"]`}
                        name="whereReplace"
                        value={params.whereReplace ?? ""}
                        onChange={handleFieldsOnChange}
                    />
                    <p className="text-ai-800 text-[13px]">
                        Use query selectors to determine the container to be replaced
                    </p>
                </div>
            )}
            {injectionType === "inject" && (
                <div>
                    <p className="text-ai text-[13px]">
                        Injection query selector
                    </p>
                    <input
                        id="injection-query-selector"
                        className="input w-full"
                        type="text"
                        placeholder={`[data-id="tabpanel-general"]`}
                        name="anchorSelector"
                        value={params.anchorSelector ?? ""}
                        onChange={handleFieldsOnChange}
                    />
                    <p className="text-ai-800 text-[13px]">
                        Use query selectors to determine where to inject the dashboard
                    </p>
                </div>
            )}
            {/* <div className="flex gap-4">
                <div className="flex items-center text-ai">
                    <span className='mr-2'>
                        <Checkbox name="canToggle" onChange={handleChecks} checked={params.canToggle} />
                    </span>
                    <p className="text-ai-800">Togglable step</p>
                </div>
            </div> */}
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button id="save-changes-button" className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button id="cancel-button" className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    )
}

export default DashboardReplacementRenderingParams
