import React from "react";
import GroupCards from "./GroupCard";

const GroupItems: React.FC<{}> = () => {
    return (
        <div className="relative grid h-full grid-rows-[auto_1fr] gap-4 p-6">
            <GroupCards />
        </div>
    );
};

export default GroupItems;
