import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { AIRModel } from '../../features/types';
import { swapItems } from '../../Utils/utils';
import { Remove } from '@mui/icons-material';

interface StepActionsProp {
    _model: AIRModel
    step: any,
    firstStep: boolean,
    lastStep: boolean,
    index: number,
    onConfigChange: (config: any) => void
}

const StepActions: React.FC<StepActionsProp> = ({ _model, index, firstStep, lastStep, onConfigChange }) => {

    if(!_model || _model.config.params.preprocessingSteps === null) {
        return null
    }

    const onMoveUp = () => {
        onSwapStep(index, index - 1)
    }

    const onMoveDown = () => {
        onSwapStep(index, index + 1)
    }

    const onSwapStep = (a: number, b: number) => {
        let m = { ..._model }
        if(m.config.params.preprocessingSteps) {
            let steps = swapItems([ ...m.config.params.preprocessingSteps[0].steps ], a, b)
            m.config.params.preprocessingSteps[0].steps = steps
            onConfigChange(m.config)
        }
    }

    const onRemoveStep = (e: any) => {
        let m = { ..._model }
        if(m.config.params.preprocessingSteps) { 
            m.config.params.preprocessingSteps[0].steps.splice(index, 1)
            if(m.config.params.preprocessingSteps[0].steps.length === 0 ) {
                m.config.params.preprocessingSteps = null
            }
            onConfigChange(m.config)
        }
    }

    return (
        <div className='flex'>
            { !firstStep && 
                <button className='btn-icon' onClick={onMoveUp}>
                    <ArrowUpwardIcon fontSize='small'/>
                </button>
            }
            { !lastStep &&
                <button className='btn-icon' onClick={onMoveDown}>
                    <ArrowDownwardIcon fontSize='small'/>
                </button>
            }
            <button className='btn-icon' onClick={onRemoveStep}>
                <Remove fontSize='small' color='error'/>
            </button>
        </div>
    )
}

export default StepActions

