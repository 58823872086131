import React from "react";
import { ROUTER_PATHS } from "../../Utils/constants";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import UserFilterBar from "./UserFilterBar";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import { AddCircleOutline } from "@mui/icons-material";
import PersonOffTwoToneIcon from "@mui/icons-material/PersonOffTwoTone";
import useGetAllUsers from "../../features/queries/useGetAllUsers";
import useGetRoles from "../../features/queries/useGetRoles";
import { useGetGroups } from "../../features/queries";
import Dropdown from "../../Components/Dropdown";

interface UserListProps {}

const pageSizeOpts = ["10", "25", "50"]
let searchTimeout: any = null
const UserList: React.FC<UserListProps> = () => {

    const [pageSize, setPageSize] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(0)
    const [searchTerm, setSearchTerm] = React.useState("");
    const [roleId, setRoleId] = React.useState("");
    const [groupId, setGroupId] = React.useState("");
    const [sortColumn, setSortColumn] = React.useState("givenName");
    const [sortDirection, setSortDirection] = React.useState("ASC");
    const rolesQuery = useGetRoles()
    const groupQuery = useGetGroups({ pageSize: 0, page: 0, searchTerm: "", sortColumn: "", sortDirection: "" })
    const usersQuery = useGetAllUsers({ pageSize, page: currentPage, searchTerm, roleId, groupId, sortColumn, sortDirection });

    const handleFilterByRole = (e: any) => {
        if(rolesQuery.data) {
            setRoleId(rolesQuery.data.content[e.target.value].id);
        }
    };

    const handleFilterByGroup = (e: any) => {
        if(groupQuery.data) {
            setGroupId(groupQuery.data.content[(e.target.value)].id);
        }
    };

    const handleSearchInput = (e: any) => {
        if(searchTimeout) {
            clearTimeout(searchTimeout)
        }
        searchTimeout = setTimeout(() => {
            setSearchTerm(e.target.value);
        }, 500)
    };

    const handleSortColumn = (e: any) => {
        setSortColumn(e.target.value)
    }

    const handleSortDirection = (e: any) => {
        setSortDirection(e.target.value)
    }

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClose = () => {
        setIsModalOpen(false);
    };

    const navigate = useNavigate();
    const onSelectedTab = (path: string) => {
        navigate(path);
    };

    const [checkAll, setCheckAll] = React.useState(false);
    const [userChecked, setUserChecked] = React.useState<number[]>([]);
    const handleAllCheckBoxs = () => {
        setCheckAll(!checkAll);
        setUserChecked([]);
    };

    const handleUserCheckboxs = (index: number) => {
        let checked = [...userChecked];
        const validate = userChecked.indexOf(index);

        if (validate > -1) {
            checked.splice(validate, 1);
            setUserChecked(checked);
        } else {
            setUserChecked([...checked, index]);
        }
    };

    const isUserChecked = (index: number): boolean => {
        const validate = userChecked.indexOf(index);
        return checkAll || validate > -1;
    };

    const CheckBoxAll = () => (
        <div className="checkbox relative flex items-center justify-center">
            <input type="checkbox" checked={checkAll} onChange={handleAllCheckBoxs} className="peer/checkbox z-10 cursor-pointer opacity-0" />
            <div className="absolute flex h-4 w-4 items-center justify-center rounded-[4px] border-2 border-ai peer-checked/checkbox:bg-ai">
                <span className="text-xs text-white">&#10003;</span>
            </div>
        </div>
    );

    const CheckBoxUser = (props: { index: number }) => (
        <div className="checkbox relative flex items-center justify-center">
            <input
                type="checkbox"
                id={`userCheckbox-${props.index}`}
                checked={isUserChecked(props.index)}
                onChange={handleUserCheckboxs.bind(this, props.index)}
                className="peer/checkbox z-10 cursor-pointer opacity-0"
            />
            <div className="absolute flex h-4 w-4 items-center justify-center rounded-[4px] border-2 border-ai peer-checked/checkbox:bg-ai">
                <span className="text-xs text-white">&#10003;</span>
            </div>
        </div>
    );

    const UserAvatarAndName = (props: { user: any }) => (
        <div className="flex cursor-pointer items-center gap-4 truncate underline-offset-4 [&>span]:hover:underline">
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-ai text-[0.6rem] uppercase text-white">
                {props.user.givenName && props.user.givenName.charAt(0)}
                {props.user.familyName && props.user.familyName.charAt(0)}
            </div>
            <span className="truncate w-1/2">
                {props.user.givenName} {props.user.familyName}
            </span>
        </div>
    );

    const AddUserBtn = () => (
        <button type="submit" className="btn-primary flex h-[42px] gap-2  ">
            <AddCircleOutline className="" />
            <span className="inline-block truncate" title="Add User">
                Add User
            </span>
        </button>
    );

    const NoUserToDisplay = () => (
        <>
            <div className="flex h-56 w-full flex-col items-center justify-center gap-2 text-ai-800">
                <h1 className="text-base font-bold">There are no users to display</h1>
                <PersonOffTwoToneIcon fontSize="large" />
                {/* Bellow this line is disabled until inplementation */}
                {/* <p className="">Click the button below to add users to your account</p>
                <AddUserBtn /> */}
            </div>
        </>
    );

    const LoadingUsers = () => (
        <>
            <div className="flex h-56 w-full flex-col items-center justify-center gap-2 text-ai-800">
                <h1 className="text-base font-bold">Fetching list of users</h1>
                <div className="flex items-center justify-center">
                    <div className="mt-2 flex h-10 w-10 animate-spin items-center justify-center rounded-full bg-gradient-to-tr from-ai-800 via-ai-500 to-ai-300">
                        <div className="h-8 w-8 rounded-full bg-ai-100"></div>
                    </div>
                </div>
            </div>
        </>
    );

    const handlePageSizeChange = (e: any) => {
        const opt = e.target.value as number
        setPageSize(Number(pageSizeOpts[opt]));
    };

    const pages = Array.from(Array(usersQuery.data?.totalPages ?? 0).keys());

    return (
        <div className="grid h-full grid-rows-[1fr_auto] gap-3 text-sm p-6">
            <div className="flex flex-col gap-3">
                <UserFilterBar handleInputChange={handleSearchInput} handleFilterByRole={handleFilterByRole} handleFilterByGroup={handleFilterByGroup} handleSortColumn={handleSortColumn} handleSortDirection={handleSortDirection}/>
                {/* Table Selection Button  */}
                <div className="flex w-full justify-end gap-4">
                    {/* <button className="rounded-md bg-white py-2  px-4 text-sm text-ai hover:bg-ai hover:text-white" onClick={() => setIsModalOpen(!isModalOpen)}>
                    Delete Selected
                </button> */}
                    {/* <button className="rounded-md bg-white py-2  px-4 text-sm text-ai hover:bg-ai hover:text-white">Add to Group</button> */}
                    {usersQuery.isLoading ? <>-- of --</> : <p>{`${usersQuery.data?.numberOfElements ?? 0} of ${usersQuery.data?.totalElements ?? 0}`}</p>}
                </div>

                {/* Table */}
                <div className=" bg-ai-100 p-2 text-ai">
                    {usersQuery.data && usersQuery.data.content.length > 0 ? (
                        <table className="w-full table-auto border border-ai-200 text-left ">
                            <thead className="bg-white ">
                                <tr className="capitalize [&>*]:px-2 [&>*]:py-[6px]">
                                    {/* <th className="w-10">
                                        <CheckBoxAll />
                                    </th> */}
                                    <th>
                                        <span className="">Name</span>
                                    </th>
                                    <th>
                                        <span className="">Email</span>
                                    </th>
                                    <th>
                                        <span className="">Role</span>
                                    </th>
                                    <th className="hidden">
                                        <span className=""></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-ai-200">
                                {usersQuery.data.content.map((user, index) => (
                                    <tr key={user.id} className="border [&>*]:truncate [&>td]:bg-ai-50 [&>td]:p-2 [&>td]:text-ai-800 [&>td]:w-auto">
                                        {/* <td className="w-10">
                                            <CheckBoxUser index={index} />
                                        </td> */}
                                        <td>
                                            <div className="cursor-pointer underline-offset-4 [&>*]:hover:underline " onClick={onSelectedTab.bind(this, ROUTER_PATHS.users + `/${user.id}`)}>
                                                <UserAvatarAndName user={user} />
                                            </div>
                                        </td>
                                        <td><p className="truncate w-3/4">{user.email}</p></td>
                                        <td>{user.roleName}</td>
                                        <td className="hidden w-20">
                                            <div className="flex justify-end gap-4  px-4 text-ai transition duration-300 ease-in-out">
                                                {/* this button bellow will be disable for now */}
                                                {/* <div>
                                                <CreateOutlinedIcon className="hover:scale-110 cursor-pointer" fontSize="small" />
                                            </div> */}

                                                <div>
                                                    <DeleteOutlinedIcon className="cursor-pointer hover:scale-110" fontSize="small" onClick={() => setIsModalOpen(!isModalOpen)} />

                                                    <Modal isOpen={isModalOpen} onClose={handleClose}>
                                                        <>
                                                            <h3 className="text-lg font-bold text-ai-800">Do you really want to delete this profile?</h3>
                                                            <p className=" text-base text-ai-800">You cannot undo this action.</p>
                                                            <div className="mt-6 flex w-full justify-between gap-4 text-base ">
                                                                <button
                                                                    className="w-full transform rounded-md bg-ai p-2 px-4 font-bold text-white transition duration-500 hover:bg-ai-600"
                                                                    onClick={handleClose}>
                                                                    No, cancel
                                                                </button>
                                                                <button className="w-full transform rounded-md p-2 px-4 text-ai transition duration-500 hover:bg-ai-600 hover:text-white">
                                                                    Yes, delete
                                                                </button>
                                                            </div>
                                                        </>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <> {usersQuery.isLoading ? <LoadingUsers /> : <NoUserToDisplay />} </>
                    )}
                </div>
            </div>

            {/* Pagination  & Qty Button */}
            <div className="flex max-h-12 w-full">
                <div className="relative left-12 mx-auto flex flex-1 items-center justify-center gap-2">
                    {pages.map((page) => {
                        return (
                            <button
                                key={"users-page-" + page + 1}
                                onClick={() => {
                                    setCurrentPage(page);
                                }}
                                className={`h-10 w-10  rounded-md ${currentPage === page ? "bg-ai text-white" : "bg-white hover:bg-ai hover:text-white"}`}>
                                {page + 1}
                            </button>
                        );
                    })}
                </div>
                <div className="relative flex items-center justify-center gap-2">
                    <p>Show</p>
                    <Dropdown selectedIndex={["10", "25", "50"].indexOf(pageSize.toString())} handleDropdownChange={handlePageSizeChange} items={pageSizeOpts} direction="up" size="sm" />
                </div>
            </div>
        </div>
    );
}

export default UserList;
