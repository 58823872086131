import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {AIRModel} from "../types";

const useCloneModel = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (variables: { modelId: string, name: string, description: string }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                const response = await axios.post<{data: AIRModel}>(`/api/v1/models//${variables.modelId}/copy`,
                    {
                        name: variables.name,
                        description: variables.description
                    }, options);
                return response.data.data;
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workspace"])
        }
    })
}

export default useCloneModel
