import React from "react"
import { FeedbackProps } from "./FeedbackSteps"
import { AddCircleOutline, Remove } from "@mui/icons-material"
import ContentviewMessage from "../ContentviewMessage"
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import Checkbox from "../Checkbox"

const SimpleFeedbackParams: React.FC<FeedbackProps> = ({ _model, index, onSaveChanges, onCancel }) => {

    const [params, setParams] = React.useState({
        bucket: '',
        fileName: '',
        period: '',
        predictionNameKey: '',
        predictionValueKey: '',
        questions: [] as string[],
        columnList: [] as string[]
    })
    const [questionInput, setQuestionInput] = React.useState('')
    const [column, setColumn] = React.useState('')
    const [scheduled, setScheduled] = React.useState(false)

    React.useEffect(() => {
        if (_model && _model.config?.params?.feedbackSteps && index > -1) {
            setParams({ ..._model.config?.params?.feedbackSteps[index].params })
        }
    }, [])

    const onColumnFieldChange = (e: any) => {
        setColumn(e.target.value)
    }

    const onAddColumn = () => {
        if (column !== '') {
            let _p = {...params}
            let columns = column.split(',');
            if(!_p.columnList) {
                _p.columnList = []
            }
            setParams({..._p,
                columnList: [..._p.columnList, ...columns]
            })
            setColumn('')
        }
    }

    const onRemoveColumn = (index: number) => {
        let _c = [...params.columnList]
        _c.splice(index, 1)
        setParams({
            ...params,
            columnList: _c
        })
    }

    const handleFieldsOnChange = (event: any) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }

    const questionInputChange = (e: any) => {
        setQuestionInput(e.target.value)
    }

    const onAddQuestion = () => {
        let _q = [...params.questions] ?? []
        if (questionInput !== '') {
            setParams({
                ...params,
                questions: [
                    ..._q,
                    questionInput
                ]
            })
            setQuestionInput('')
        }
    }

    const onDeleteQuestion = (index: number) => {
        let _q = [...params.questions]
        _q.splice(index, 1)
        setParams({
            ...params,
            questions: _q
        })
    }

    const onScheduledChange = (e: any) => {
        if (!e.target.checked) {
            setParams({ ...params, period: '' })
        }
        setScheduled(!scheduled)
    }

    const handleScheduleOnChange = (value: string) => {
        setParams({ ...params, period: value })
    }

    const onSubmit = () => {
        onSaveChanges(params)
    }

    return (
        <div className="flex flex-col gap-4">
            <p className="text-ai-700 font-bold mt-4">Parameters</p>
            <div className="flex gap-2">
                <div className="flex-1">
                    <label className="text-ai px-2 text-[13px]">Bucket</label>
                    <input id="bucket" type={"text"} className={"input w-full"} onChange={handleFieldsOnChange} value={params.bucket ?? ''} name="bucket"/>
                </div>
                <div className="flex-1">
                    <label className="text-ai px-2 text-[13px]">File Name</label>
                    <input id="file-name" type={"text"} className={"input w-full"} onChange={handleFieldsOnChange} value={params.fileName ?? ''} name="fileName"/>
                </div>
            </div>
            <hr className="border-ai-200" />
            <div className="flex gap-2">
                <div className="flex-1">
                    <label className="text-ai px-2 text-[13px]">Prediction Name Key</label>
                    <input id="prediction-name-key" type={"text"} className={"input w-full"} onChange={handleFieldsOnChange} value={params.predictionNameKey ?? ''} name="predictionNameKey"/>
                </div>
                <div className="flex-1">
                    <label className="text-ai px-2 text-[13px]">Prediction Value Key</label>
                    <input id="prediction-value-key" type={"text"} className={"input w-full"} onChange={handleFieldsOnChange} value={params.predictionValueKey ?? ''} name="predictionValueKey"/>
                </div>
            </div>
            <hr className='border-ai-200' />
            <div className='flex flex-1 gap-2'>
                <Checkbox checked={scheduled} onChange={onScheduledChange} />
                <label className='block px-2 text-ai'>Schedule ETL?</label>
            </div>
            {scheduled && (
                <div className='w-full'>
                    <label className='block my-2 text-ai'>Schedule will execute at server time.</label>
                    <Cron value={params.period} setValue={handleScheduleOnChange} />
                </div>
            )}
            <hr className="border-ai-200" />
            <p className="text-ai-700 font-bold">Column List</p>
            <div className='rounded bg-ai-100 border border-ai-200 p-3'>
                <div className="flex items-center gap-2 mb-2">
                    <div className="flex-1">
                        <input
                            id={"column-input"}
                            className="input w-full"
                            type="text"
                            placeholder="LEAD_NAME"
                            value={column}
                            onChange={onColumnFieldChange}
                        />
                    </div>
                    <div>
                        <button
                            id="add-column"
                            className="btn-secondary flex items-center border border-ai p-2 text-[14px] font-bold"
                            onClick={onAddColumn}
                        >
                            <AddCircleOutline sx={{ fontSize: "16px" }} />
                            <span className="px-1">Add</span>
                        </button>
                    </div>
                </div>
                <table aria-label="urls" className="border border-ai-200">
                    <thead className="border-b border-b-ai-200 bg-white text-left">
                        <tr>
                            <th className="w-1 px-4 py-2">
                                <span className="text-[16px] font-normal text-ai-800">
                                    #
                                </span>
                            </th>
                            <th className="w-full">
                                <span className="text-[16px] font-normal text-ai-800">
                                    Columns
                                </span>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="text-left">
                        {(params.columnList === undefined || params.columnList.length === 0) && (
                            <tr>
                                <td colSpan={4}>
                                    <ContentviewMessage title="" message="Add columns for review"/>
                                </td>
                            </tr>
                        )}

                        {params.columnList &&
                        params.columnList.map((source: string, index: number) => {
                            return (
                                <tr key={source + index} className={"bg-ai-50"}>
                                    <td className="p-4 align-top">
                                        <p className=" text-ai-800">
                                            {index + 1 + "."}
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-ai-800">
                                            {source}
                                        </p>
                                    </td>
                                    <td>
                                        <button className="btn-icon" onClick={() => onRemoveColumn(index) }>
                                            <Remove color="error" />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <hr className="border-ai-200"/>
            <p className="text-ai-700 font-bold">Quality Questions</p>
            <div className='rounded bg-ai-100 border border-ai-200 p-3'>
                <div className="flex items-center gap-2 mb-2">
                    <div className="flex-1">
                        <input
                            id={"question-input"}
                            className="input w-full"
                            type="text"
                            placeholder="Was this data of value?"
                            value={questionInput}
                            onChange={questionInputChange}
                        />
                    </div>
                    <div>
                        <button
                            id="add-question"
                            className="btn-secondary flex items-center border border-ai p-2 text-[14px] font-bold"
                            onClick={onAddQuestion}
                        >
                            <AddCircleOutline sx={{ fontSize: "16px" }} />
                            <span className="px-1">Add</span>
                        </button>
                    </div>
                </div>
                <table aria-label="urls" className="border border-ai-200">
                    <thead className="border-b border-b-ai-200 bg-white text-left">
                        <tr>
                            <th className="w-1 px-4 py-2">
                                <span className="text-[16px] font-normal text-ai-800">
                                    #
                                </span>
                            </th>
                            <th className="w-full">
                                <span className="text-[16px] font-normal text-ai-800">
                                    Questions
                                </span>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="text-left">
                        {(params.questions === undefined || params.questions.length === 0) && (
                            <tr>
                                <td colSpan={4}>
                                    <ContentviewMessage title="" message="Add questions for review"/>
                                </td>
                            </tr>
                        )}

                        {params.questions &&
                        params.questions.map((source: string, index: number) => {
                            return (
                                <tr key={source + index} className={"bg-ai-50"}>
                                    <td className="p-4 align-top">
                                        <p className=" text-ai-800">
                                            {index + 1 + "."}
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-ai-800">
                                            {source}
                                        </p>
                                    </td>
                                    <td>
                                        <button className="btn-icon" onClick={() => onDeleteQuestion(index) }>
                                            <Remove color="error" />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button id="save-changes-button" className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button id="cancel-button" className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    )
}

export default SimpleFeedbackParams
