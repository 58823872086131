import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useLayout } from "../../Store/Hooks";

const useAnalyticPreview = () => {
    const {setAnalyticPreview} = useLayout()
    return useMutation({
        mutationFn: async (variables: { modelId?: string, step: any, inputExample?: string }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                const response = await axios.post<{data: any}>(`/reverse-ml/v1/job/preview/`,
                    {
                        modelId: variables.modelId,
                        analytic: variables.step,
                        inputExample: variables.inputExample
                    }, options);
                return response.data.data;
            }
        },
        onSuccess: (data) => {
            setAnalyticPreview(data.preview)
        },
        onError() {
            setAnalyticPreview(null)
        },
    })
}

export default useAnalyticPreview
