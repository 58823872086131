/* global chrome */
import React from 'react'
import { Box, Button, Menu, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useGetCurrentUser } from '../features/queries'
import { useNavigate } from 'react-router-dom'
import { ROUTER_PATHS } from '../Utils/constants'
import { useAuth } from 'react-oidc-context'

const ProfileOptions: React.FC<{}> = () => {
    const auth = useAuth()
    const navigate = useNavigate()
    const userQuery = useGetCurrentUser()
    const user = userQuery.data?.data

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    if (!user) {
        return null
    }

    const onOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        if(window.chrome && window.chrome.runtime) {
            const port = chrome.runtime.connect('fkeikkmmcndnfbpehjjjafnbfhbopnoa')
            port.postMessage({ action: 'LOGOUT_USER' })
            port.disconnect()
        }
        auth.signoutRedirect()
    }

    const handleProfile = () => {
        navigate(ROUTER_PATHS.users + `/profile`)
    }

    return (
        <Box>
            <Button id='profile' variant='text' sx={{ color: 'white' }} endIcon={<KeyboardArrowDown />} onClick={onOptions}>
                <Box
                    id='profile_name'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box marginRight='16px'>
                        <Avatar sizes='small' sx={{ width: 34, height: 34, bgcolor: 'primary.main' }}>
                            <Typography variant='subtitle1' color={'white'}>
                                {user.givenName ? user.givenName[0] : ""}
                                {user.familyName ? user.familyName[0] : ""}
                            </Typography>
                        </Avatar>
                    </Box>
                    {user.givenName + ' ' + user.familyName}
                </Box>
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleProfile}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </Box>
    )
}

export default ProfileOptions
