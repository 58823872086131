import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {AIRModel, ModelConfigRoot} from "../types";
import { toast } from "sonner";

const useConfigUpdate = () => {

    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (variables: { upload?: boolean, modelId: string, config: ModelConfigRoot }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                if (variables.upload) {
                    const response = await axios.put<{ data: AIRModel }>(`/upload/v1/models/${variables.modelId}`, { config: variables.config }, options);
                    return response.data.data;
                } else {
                    const response = await axios.patch<{ data: AIRModel }>(`/api/v1/models/${variables.modelId}`, { config: variables.config }, options);
                    return response.data.data;
                }
            }
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['workspace'])
            queryClient.invalidateQueries(['models', variables.modelId])
            toast.success("Model Updated", {
                description: 'Model has been updated successfully.'
            })
        },
        onError(error: any, variables, context) {
            if(error.response.data && error.response.data.error) {
                toast.error('Failed to Update', {
                    description: error.response.data.error
                })
            }
        },
    })
}

export default useConfigUpdate
