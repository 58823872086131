import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import ModelDash from './ModelDash'
import ModelDashBinning from './ModelDashBinning'
import { useParams } from 'react-router-dom'
import { useGetModel, useModelFeedback } from '../../features/queries'

const ModelInsights: React.FC<{}> = () => {
    const { id } = useParams()
    const modelQuery = useGetModel(id)
    const modelFeedbackQuery = useModelFeedback(id ?? '')

    const model = modelQuery.data
    const modelFeedbackPlotCount = modelFeedbackQuery.data

    if (!model) {
        return null
    }

    return (
        <ModelDash>
            <div className='flex h-full w-full flex-col gap-4'>
                <div className='overflow-hidden rounded-md bg-ai-50'>
                    <div className='flex items-center bg-ai-200 p-4 font-bold'>Usage Metrics</div>
                    <div className='p-4 border-b border-[#DADAE7]'>
                        <ModelDashBinning model={model} />
                    </div>
                </div>
                {modelFeedbackPlotCount?.map((plot: any, index: number) => {
                    return (
                        <div className='overflow-hidden rounded-md bg-ai-50 w-1/2' key={plot.question + index}>
                            <div className='flex items-center bg-ai-200 p-4 font-bold'>{plot.question}</div>
                            <div className='h-60 p-4'>
                                <ResponsiveContainer>
                                    <BarChart data={plot.answers}>
                                        <CartesianGrid strokeDasharray='3 3' />
                                        <XAxis dataKey='option' />
                                        <YAxis dataKey='count' />
                                        <Tooltip />
                                        <Bar type='monotone' dataKey='count' fill='#82ca9d' />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )
                })}
            </div>
        </ModelDash>
    )
}

export default ModelInsights
