import { configureStore } from '@reduxjs/toolkit'
import {
  layoutReducer,
} from './Slices'

export default configureStore({
  reducer: {
    layout: layoutReducer,
  },
})
