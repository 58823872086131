import React from 'react'
import { Navigate } from 'react-router-dom'
import { useGetCurrentUser } from '../features/queries'
import { useAuth } from 'react-oidc-context'

interface ProtectedRouteProps {
    children: React.ReactNode
    permission: string | string[]
}

const ProtectedAction: React.FC<ProtectedRouteProps> = ({ children, permission }) => {
    const auth = useAuth()
    const verifyQuery = useGetCurrentUser()

    if (verifyQuery.isLoading) {
        return null
    }

    if (!auth.isAuthenticated) {
        auth.signinRedirect();
    }

    if (auth.isAuthenticated && verifyQuery.isFetched && !verifyQuery.isLoading && !verifyQuery.data?.data) {
        // We were able to authenticate but failed to fetch user from /user/me
        return null
    }

    if (verifyQuery.data?.data) {
        if (
            !verifyQuery.data.data.permissions.some(p => {
                if (Array.isArray(permission)) {
                    return permission.findIndex(myPermission => myPermission.toLowerCase() === p.toLowerCase()) > -1
                } else {
                    return p.toLowerCase() === permission.toLowerCase()
                }
            })
        ) {
            return null
        }
    }

    return <React.Fragment>{children}</React.Fragment>
}

export default ProtectedAction
