import React from 'react'
import StepProps from '../../features/types'
import Dropdown from '../Dropdown'

const locationOpts = ["pre", "post"]

const PadSequencesStep: React.FC<StepProps> = ({ _model, json, step, editing, index, onConfigChange }) => {

    const [params, setParams] = React.useState({
        padCharacter: 0,
        length: 0,
        padLocation: 'post',
        truncateLocation: 'post'
    })

    React.useEffect(() => {
        if (editing) {
            setParams({
                padCharacter: step.params.padCharacter,
                length: step.params.length,
                padLocation: step.params.padLocation,
                truncateLocation: step.params.truncateLocation,
            })
        }
    }, [step])

    if (!_model) {
        return null
    }

    const onAddStep = (e: any) => {
        let _config = { ...json }
        if (_model) {
            const step = {
                className: 'PadSequences',
                params
            }
            if (editing && index !== null && index !== undefined && _config.params.preprocessingSteps) {
                _config.params.preprocessingSteps[0].steps[index] = { ...step }
            } else {
                if (!_config.params.preprocessingSteps || _config.params.preprocessingSteps.length === 0) {
                    _config.params.preprocessingSteps = []
                    _config.params.preprocessingSteps.push({
                        className: 'TextPreprocessor',
                        steps: []
                    })
                }
                _config.params.preprocessingSteps[0].steps.push(step)
            }
            onConfigChange(_config)
        }
    }

    const onParamsChange = (e: any) => {
        setParams({
            ...params,
            [e.target.name]: e.target.value
        })
    }

    const onDropdownChange = (key: string, e: any) => {
        const selectedIndex = Number(e.target.value)
        setParams({
            ...params,
            [key]: locationOpts[selectedIndex]
        })
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='text-ai-700 font-bold'>Pad Sequences</p>
            <div className='flex gap-2'>
                <div className='flex-1'>
                    <label className='block px-2 text-ai text-[13px]'>Pad character</label>
                    <input type={"text"} className="input w-full" name="padCharacter" value={params.padCharacter} placeholder="1" onChange={onParamsChange}/>
                </div>
                <div className='flex-1'>
                    <label className='block px-2 text-ai text-[13px]'>Lenght</label>
                    <input type={"text"} className="input w-full" name="length" value={params.length} placeholder="2" onChange={onParamsChange}/>
                </div>
            </div>
            <div className='flex gap-2'>
                <div className='flex-1'>
                    <label className='block px-2 text-ai text-[13px]'>Select pad location</label>
                    <Dropdown items={locationOpts} selectedIndex={locationOpts.indexOf(params.padLocation)} handleDropdownChange={(e) => onDropdownChange("padLocation", e)}/>
                </div>
                <div className='flex-1'>
                    <label className='block px-2 text-ai text-[13px]'>Select truncate location</label>
                    <Dropdown items={locationOpts} selectedIndex={locationOpts.indexOf(params.truncateLocation)} handleDropdownChange={(e) => onDropdownChange("truncateLocation", e)}/>
                </div>
            </div>
            <hr className='border-ai-200'/>
            <div>
                <button color="primary" className='btn-primary' onClick={onAddStep}> {editing ? 'Edit Step' : 'Add Step'} </button>
            </div>
        </div>
    )
}

export default PadSequencesStep

