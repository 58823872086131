import React from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import WorkspaceFilters from "./WorkspaceFilters";

const Models: React.FC<{}> = () => {

	return (
		<DashboardLayout title="Workspace">
			<WorkspaceFilters />
		</DashboardLayout>
	);
};

export default Models;
