import { useQuery } from "@tanstack/react-query"
import { AIRModel, RegistryQueryParams } from "../types"
import Qs from 'qs'
import axios from "axios"
import { endpoints } from "../endpoints"
import { useAuth } from "react-oidc-context"

const useRegistry = (params: RegistryQueryParams) => {
    const auth = useAuth()
    const token = localStorage.getItem("token");
    const authtype = localStorage.getItem("authtype");

    return useQuery(["registry", token, {...params}], async () => {
        if (token && authtype) {
            const options = {
                headers: {
                    authtype,
                    Authorization: `Bearer ${token}`,
                    "X-AIS-Tenant-Id": auth.user?.profile.tenant_id ?? ""
                },
                params,
                paramsSerializer: (params: any) => {
                    return Qs.stringify(params)
                }
            };
            const response = await axios.get<{ data: { models: AIRModel[] } }>(endpoints.models, options);
            return response.data.data.models
        }
    }, {
        initialData: [],
        keepPreviousData: true
    })
}

export default useRegistry
