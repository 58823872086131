import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoints } from "../endpoints";
import { Group } from "../types";
import { useAuth } from "react-oidc-context"; 

const useGetGroup = (id: string) => {
    const auth = useAuth()

    return useQuery(
        ["group", id],
        async () => {
            let groupResponse: Group | null = null;
            try {
                const token = localStorage.getItem("token");
                const authtype = localStorage.getItem("authtype");
                if (token && authtype) {
                    const options = {
                        headers: {
                            authtype,
                            Authorization: `Bearer ${token}`,
                            "X-AIS-Tenant-Id": auth.user?.profile.tenant_id ?? ""
                        }
                    };
                    const response = await axios.get<Group>(`${endpoints.groups}/${id}`, options);
                    groupResponse = response.data;
                }
            } catch (e) {
            } finally {
                return groupResponse;
            }
        },
        {
            enabled: !(id === undefined) && id.length > 0,
            staleTime: 1000 * 60 * 60 * 24, // 24 hours in client cache
        }
    );
};

export default useGetGroup;
