import React from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

type VariableFormProps = {
    variable: {
        name: string
        type: string
        value: string
    }
    index: number
    onChangeVariable: (
        index: number,
        updatedVariable: {
            name: string
            type: string
            value: string
        },
    ) => void
    onRemoveVariable: (index: number) => void
}

const VariableForm: React.FC<VariableFormProps> = ({
    variable,
    index,
    onChangeVariable,
    onRemoveVariable,
}) => {

    const handleVariableChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = event.target
        onChangeVariable(index, { ...variable, [name]: value })
    }

    return (
        <div className='flex gap-2'>
            <div className='flex-1'>
                <input
                    className='input w-full'
                    name='name'
                    placeholder='Variable Name'
                    value={variable.name}
                    onChange={handleVariableChange}
                />
            </div>
            <div className='flex-1'>
                <input
                    className='input w-full'
                    name='value'
                    placeholder='Variable Value'
                    value={variable.value}
                    onChange={handleVariableChange}
                />
            </div>
            <div className='flex-1'>
                <select
                    className='input w-full'
                    name='type'
                    value={variable.type}
                    onChange={handleVariableChange}
                >
                    <option value='string'>String</option>
                    <option value='number'>Number</option>
                    <option value='boolean'>Boolean</option>
                    <option value='array'>Array</option>
                </select>
            </div>
            <button type='button' onClick={() => onRemoveVariable(index)}>
                <DeleteOutlineIcon color='error' />
            </button>
        </div>
    )
}

export default VariableForm
