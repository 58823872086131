import { InfoOutlined, ThumbDown, ThumbUp } from '@mui/icons-material'
import React from 'react'
import ModalViewLayout from '../../Layouts/ModalViewLayout'
import { JSONTree } from 'react-json-tree'
import { JSONTreeTheme } from '../../Utils/constants'
import { AIRModel } from '../../features/types'
import { usePredictionFeedback } from '../../features/queries'
import { TwoSeventyRing } from 'react-svg-spinners'

const ModelDashPredictionsFeedback: React.FC<{ model: AIRModel }> = ({ model }) => {
    const predictionFeedbackQuery = usePredictionFeedback(model.id)
    const [currentPred, setCurrentPred] = React.useState<any>(null)
    const [params, setParams] = React.useState<any>(null)

    const predictionFeedbackPlotCount = predictionFeedbackQuery.data

    React.useEffect(() => {
        if (model && model.config.params.feedbackSteps) {
            if (model.config.params.feedbackSteps && model.config.params.feedbackSteps.length > 0) {
                for (const step in model.config.params.feedbackSteps) {
                    if (model.config.params.feedbackSteps[step].className === 'SimpleFeedback') {
                        setParams(model.config.params.feedbackSteps[step].params)
                    }
                }
            }
        }
    }, [model])

    const onViewPredictionContent = (prediction: any) => {
        let data: any = {}
        for(const key in prediction) {
            data[prediction[key][0].prediction.name] = prediction[key][0].prediction.value
        }
        setCurrentPred(data)
    }

    const onPopupClose = () => {
        setCurrentPred(null)
    }

    const filterFeedbacks = (feedback: any): any[] => {
        return feedback.filter((f: any, i: number) => {
            return f.up > 0 || f.down > 0
        })
    }

    const showPredFeedback = (params: any, feedback: any): boolean => {
        if (feedback.length > 0) {
            const column = feedback[0].prediction[params.predictionNameKey]
            const columnIndex = params.columnList.indexOf(column.trim())
            if (params.columnList?.length > 0 && columnIndex === -1) {
                return false
            }
            return true
        }
        return false
    }

    if (!model || !model.config.params.feedbackSteps) {
        return null
    }

    if (!params) {
        return null
    }

    const renderPlots = () => {
        return (
            <>
                {
                    predictionFeedbackQuery.isLoading &&
                    <div className='h-full w-full flex justify-center items-center'>
                        <TwoSeventyRing color='#00249C' height={40} width={40} />
                    </div>
                }
                {
                 !predictionFeedbackQuery.isLoading && (!predictionFeedbackPlotCount || predictionFeedbackPlotCount?.length === 0) &&
                    <div className='w-full flex justify-center items-center'>
                        No Feedback Available
                    </div>
                }
                {predictionFeedbackPlotCount?.map((predFeedback, index) => {
                    const keys = Object.keys(predFeedback)
                    if (keys.length === 0) {
                        return null
                    }
                    return (
                        <div key={index} className='overflow-hidden rounded-md'>
                            <div className='flex items-center bg-ai-200 p-4 font-bold'>
                                <span className='flex-1'>{predFeedback[keys[0]][0].harvestedDataPoint.rawData && predFeedback[keys[0]][0].harvestedDataPoint.rawData.length > 0 ?
                                `Feedback for: ${predFeedback[keys[0]][0].harvestedDataPoint.rawData}` : `Feedback ${index + 1}`}
                                </span>
                                <button
                                    className='btn-icon'
                                    onClick={onViewPredictionContent.bind(this, predFeedback)}
                                >
                                    <InfoOutlined />
                                </button>
                            </div>
                            <div className='mb-4 flex flex-wrap bg-ai-50 rounded-b-md'>
                                {keys.map((k, i) => {
                                    const feedbacks = filterFeedbacks(predFeedback[k])
                                    if (feedbacks.length === 0 && i == 0) {
                                        return (
                                            <div key={k + i + index} className='flex w-full items-center justify-center p-4'>
                                                No feedback provided for this prediction.
                                            </div>
                                        )
                                    }
                                    if (showPredFeedback(params, feedbacks)) {
                                        return (
                                            <div key={k + i + index} className='w-1/2 p-4'>
                                                {feedbacks.map((feedback: any, j: number) => {
                                                    return (
                                                        <div key={feedback.harvestedDataPoint.id + j}>
                                                            {j === 0 && (
                                                                <div className='flex border-b border-ai-200 font-bold'>
                                                                    {`${feedback.prediction[params.predictionNameKey]}: ${
                                                                        feedback.prediction[params.predictionValueKey]
                                                                    }`}
                                                                </div>
                                                            )}
                                                            <div className='flex items-center border-b border-ai-200 py-1'>
                                                                <div className='flex-1'>{feedback.question}</div>
                                                                <div className='flex gap-2'>
                                                                    <div className='flex items-center rounded-md px-2 text-gray-500'>
                                                                        <span className='font-bold text-ai'>{feedback.up}</span>
                                                                        <ThumbUp sx={{ marginLeft: '8px', fontSize: 16 }} />
                                                                    </div>
                                                                    <div className='flex items-center rounded-md px-2 text-gray-500'>
                                                                        <ThumbDown sx={{ marginRight: '8px', fontSize: 15 }} />
                                                                        <span className='font-bold text-ai'>{feedback.down}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    }
                                    return null
                                })}
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    if (model == null) {
        return null
    }

    return (
        <>
            {renderPlots()}
            {currentPred && (
                <ModalViewLayout
                    open={currentPred !== null}
                    title={'Prediction Entries'}
                    onClose={onPopupClose}
                >
                    <JSONTree
                        shouldExpandNode={() => {
                            return true
                        }}
                        theme={JSONTreeTheme}
                        data={currentPred}
                    />
                </ModalViewLayout>
            )}
        </>
    )
}

export default ModelDashPredictionsFeedback
