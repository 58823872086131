import React from 'react'
import ModelDash from './ModelDash'
import { JSONTree } from 'react-json-tree'
import { JSONTreeTheme } from '../../Utils/constants'
import { useParams } from 'react-router-dom'
import { useGetModel } from '../../features/queries'

const ModelConfiguration: React.FC<{}> = () => {
    const { id } = useParams()
    const modelQuery = useGetModel(id)

    const model = modelQuery.data
    if (!model) {
        return null
    }

    return (
        <ModelDash>
            <div className='bg-ai-50 rounded-md overflow-hidden'>
                <div className='flex items-center bg-ai-200 p-4 font-bold'>
                    <div>
                        Model Configuration
                    </div>
                </div>
                <div className='p-4'>
                    <JSONTree
                        shouldExpandNode={() => {
                            return true
                        }}
                        theme={JSONTreeTheme}
                        data={model?.config}
                    />
                </div>
            </div>
        </ModelDash>
    )
}

export default ModelConfiguration
