import React from 'react'
import ContentviewMessage from '../Components/ContentviewMessage'
import { useGetCurrentUser } from '../features/queries'
import DashboardLayout from './DashboardLayout'
import { useAuth } from 'react-oidc-context'
import LogoSpinner from '../Components/LogoSpinner'

interface ProtectedRouteProps {
    children: React.ReactNode
    permission?: string | string[]
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, permission }) => {
    const auth = useAuth()
    const verifyQuery = useGetCurrentUser()

    if (verifyQuery.isLoading || auth.isLoading || !verifyQuery.isFetched) {
        return (
            <div className='flex h-full w-full flex-col items-center justify-center bg-ai-700 bg-cover bg-center bg-no-repeat'>
                <LogoSpinner />
            </div>
        )
    }

    if (!auth.isLoading && !auth.isAuthenticated) {
        auth.signinRedirect();
    }

    if (!auth.isLoading && !verifyQuery.isLoading && verifyQuery.isFetched && verifyQuery.data?.data && permission) {
        if (
            !verifyQuery.data.data.permissions.some(p => {
                if (Array.isArray(permission)) {
                    return permission.findIndex(myPermission => myPermission.toLowerCase() === p.toLowerCase()) > -1
                } else {
                    return p.toLowerCase() === permission.toLowerCase()
                }
            })
        ) {
            return (
                <DashboardLayout>
                    <ContentviewMessage title='Unauthorized' message='You do not have the required permissions to view this content.' />
                </DashboardLayout>
            )
        }
    }

    return <div className='h-full'>{children}</div>
}

export default ProtectedRoute
