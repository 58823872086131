import React, { useEffect, useState } from 'react'
import ModelDash from './ModelDash'
import { useParams } from 'react-router-dom'
import { useGetModel, useGetSharedUsers } from '../../features/queries'
import useGetSharedGroups from '../../features/queries/useGetSharedGroups'
import { useShareModel } from '../../features/mutations'
import { TwoSeventyRing } from 'react-svg-spinners'

const ModelPermissions: React.FC<{}> = () => {
    const { id } = useParams()
    const modelQuery = useGetModel(id)
    const { shareWithUser, unshareWithUser, shareWithGroup, unshareWithGroup } = useShareModel()
    const unsharedUsersQuery = useGetSharedUsers(id ?? '', { shared: false })
    const sharedUsersQuery = useGetSharedUsers(id ?? '', { shared: true })
    const unsharedGroupsQuery = useGetSharedGroups(id ?? '', { shared: false })
    const sharedGroupsQuery = useGetSharedGroups(id ?? '', { shared: true })

    // For loader spinners on each checkbox
    const [sharingWithUsers, setSharingWithUsers] = useState<string[]>([])
    const [unsharingWithUsers, setUnsharingWithUsers] = useState<string[]>([])
    const [sharingWithGroups, setSharingWithGroups] = useState<string[]>([])
    const [unsharingWithGroups, setUnsharingWithGroups] = useState<string[]>([])

    const model = modelQuery.data
    const unsharedUsers = unsharedUsersQuery.data ?? []
    const sharedUsers = sharedUsersQuery.data ?? []
    const unsharedGroups = unsharedGroupsQuery.data ?? []
    const sharedGroups = sharedGroupsQuery.data ?? []

    // Remove share with user spinner
    useEffect(() => {
        if (shareWithUser.isSuccess && shareWithUser.data) {
            let _sahringWithUsers = [...sharingWithUsers]
            const index = _sahringWithUsers.indexOf(shareWithUser.data.data.userId)
            _sahringWithUsers.splice(index)
            setSharingWithUsers(_sahringWithUsers)
        }
    }, [shareWithUser.isSuccess, shareWithUser.data])

    // Remove unshare with user spinner
    useEffect(() => {
        if (unshareWithUser.isSuccess && unshareWithUser.data) {
            let _unsharingWithUsers = [...unsharingWithUsers]
            const index = _unsharingWithUsers.indexOf(unshareWithUser.data.data.userId)
            _unsharingWithUsers.splice(index)
            setUnsharingWithUsers(_unsharingWithUsers)
        }
    }, [unshareWithUser.isSuccess, unshareWithUser.data])

    // Remove share with group spinner
    useEffect(() => {
        if (shareWithGroup.isSuccess && shareWithGroup.data) {
            let _sharingWithGroups = [...sharingWithGroups]
            const index = _sharingWithGroups.indexOf(shareWithGroup.data.data.userId)
            _sharingWithGroups.splice(index)
            setSharingWithGroups(_sharingWithGroups)
        }
    }, [shareWithGroup.isSuccess, shareWithGroup.data])

    // Remove unshare with user spinner
    useEffect(() => {
        if (unshareWithGroup.isSuccess && unshareWithGroup.data) {
            let _unsharingWithGroups = [...unsharingWithGroups]
            const index = _unsharingWithGroups.indexOf(unshareWithGroup.data.data.userId)
            _unsharingWithGroups.splice(index)
            setUnsharingWithGroups(_unsharingWithGroups)
        }
    }, [unshareWithGroup.isSuccess, unshareWithGroup.data])

    if (!model) {
        return null
    }

    const onShareWithUser = (userId: string) => {
        if (id) {
            setSharingWithUsers([...sharingWithUsers, userId])
            shareWithUser.mutate({ modelId: id, userId, params: { share: true } })
        }
    }

    const onUnshareWithUser = (userId: string) => {
        if (id) {
            setUnsharingWithUsers([...unsharingWithUsers, userId])
            unshareWithUser.mutate({ modelId: id, userId })
        }
    }

    const onShareWithGroup = (groupId: string) => {
        if (id) {
            setSharingWithGroups([...sharingWithGroups, groupId])
            shareWithGroup.mutate({ modelId: id, groupId, params: { share: true } })
        }
    }

    const onUnshareWithGroup = (groupId: string) => {
        if (id) {
            setUnsharingWithGroups([...unsharingWithGroups, groupId])
            unshareWithGroup.mutate({ modelId: id, groupId })
        }
    }

    const CheckBoxUser = (props: { index: number; userId: string; checked?: boolean }) => {
        const sharingIndex = sharingWithUsers.indexOf(props.userId)
        const unsharingIndex = unsharingWithUsers.indexOf(props.userId)
        return (
            <>
                {sharingIndex > -1 || unsharingIndex > -1 ? (
                    <TwoSeventyRing color='#00249C' height={13} width={13} />
                ) : (
                    <div className='checkbox relative flex items-center justify-center'>
                        <input
                            type='checkbox'
                            id={`userCheckbox-${props.userId + props.index}`}
                            checked={props.checked}
                            className='peer/checkbox z-10 cursor-pointer opacity-0'
                            onChange={() => {
                                if (props.checked) {
                                    onUnshareWithUser(props.userId)
                                } else {
                                    onShareWithUser(props.userId)
                                }
                            }}
                        />
                        <div className='absolute flex h-4 w-4 items-center justify-center rounded-[4px] border-2 border-ai peer-checked/checkbox:bg-ai'>
                            <span className='text-xs text-white'>&#10003;</span>
                        </div>
                    </div>
                )}
            </>
        )
    }

    const CheckBoxGroup = (props: { index: number; groupId: string; checked?: boolean }) => {
        const sharingIndex = sharingWithGroups.indexOf(props.groupId)
        const unsharingIndex = unsharingWithGroups.indexOf(props.groupId)
        return (
            <>
                {sharingIndex > -1 || unsharingIndex > -1 ? (
                    <TwoSeventyRing color='#00249C' height={13} width={13} />
                ) : (
                    <div className='checkbox relative flex items-center justify-center'>
                        <input
                            type='checkbox'
                            id={`groupCheckbox-${props.groupId + props.index}`}
                            checked={props.checked}
                            className='peer/checkbox z-10 cursor-pointer opacity-0'
                            onChange={() => {
                                if (props.checked) {
                                    onUnshareWithGroup(props.groupId)
                                } else {
                                    onShareWithGroup(props.groupId)
                                }
                            }}
                        />
                        <div className='absolute flex h-4 w-4 items-center justify-center rounded-[4px] border-2 border-ai peer-checked/checkbox:bg-ai'>
                            <span className='text-xs text-white'>&#10003;</span>
                        </div>
                    </div>
                )}
            </>
        )
    }

    return (
        <ModelDash hideVersionSelect>
            <div className='w-full mr-3 flex bg-white p-2'>
                {(unsharedUsersQuery.isLoading ||
                    sharedUsersQuery.isLoading ||
                    unsharedGroupsQuery.isLoading ||
                    sharedGroupsQuery.isLoading) && (
                    <div className='flex h-full w-full items-center justify-center'>
                        <TwoSeventyRing color='#00249C' height={40} width={40} />
                    </div>
                )}
                {(!unsharedUsersQuery.isLoading &&
                    !sharedUsersQuery.isLoading &&
                    !unsharedGroupsQuery.isLoading &&
                    !sharedGroupsQuery.isLoading) && (
                    <>
                        <div className='flex-1 bg-ai-50 p-4'>
                            <h2 className='font-bold text-ai-700'>Users</h2>
                            <hr className='my-2 h-px border-0 bg-gray-200' />
                            <p className='text-sm'>{sharedUsers.length > 0 ? 'Shared with:' : ''}</p>
                            {sharedUsers.map((user, index) => {
                                return (
                                    <div
                                        key={user.displayName}
                                        className='my-2 flex items-center rounded border border-ai-200 bg-white p-2 text-sm'
                                    >
                                        <span className='mr-2'>
                                            <CheckBoxUser checked index={index} userId={user.id} />
                                        </span>
                                        {user.displayName}
                                    </div>
                                )
                            })}
                            {/* <hr className="h-px my-2 bg-gray-200 border-0"/> */}
                            <p className='text-sm'>Select user to share:</p>
                            {unsharedUsers.map((user, index) => {
                                return (
                                    <div
                                        key={user.displayName}
                                        className='my-2 flex items-center rounded border border-ai-200 bg-white p-2 text-sm'
                                    >
                                        <span className='mr-2'>
                                            <CheckBoxUser index={index} userId={user.id} />
                                        </span>
                                        {user.displayName}
                                    </div>
                                )
                            })}
                        </div>
                        <div className='flex-1 bg-ai-100 p-4'>
                            <h2 className='font-bold text-ai-700'>Groups</h2>
                            <hr className='my-2 h-px border-0 bg-gray-300' />
                            <p className='text-sm'>{sharedGroups.length > 0 ? 'Shared with:' : ''}</p>
                            {sharedGroups.map((group, index) => {
                                return (
                                    <div
                                        key={group.displayName}
                                        className='my-2 flex items-center rounded border border-ai-200 bg-white p-2 text-sm'
                                    >
                                        <span className='mr-2'>
                                            <CheckBoxGroup checked index={index} groupId={group.id} />
                                        </span>
                                        {group.displayName}
                                    </div>
                                )
                            })}
                            {/* <hr className="h-px my-2 bg-gray-200 border-0"/> */}
                            <p className='text-sm'>Select group to share:</p>
                            {unsharedGroups.map((group, index) => {
                                return (
                                    <div
                                        key={group.displayName}
                                        className='my-2 flex items-center rounded border border-ai-200 bg-white p-2 text-sm'
                                    >
                                        <span className='mr-2'>
                                            <CheckBoxGroup index={index} groupId={group.id} />
                                        </span>
                                        {group.displayName}
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )}
            </div>
        </ModelDash>
    )
}

export default ModelPermissions
