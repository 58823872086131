import { Code, PersonAddAltOutlined, PollOutlined, WorkHistory } from '@mui/icons-material'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetModel } from '../../features/queries'
import DashboardLayout from '../../Layouts/DashboardLayout'
import { ROUTER_PATHS } from '../../Utils/constants'
import ModelDashAbout from './ModelDashAbout'
import ModelDashActions from './ModelDashActions'
import ModelDashUserShare from './ModelDashUserShare'
import ModelDashVersions from './ModelDashVersions'
import { ChevronRight, MoveLeft } from 'lucide-react'

const predictiveTabs = [
    { label: 'Insights', icon: <PollOutlined fontSize='small' />, subPath: '/insights' },
    { label: 'Predictive Feedback', icon: <PollOutlined fontSize='small' />, subPath: '/predfeedback' },
    { label: 'Configuration', icon: <Code fontSize='small' />, subPath: '/configuration' },
    { label: 'Users and Groups', icon: <PersonAddAltOutlined fontSize='small' />, subPath: '/permissions' },
]

const generativeTabs = [
    { label: 'Insights', icon: <PollOutlined fontSize='small' />, subPath: '/insights' },
    { label: 'Generative Feedback', icon: <PollOutlined fontSize='small' />, subPath: '/genfeedback' },
    { label: 'Configuration', icon: <Code fontSize='small' />, subPath: '/configuration' },
    { label: 'Users and Groups', icon: <PersonAddAltOutlined fontSize='small' />, subPath: '/permissions' },
]

const reverseETLTabs = [
    { label: 'Jobs', icon: <WorkHistory fontSize='small' />, subPath: '/jobs' },
    { label: 'Configuration', icon: <Code fontSize='small' />, subPath: '/configuration' },
    { label: 'Users and Groups', icon: <PersonAddAltOutlined fontSize='small' />, subPath: '/permissions' },
]

interface ModelDashProps {
    children: React.ReactNode
    hideVersionSelect?: boolean
    hiddenTabs?: boolean
    jobId?: string
}

const ModelDash: React.FC<ModelDashProps> = ({ children, hideVersionSelect, hiddenTabs = false, jobId }) => {
    const { id } = useParams()
    const modelQuery = useGetModel(id)
    const navigate = useNavigate()
    const location = useLocation()

    const model = modelQuery.data

    const onTabSelect = (subPath: string) => {
        if(!model) return
        navigate(ROUTER_PATHS.model.replace(':id', model.id) + subPath)
    }

    const isTabSelected = (subPath: string) => {
        return location.pathname.includes(subPath)
    }

    let tabs = []
    if (model && model.config.params.analytics && model.config.params.analytics?.length > 1) {
        tabs = reverseETLTabs
    } else if (model && model.config.params.analytics && model.config.params.analytics?.length > 1 && model.config.params.analytics[0].className === 'OpenAIModel') {
        tabs = generativeTabs
    } else {
        tabs = predictiveTabs
    }

    return (
        <DashboardLayout title={model ? model.config.params.name : ''}>
            {jobId && (
                <div className='flex items-center gap-4 px-7 py-8 text-sm'>
                    <div className='flex items-center gap-2 text-gray-500 hover:cursor-pointer' onClick={() => navigate(-1)}>
                        <MoveLeft size={16} />
                        <p className=''>Back</p>
                    </div>
                    <div className='h-full w-[1px] -scale-y-150 bg-gray-600'>&nbsp;</div>
                    <div className='flex items-center gap-2'>
                        <p className='font-bold text-ai-500'>Logs</p>
                        <ChevronRight className='text-gray-500' size={16} />
                        <p className='font-bold text-gray-600'>{jobId}</p>
                    </div>
                </div>
            )}
            {!hiddenTabs && (
                <div className='mb-6 mt-6 flex px-6'>
                    {tabs.map((tab, index) => {
                        return (
                            <button
                                key={tab.label + index}
                                onClick={onTabSelect.bind(this, tab.subPath)}
                                className={`relative flex cursor-pointer items-center rounded-md px-5 py-2 text-gray-500 transition-all hover:font-bold hover:!text-ai hover:underline hover:decoration-2 hover:underline-offset-[6px] ${
                                    isTabSelected(tab.subPath) &&
                                    ' bg-ai-100 font-bold !text-ai underline decoration-2 underline-offset-[6px] hover:!underline-offset-[6px]'
                                }`}
                            >
                                <span className='relative -top-0.5'>{tab.label}</span>
                            </button>
                        )
                    })}
                </div>
            )}
            <div className='mx-6 mb-6 flex gap-4 rounded-md bg-white p-6 shadow'>
                <div className={`flex-none ${hideVersionSelect ? 'w-full' : 'w-2/3'} float-left inline-block`}>{children}</div>
                {model && !hideVersionSelect && (
                    <div className='flex min-h-[500px] w-1/3 flex-none flex-col'>
                        <div className='border-b border-ai-100'>
                            <ModelDashVersions model={model} />
                        </div>
                        <div className='border-b border-ai-100 py-7'>
                            <ModelDashAbout model={model} />
                        </div>
                        <div className='border-b border-ai-100 py-7'>
                            <ModelDashActions model={model} />
                        </div>
                        <div className='border-b border-ai-100 py-7'>
                            <ModelDashUserShare model={model} />
                        </div>
                    </div>
                )}
            </div>
        </DashboardLayout>
    )
}

export default ModelDash
