import React from "react"
import { FeedbackProps } from "./FeedbackSteps"
import { AddCircleOutline, Delete, Remove } from "@mui/icons-material"
import ContentviewMessage from "../ContentviewMessage"

const BinaryFeedbackParams: React.FC<FeedbackProps> = ({ _model, index, onSaveChanges, onCancel }) => {

    const [currentLabel, setCurrentLabel] = React.useState('')
    const [questionInput, setQuestionInput] = React.useState('')

    const [params, setParams] = React.useState({
        labelMap: [] as string[],
        questions: [] as string[]
    })

    React.useEffect(() => {
        if (_model && _model.config?.params?.feedbackSteps && _model.config?.params?.feedbackSteps?.length > 0 && _model.config?.params?.feedbackSteps[0].className === 'BinaryFeedback') {
            setParams({ ..._model.config?.params?.feedbackSteps[0].params })
        }
    }, [_model])

    if (!_model) {
        return null
    }

    const questionInputChange = (e: any) => {
        setQuestionInput(e.target.value)
    }

    const onAddQuestion = () => {
        let _q = params.questions ?? []
        if (questionInput !== '') {
            setParams({
                ...params,
                questions: [
                    ..._q,
                    questionInput
                ]
            })
        }
    }

    const onDeleteQuestion = (index: number) => {
        let _q = [...params.questions]
        _q.splice(index, 1)
        setParams({
            ...params,
            questions: _q
        })
    }

    const handleCurrentLabelField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentLabel(e.target.value)
    }

    const handleAddLabel = () => {
        const labelMap: string[] = [...params.labelMap]
        labelMap.push(currentLabel)
        setParams({ ...params, labelMap })
        setCurrentLabel('')
    }

    const handleLabelDelete = (index: number) => {
        let labelMap = [...params.labelMap]
        labelMap.splice(index, 1)
        setParams({ ...params, labelMap })
    }

    const onSubmit = () => {
        onSaveChanges(params)
    }

    return (
        <div className="flex flex-col gap-4">
            <div>
                <p className="text-ai-700 font-bold mt-4">Label Map</p>
                <p className="text-ai-800 text-[13px]">Add the labels for the Binary Class, specifically two (2) labels</p>
            </div>
            <div className='rounded bg-ai-100 border border-ai-200 p-3'>
                <div className="flex items-center gap-2 mb-2">
                    <div className="flex-1">
                        <input
                            id={"label-input"}
                            className="input w-full"
                            type="text"
                            placeholder="LEAD_NAME"
                            value={currentLabel}
                            onChange={handleCurrentLabelField}
                        />
                    </div>
                    <div>
                        <button
                            id="add-label"
                            className="btn-secondary flex items-center border border-ai p-2 text-[14px] font-bold"
                            onClick={handleAddLabel}
                        >
                            <AddCircleOutline sx={{ fontSize: "16px" }} />
                            <span className="px-1">Add</span>
                        </button>
                    </div>
                </div>
                <table aria-label="urls" className="border border-ai-200">
                    <thead className="border-b border-b-ai-200 bg-white text-left">
                        <tr>
                            <th className="w-1 px-4 py-2">
                                <span className="text-[16px] font-normal text-ai-800">
                                    #
                                </span>
                            </th>
                            <th className="w-full">
                                <span className="text-[16px] font-normal text-ai-800">
                                    Label
                                </span>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="text-left">
                        {(params.labelMap === undefined || params.labelMap.length === 0) && (
                            <tr>
                                <td colSpan={4}>
                                    <ContentviewMessage title="" message="Add labels for review"/>
                                </td>
                            </tr>
                        )}

                        {params.labelMap &&
                        params.labelMap.map((source: string, index: number) => {
                            return (
                                <tr key={source + index} className={"bg-ai-50"}>
                                    <td className="p-4 align-top">
                                        <p className=" text-ai-800">
                                            {index + 1 + "."}
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-ai-800">
                                            {source}
                                        </p>
                                    </td>
                                    <td>
                                        <button className="btn-icon" onClick={() => handleLabelDelete(index) }>
                                            <Remove color="error" />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <hr className="border-ai-200"/>
            <p className="text-ai-700 font-bold">Quality Questions</p>
            <div className='rounded bg-ai-100 border border-ai-200 p-3'>
                <div className="flex items-center gap-2 mb-2">
                    <div className="flex-1">
                        <input
                            id={"question-input"}
                            className="input w-full"
                            type="text"
                            placeholder="Was this data of value?"
                            value={questionInput}
                            onChange={questionInputChange}
                        />
                    </div>
                    <div>
                        <button
                            id="add-question"
                            className="btn-secondary flex items-center border border-ai p-2 text-[14px] font-bold"
                            onClick={onAddQuestion}
                        >
                            <AddCircleOutline sx={{ fontSize: "16px" }} />
                            <span className="px-1">Add</span>
                        </button>
                    </div>
                </div>
                <table aria-label="urls" className="border border-ai-200">
                    <thead className="border-b border-b-ai-200 bg-white text-left">
                        <tr>
                            <th className="w-1 px-4 py-2">
                                <span className="text-[16px] font-normal text-ai-800">
                                    #
                                </span>
                            </th>
                            <th className="w-full">
                                <span className="text-[16px] font-normal text-ai-800">
                                    Questions
                                </span>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="text-left">
                        {(params.questions === undefined || params.questions.length === 0) && (
                            <tr>
                                <td colSpan={4}>
                                    <ContentviewMessage title="" message="Add questions for review"/>
                                </td>
                            </tr>
                        )}

                        {params.questions &&
                        params.questions.map((source: string, index: number) => {
                            return (
                                <tr key={source + index} className={"bg-ai-50"}>
                                    <td className="p-4 align-top">
                                        <p className=" text-ai-800">
                                            {index + 1 + "."}
                                        </p>
                                    </td>
                                    <td>
                                        <p className=" text-ai-800">
                                            {source}
                                        </p>
                                    </td>
                                    <td>
                                        <button className="btn-icon" onClick={() => onDeleteQuestion(index) }>
                                            <Remove color="error" />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <hr className="border-ai-200"/>
            <div className="flex justify-between">
                <button id="save-changes-button" className="btn-primary" onClick={onSubmit}>Save Changes</button>
                <button id="cancel-button" className="btn-secondary border-none bg-transparent text-[#FF0000]" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    )
}

export default BinaryFeedbackParams
