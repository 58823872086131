import React from 'react'
import ModelDash from './ModelDash'
import ModelDashPredictionsFeedback from './ModelDashPredictionsFeedback'
import { useParams } from 'react-router-dom'
import { useGetModel } from '../../features/queries'

const ModelPredictionFeedback: React.FC<{}> = () => {
    const { id } = useParams()
    const modelQuery = useGetModel(id)

    const model = modelQuery.data

    if (!model) {
        return null
    }

    return (
        <ModelDash>
            <ModelDashPredictionsFeedback model={model} />
        </ModelDash>
    )
}

export default ModelPredictionFeedback