import React from 'react'
import { FeedbackProps } from './FeedbackSteps'
import { AddCircle, Close, Remove } from '@mui/icons-material'
import Dropdown from '../Dropdown'
import ContentviewMessage from '../ContentviewMessage'

interface SurveyParams {
    questions: SurveyQuestion[],
    step: number
}

interface SurveyQuestion {
    question: string
    answerType: string
    choices?: string[]
}

const inputTypes = [
    {
        label: 'Open text',
        value: 'text',
        icon: null,
        helperLabel: 'Uses textarea for user input',
    },
    {
        label: 'Single Select',
        value: 'singleChoice',
        icon: null,
        helperLabel: 'Uses dropdown for user input',
    },
    {
        label: 'Multiple Select',
        value: 'multiChoice',
        icon: null,
        helperLabel: 'Uses checkbox for user input',
    },
]

const StepFeedbackParams: React.FC<FeedbackProps> = ({
    _model,
    index,
    onSaveChanges,
    onCancel,
}) => {
    const [params, setParams] = React.useState<SurveyParams>({
        questions: [],
        step: 0
    })
    const [inputType, setInputType] = React.useState(0)
    const [question, setQuestion] = React.useState('')
    const [choice, setChoice] = React.useState('')
    const [choices, setChoices] = React.useState<string[]>([])
    const [renderingSteps, setRenderingSteps] = React.useState<string[]>([])
    const [step, setStep] = React.useState(0)

    React.useEffect(() => {
        const renderingSteps = _model.config?.params?.renderingSteps
        if (renderingSteps) {
            const availableSteps = renderingSteps.map((step, i) => {
                return step.label && step.label.length > 0
                    ? step.label
                    : `${step.className}-${i}`
            })
            setRenderingSteps(availableSteps)
            if (index !== null && index >= 0) {
                const feedbackSteps = _model?.config?.params?.feedbackSteps ?? []
                if (
                    feedbackSteps.length > 0 &&
                    feedbackSteps[index].className ===
                        'StepFeedback'
                ) {
                    availableSteps.forEach((step, i) =>{
                        if(feedbackSteps[index].params.step === i) {
                            setStep(i)
                        }
                    })
                    setParams({
                        ...feedbackSteps[index].params,
                    })
                }
            } else {
                setParams({ questions: [], step: -1 })
            }
        }
    }, [_model, index])

    if (!_model) {
        return null
    }

    const onSelectedRenderingStep = (event: any) => {
        const selectedIndex = Number(event.target.value)
        setStep(selectedIndex)
        setParams({
            ...params,
            step: selectedIndex
        })
    }

    const onInputTypeChange = (event: any) => {
        const selectedIndex = Number(event.target.value)
        setInputType(selectedIndex)
    }

    const onQuestionFieldChange = (event: any) => {
        setQuestion(event.target.value)
    }

    const onChoiceFieldChange = (event: any) => {
        setChoice(event.target.value)
    }

    const onAddChoice = (e: any) => {
        e.preventDefault()
        setChoices([...choices, choice])
        setChoice('')
    }

    const onRemoveChoice = (index: number) => {
        let _choices = [...choices]
        _choices.splice(index, 1)
        setChoices(_choices)
    }

    const onDeleteQuestion = (index: number) => {
        if(_model.config.params.feedbackSteps && index !== null) {
            let _questions = [ ...params.questions ]
            _questions.splice(index, 1)
            let _params = { ..._model.config.params.feedbackSteps[index].params }
            _params.questions = _questions
            onSaveChanges(_params)
        }
    }

    const onAddQuestion = () => {
        let _params = { ...params }
        let questions = [..._params.questions]
        const questionObj = {
            question,
            answerType: inputTypes[inputType].value,
            choices,
        }
        questions.push(questionObj)
        _params.questions = questions
        setParams(_params)
        setQuestion('')
        setChoices([])
    }

    const onSubmit = () => {
        onSaveChanges(params)
    }

    return (
        <div className='flex flex-col gap-4'>
            <p className='font-bold text-ai-700'>
                Select rendering steps to provide feedback on
            </p>
            <div className='flex items-center'>
                <div className='w-1/2'>
                    <Dropdown
                        id={"rendering-step-dropdown"}
                        items={renderingSteps}
                        handleDropdownChange={onSelectedRenderingStep}
                        selectedIndex={step}
                        label=''
                    />
                </div>
            </div>
            <div>
                <p className='font-bold text-ai-700'>Add Questions</p>
                <div className='flex items-end gap-2'>
                    <div className='flex-1'>
                        <input
                            id={"question-input"}
                            value={question ?? ''}
                            onChange={onQuestionFieldChange}
                            className='input w-full'
                        />
                    </div>
                    <div className='flex flex-col'>
                        <label className='text-right text-xs text-ai-800'>
                            {inputTypes[inputType].helperLabel}
                        </label>
                        <Dropdown
                            id={"question-type"}
                            items={inputTypes.map(type => type.label)}
                            handleDropdownChange={onInputTypeChange}
                            selectedIndex={inputType}
                            placeholder='Select input type'
                        />
                    </div>
                    <div>
                        <button id={"add-question"} onClick={onAddQuestion} className='btn-secondary'>Add</button>
                    </div>
                </div>
            </div>
            {inputTypes[inputType].value !== 'text' && (
                <div className='w-4/12'>
                    <label className='text-ai-800'>Add select options</label>
                    {choices.map((choice, index) => {
                        return (
                            <div
                                key={choice + index + '-edit'}
                                className='flex gap-2'
                            >
                                <div className='flex-1'>
                                    <p className='text-ai-800'> - {choice} </p>
                                </div>
                                <button
                                    onClick={onRemoveChoice.bind(this, index)}
                                    className='btn-icon'
                                >
                                    <Close fontSize='small' color='warning' />
                                </button>
                            </div>
                        )
                    })}
                    <form onSubmit={onAddChoice} style={{ marginTop: '2px' }}>
                        <div className='flex items-center gap-2'>
                            <input
                                id={"single-answer-input"}
                                type={'text'}
                                value={choice}
                                onChange={onChoiceFieldChange}
                                className='input w-full'
                            />
                            <button
                                id={"add-single-answer"}
                                type='submit'
                                onClick={onAddChoice}
                                className='btn-icon'
                            >
                                <AddCircle fontSize='small' />
                            </button>
                        </div>
                    </form>
                </div>
            )}
            <div>
                <div className='rounded border border-ai-200 bg-ai-100 p-3'>
                    <table aria-label='urls' className='border border-ai-200'>
                        <thead className='border-b border-b-ai-200 bg-white text-left'>
                            <tr>
                                <th className='w-1 px-4 py-2'>
                                    <span className='text-[16px] font-normal text-ai-800'>
                                        #
                                    </span>
                                </th>
                                <th className='w-full'>
                                    <span className='text-[16px] font-normal text-ai-800'>
                                        Questions
                                    </span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className='text-left'>
                            {params.questions.length === 0 && (
                                <tr>
                                    <td colSpan={4}>
                                        <ContentviewMessage
                                            title=''
                                            message='Add questions for review'
                                        />
                                    </td>
                                </tr>
                            )}

                            {params.questions.map((source: any, index: number) => {
                                return (
                                    <React.Fragment>
                                        <tr
                                            key={source + index}
                                            className={'bg-ai-50'}
                                        >
                                            <td className='p-4 align-top'>
                                                <p className=' text-ai-800'>
                                                    {index + 1 + '.'}
                                                </p>
                                            </td>
                                            <td>
                                                <p className=' text-ai-800'>
                                                    {source.question}
                                                </p>
                                            </td>
                                            <td>
                                                <button
                                                    className='btn-icon'
                                                    onClick={() =>
                                                        onDeleteQuestion(index)
                                                    }
                                                >
                                                    <Remove color='error' />
                                                </button>
                                            </td>
                                        </tr>
                                        {source.answerType !== 'text' && (
                                            <tr>
                                                <td
                                                    colSpan={3}
                                                    className='bg-white px-2 py-1'
                                                >
                                                    <label className='text-sm '>
                                                        available selections:
                                                    </label>
                                                    <hr className='border-ai-200' />
                                                    {(source.choices ?? []).map(
                                                        (c: any, j: number) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        c +
                                                                        j +
                                                                        '-preview'
                                                                    }
                                                                    className='px-2 py-1'
                                                                >
                                                                    <div className='flex items-center gap-2'>
                                                                        <div className='flex-1'>
                                                                            <label className='text-sm text-ai-800'>
                                                                                -{' '}
                                                                                {
                                                                                    c
                                                                                }
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <hr className='border-ai-200' />
            <div className='flex justify-between'>
                <button id='save-changes-button' className='btn-primary' onClick={onSubmit}>
                    Save Changes
                </button>
                <button
                    id="cancel-button"
                    className='btn-secondary border-none bg-transparent text-[#FF0000]'
                    onClick={onCancel}
                >
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default StepFeedbackParams
