import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios";
import { endpoints } from "../endpoints";
import { downloadFileFromBuffer } from "../../Utils/utils";

const useExportJobLogs = () => {
    return useMutation({
        mutationFn: async (variables: { etlStatusId: string, fileName: string }) => {
            const token = localStorage.getItem("token");
            const authtype = localStorage.getItem("authtype");
            if (token && authtype) {
                const options = {
                    headers: {
                        authtype,
                        Authorization: `Bearer ${token}`,
                    }
                };
                const response = await axios.get(`${endpoints.exportEtlLogs}/${variables.etlStatusId}`, options);
                return await downloadFileFromBuffer(response.data.data.data, variables.fileName + '.zip');
            }
        }
    })   
}

export default useExportJobLogs
