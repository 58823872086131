import React from "react";
import { motion } from "framer-motion";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { AddCircleOutline, FilterListOutlined } from "@mui/icons-material";
import useGetRoles from "../../features/queries/useGetRoles";
import { useGetGroups } from "../../features/queries";
import Dropdown from "../../Components/Dropdown";

interface Props {
    handleInputChange: (e: any) => void;
    handleFilterByRole: (e: any) => void;
    handleFilterByGroup: (e: any) => void;
    handleSortColumn: (e: any) => void;
    handleSortDirection: (e: any) => void;
}

const UserFilterBar: React.FC<Props> = ({ handleInputChange, handleFilterByGroup, handleFilterByRole, handleSortColumn, handleSortDirection }) => {
    const [sortKey, setSortKey] = React.useState("givenName");
    const [sortOrder, setSortOrder] = React.useState("ASC");
    const [openSorts, setOpenSorts] = React.useState(false);

    const [selectedRole, setSelectedRole] = React.useState(-1)
    const [selectedGroup, setSelectedGroup] = React.useState(-1)

    const rolesQuery = useGetRoles();
    const groupQuery = useGetGroups({ page: 0, pageSize: 0, searchTerm: "", sortColumn: "", sortDirection: "" });
    const handleDropdownChange = (e: any, defaultValue?: string) => {
        const index = Number(e.target.value)
        if (e.target.id === "roles") {
            handleFilterByRole(e);
            setSelectedRole(index)
        } else if (e.target.id === "groups") {
            handleFilterByGroup(e);
            setSelectedGroup(index)
        } else return;
    };

    const onSortKeyChange = (e: any) => {
        setSortKey(e.target.value);
        handleSortColumn(e);
    };

    const onSortOrderChange = (e: any) => {
        setSortOrder(e.target.value);
        handleSortDirection(e);
    };

    let dropdownRoles: string[] = rolesQuery.data?.content.map((role) => role.name) ?? [];
    // dropdownRoles.unshift("All Roles");
    let dropdownGroups: string[] = groupQuery.data?.content?.map((group) => group.displayName) ?? [];
    // dropdownGroups.unshift("All Groups");

    return (
        <div
            className={`flex gap-4 rounded-md border border-ai-200 bg-white p-3 text-sm transition-[height] duration-500 ease-in-out ${
                openSorts ? "flex-col max-md:h-[13.5rem] md:h-[9.8rem] lg:h-[8rem]" : "max-md:h-[7.6rem] md:h-[4.15rem]"
            } `}>
            <div className="flex w-full gap-4 max-md:flex-col">
                <div className="flex flex-1 max-h-10 w-full items-center rounded-md border border-ai-200 p-2 hover:border-ai focus:border-ai-400 lg:max-w-sm lg:flex-1">
                    <SearchOutlinedIcon className="text-ai" />
                    <input className="w-full px-2 focus:outline-none" placeholder="Search users" onChange={handleInputChange} />
                </div>
                <div className="flex w-1/3 gap-4">
                    <Dropdown id="roles" items={dropdownRoles} selectedIndex={selectedRole} label="Filter by role" handleDropdownChange={(e) => handleDropdownChange(e)} />
                    <Dropdown id="groups" items={dropdownGroups} selectedIndex={selectedGroup} label="Filter by group" handleDropdownChange={(e) => handleDropdownChange(e)} />
                    <button
                        className="flex max-h-10 min-w-max items-center space-x-2 rounded-md border border-ai-200 p-2 text-black lg:max-w-[7rem] lg:pr-3"
                        title="Sort By"
                        onClick={() => {
                            setOpenSorts(!openSorts);
                        }}>
                        <FilterListOutlined className="text-ai" />
                        <p className="">Sort By</p>
                    </button>
                </div>
            </div>

            {openSorts && (
                <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.25, delay: 0.25 }} className="space-y-2 pb-2 text-black">
                    <p className="">Sort by</p>
                    <div className="flex flex-col space-y-2 lg:flex-row lg:items-center lg:space-x-8 lg:space-y-0">
                        <div className="flex flex-1 space-x-3">
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group1"
                                    type="radio"
                                    value="givenName"
                                    checked={sortKey === "givenName"}
                                    onChange={(e) => {
                                        onSortKeyChange(e);
                                    }}
                                />
                                <div className="radio" />
                                <label htmlFor="" className="pl-3 truncate ">
                                    Name
                                </label>
                            </div>
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group1"
                                    type="radio"
                                    value="familyName"
                                    checked={sortKey === "familyName"}
                                    onChange={(e) => {
                                        onSortKeyChange(e);
                                    }}
                                />
                                <div className="radio" />
                                <label htmlFor="" className="pl-3 truncate">
                                    Last Name
                                </label>
                            </div>
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group1"
                                    type="radio"
                                    value="email"
                                    checked={sortKey === "email"}
                                    onChange={(e) => {
                                        onSortKeyChange(e);
                                    }}
                                />
                                <div className="radio" />
                                <label htmlFor="" className="pl-3 truncate">
                                    Email
                                </label>
                            </div>
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group1"
                                    type="radio"
                                    value="createdDate"
                                    checked={sortKey === "createdDate"}
                                    onChange={(e) => {
                                        onSortKeyChange(e);
                                    }}
                                />
                                <div className="radio" />
                                <label htmlFor="" className="pl-3 truncate">
                                    Created Date
                                </label>
                            </div>
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group1"
                                    type="radio"
                                    value="modifiedDate"
                                    checked={sortKey === "modifiedDate"}
                                    onChange={(e) => {
                                        onSortKeyChange(e);
                                    }}
                                />
                                <div className="radio" />
                                <label htmlFor="" className="pl-3 truncate">
                                    Updated Date
                                </label>
                            </div>
                        </div>

                        <div className="flex space-x-3">
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group2"
                                    type="radio"
                                    value="ASC"
                                    checked={sortOrder === "ASC"}
                                    onChange={(e) => {
                                        onSortOrderChange(e);
                                    }}
                                />
                                <div className="radio" />
                                <label htmlFor="" className="pl-3 truncate">
                                    Ascending
                                </label>
                            </div>
                            <div className="relative flex items-center">
                                <input
                                    className="radio-parent peer"
                                    name="group2"
                                    type="radio"
                                    value="DESC"
                                    checked={sortOrder === "DESC"}
                                    onChange={(e) => {
                                        onSortOrderChange(e);
                                    }}
                                />
                                <div className="radio " />
                                <label htmlFor="" className="pl-3 truncate">
                                    Descending
                                </label>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default UserFilterBar;
