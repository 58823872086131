import React from "react";
import ModelItem from "./ModelItem";
import { AIRModel } from "../../features/types";

interface ModelListProps {
    workspace: AIRModel[];
}

const ModelList: React.FC<ModelListProps> = ({ workspace }) => {
    const [models, setModels] = React.useState<AIRModel[]>([]);

    React.useEffect(() => {
        setModels([...workspace]);
    }, [workspace]);

    return (
        <div className="grid h-full w-full grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-6">
            {models.map((model, index) => {
                return (
                    <ModelItem
                        key={`${model.id}-${index}-card`}
                        id={`${index + 1}-model-card`}
                        model={model}
                        configDidChange={(config) => {
                            let _w = [...models];
                            let _m = { ..._w[index] };
                            _m.config = { ...config };
                            _w[index] = _m;
                            setModels(_w);
                        }}
                    />
                );
            })}
        </div>
    );
};

export default ModelList;
