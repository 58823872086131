import axios from 'axios'
import { endpoints } from '../endpoints'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// Function to clear a job status by modelId
const clearJobStatus = async (modelId: string) => {
    try {
        const token = localStorage.getItem('token')
        const authtype = localStorage.getItem('authtype')
        if (token && authtype) {
            const options = {
                headers: {
                    authtype,
                    Authorization: `Bearer ${token}`,
                },
            }
            const response = await axios.post(
                endpoints.jobStatus,
                {
                    modelId: modelId,
                },
                options,
            )

            return response.data
        }
    } catch (error) {
        console.error('Error clearing job status:', error)
    }
}

const useClearJobStatus = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (variables: { modelId: string }) => {
            return clearJobStatus(variables.modelId)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['jobStatus'])
        },
        onError() {
            console.log('Error running clearJobStatus function')
        },
    })
}

export default useClearJobStatus
