import React from "react"
import logoGif from '../Assets/logo.gif'

interface LogoSpinnerProps {
	message?: string
}
const LogoSpinner: React.FC<LogoSpinnerProps> = ({ message }) => {
	return (
		<>
			<h2 className="font-bold text-ai-50 white">{message}</h2>
			<img src={logoGif} alt="logo" className={`h-12  w-12`} />
		</>
	)
}

export default LogoSpinner