import React, { useMemo } from 'react'
import PreprocessingSteps from '../../Components/preprocessing/PreprocessingSteps'
import HarvesterSteps from '../../Components/harvesters/HarvesterSteps'
import AnalyticsSteps from '../../Components/analytics/AnalyticsSteps'
import PostprocessingSteps from '../../Components/postprocessing/PostprocessingSteps'
import RenderingSteps from '../../Components/rendering/RenderingSteps'
import FeedbackSteps from '../../Components/feedback/FeedbackSteps'
import {
    AddCircleOutline,
    CheckOutlined,
    Code,
    EditOutlined,
    KeyboardArrowDownRounded,
    KeyboardArrowUpRounded,
    Remove,
} from '@mui/icons-material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { EMPTY_AIR_MODEL, EMPTY_AIR_MODEL_CONFIG, JSONTreeTheme, ROUTER_PATHS } from '../../Utils/constants'
import DashboardLayout from '../../Layouts/DashboardLayout'
import { JSONTree } from 'react-json-tree'
import { useGetCurrentUser, useGetModel, useWorkspace } from '../../features/queries'
import { useConfigUpdate } from '../../features/mutations'
import Checkbox from '../../Components/Checkbox'
import { useLayout } from '../../Store/Hooks'
import { ModelConfigRoot } from '../../features/types'
import { createAIRZipFromConfig, extractContentFromAIRZip } from '../../Utils/utils'
import { FileUploader } from 'react-drag-drop-files'
import { useCreateModel } from '../../features/mutations'
import { TwoSeventyRing } from 'react-svg-spinners'
import useJobRequests from '../../features/mutations/useJobRequests'
import { toast } from 'sonner'

const configSteps = [
    {
        label: 'Harvesters',
        description: 'How to harvest the data from DOM',
        className: 'harvestingSteps',
    },
    {
        label: 'Analytics',
        description: 'Remote or local analytic models given a route/path',
        className: 'analytics',
    },
    {
        label: 'Preprocessing',
        description: 'Preprocess the harvested data used for input',
        className: 'preprocessingSteps',
    },
    {
        label: 'Postprocessing',
        description: 'Postprocess the results from the output',
        className: 'postprocessingSteps',
    },
    {
        label: 'Rendering',
        description: 'Decorate DOM',
        className: 'renderingSteps',
    },
    {
        label: 'Feedback',
        description: 'Widgets that help users provide feedback for the results and model',
        className: 'feedbackSteps',
    },
]

const stepComponents = {
    Harvesters: HarvesterSteps,
    Analytics: AnalyticsSteps,
    Preprocessing: PreprocessingSteps,
    Postprocessing: PostprocessingSteps,
    Rendering: RenderingSteps,
    Feedback: FeedbackSteps,
}

interface ModelEditProps {
    create?: boolean
}

const ModelEdit: React.FC<ModelEditProps> = ({ create }) => {
    const { id } = useParams()
    const navigate = useNavigate()
    const currentUserQuery = useGetCurrentUser()
    const currentUser = currentUserQuery.data
    const workspaceQuery = useWorkspace({ userOnly: 'true' })
    const modelQuery = useGetModel(id)
    const configMutation = useConfigUpdate()
    const { setAnalyticPreview } = useLayout()
    const { createAirModel, uploadAirModel } = useCreateModel()
    const [description, setDescription] = React.useState('')
    const [url, setUrl] = React.useState('')
    const [whitelist, setWhitelist] = React.useState<string[]>([])
    const [email, setEmail] = React.useState('')
    const [emailList, setEmailList] = React.useState<string[]>([])
    const [tab, setTab] = React.useState(0)
    const [selectedStep, setSelectedStep] = React.useState({
        index: -1,
        step: configSteps[0],
    })
    const [modelFiles, setModelFiles] = React.useState<File[]>([])
    const [rootFolder, setRootFolder] = React.useState<string | null>(null)
    const [files, setFiles] = React.useState<File[] | null>(null)
    const [isImporting, setIsImporting] = React.useState(false)
    const [editingImportIndex, setEditingImportIndex] = React.useState(-1)
    const [searchParams, setSearchParams] = useSearchParams()
    const modelCardType = searchParams.get('modelCardType') as 'leanai' | 'aiautomation' | null
    const [config, setConfig] = React.useState<ModelConfigRoot>({ ...EMPTY_AIR_MODEL_CONFIG })
    const { runJob } = useJobRequests()
    const maxModels = useMemo(() => {
        if (currentUser) {
            if(currentUser.data?.tenantSettings?.max_models_per_user) {
                return parseInt(currentUser.data.tenantSettings.max_models_per_user)
            }
        }
    }, [currentUserQuery.data])
    const usersModels = useMemo(() => {
        if (workspaceQuery.data) {
            return workspaceQuery.data.length
        }
    }, [workspaceQuery.data])
    const limitReached = useMemo(() => {
        if (maxModels && usersModels) {
            return usersModels >= maxModels
        }
    }, [maxModels, usersModels])
    const userRole = useMemo(()=>{
        if(currentUserQuery.data?.data){
            return currentUserQuery.data?.data.roleName === "Community User"
        }
    }, [currentUserQuery.data?.data])
    // Filter steps based on the modelCardType
    const filteredConfigSteps = useMemo(
        () => (modelCardType === 'leanai' ? configSteps : configSteps.filter(step => step.label === 'Analytics')),
        [modelCardType],
    )

    const _model = modelQuery.data

    React.useEffect(() => {
        if (create && limitReached && userRole) {
            navigate(ROUTER_PATHS.modelSelect)
        }
    }, [create, limitReached, userRole])

    React.useEffect(() => {
        if (!modelCardType) {
            navigate(ROUTER_PATHS.modelSelect)
        }
    }, [modelCardType])

    React.useEffect(() => {
        if (!create && _model) {
            let model = { ..._model }
            if (!model.config.params.type) {
                model.config.params.type = modelCardType ?? 'leanai'
            }
            setConfig({ ...model.config })
            setDescription(model.config.params.description)
            setWhitelist(model.config.params.urls && model.config.params.urls !== '*' ? model.config.params.urls : [])
            setEmailList(model.config.params.emails && model.config.params.emails !== '*' ? model.config.params.emails : [])
        } else if (create && modelCardType) {
            setConfig({
                ...EMPTY_AIR_MODEL_CONFIG,
                params: {
                    ...EMPTY_AIR_MODEL_CONFIG.params,
                    author: `${currentUser?.data.givenName ?? ''} ${currentUser?.data.familyName ?? ''}`,
                    type: modelCardType,
                },
            })
        }
        return resetValues
    }, [create, _model, modelCardType])

    const isReadyForJob = (): boolean => {
        const analytics = config.params.analytics
        if (analytics) {
            const hasRead = analytics.some(analytic => analytic.params.connectorAction === 'read')
            const hasWrite = analytics.some(analytic => analytic.params.connectorAction === 'write')
            return hasRead && hasWrite
        }
        return false
    }

    const onRunJob = () => {
        if (!_model) return
        runJob.mutate({ modelId: _model.id, modelName: config.params.name })
    }

    const resetValues = () => {
        setAnalyticPreview(null)
        EMPTY_AIR_MODEL_CONFIG.params.analytics = []
        EMPTY_AIR_MODEL_CONFIG.params.harvestingSteps = []
        EMPTY_AIR_MODEL_CONFIG.params.preprocessingSteps = []
        EMPTY_AIR_MODEL_CONFIG.params.postprocessingSteps = []
        EMPTY_AIR_MODEL_CONFIG.params.renderingSteps = []
        EMPTY_AIR_MODEL_CONFIG.params.feedbackSteps = []
        EMPTY_AIR_MODEL_CONFIG.params.emails = []
        EMPTY_AIR_MODEL_CONFIG.params.urls = "*"
        setConfig({
            ...EMPTY_AIR_MODEL_CONFIG,
            params: {
                ...EMPTY_AIR_MODEL_CONFIG.params,
                author: `${currentUser?.data?.givenName ?? ''} ${currentUser?.data?.familyName ?? ''}`,
                type: modelCardType ?? 'leanai',
            },
        })
        setDescription(EMPTY_AIR_MODEL_CONFIG.params.description)
        setWhitelist([])
        setEmailList([])
    }

    const onDescriptionChange = (e: any) => {
        setDescription(e.target.value)
    }

    const onUrlFieldChange = (e: any) => {
        setUrl(e.target.value)
    }

    const onEmailFieldChange = (e: any) => {
        setEmail(e.target.value)
    }

    const onAddToEmailList = () => {
        if (email !== '') {
            const _c = {
                ...config,
                params: {
                    ...config.params,
                    emails: [...emailList, email],
                },
            }
            if (create) {
                setConfig(_c)
            } else if (_model) {
                configMutation.mutate({ modelId: _model.id, config: _c, upload: true })
            }
            setEmailList(_c.params.emails)
            setEmail('')
        }
    }

    const onAddUrlToWhitelist = () => {
        if (url !== '') {
            var urls = url.split(',')
            const _c = {
                ...config,
                params: {
                    ...config.params,
                    urls: [...whitelist, ...urls],
                },
            }
            if (create) {
                setConfig(_c)
            } else if (_model) {
                configMutation.mutate({ modelId: _model.id, config: _c, upload: true })
            }
            setWhitelist(_c.params.urls)
            setUrl('')
        }
    }

    const onRemoveImportFile = (index: number) => {
        if (!files) {
            return
        }
        let _f = [...files]
        _f.splice(index, 1)
        if (editingImportIndex === index) {
            setEditingImportIndex(-1)
            resetValues()
        }
        if (_f.length === 0) {
            resetValues()
        }
        setFiles(_f)
    }

    const onRemoveUrlFromWhitelist = (index: number) => {
        const wildcard: '*' = '*'
        let _w = [...whitelist]
        _w.splice(index, 1)
        let _c = { ...config }
        _c.params.urls = _w.length === 0 ? wildcard : _w
        if (create) {
            setConfig(config)
        } else if (_model) {
            configMutation.mutate({ modelId: _model.id, config: _c, upload: true })
        }
        setWhitelist(_c.params.urls && _c.params.urls !== '*' ? _c.params.urls : [])
    }

    const onRemoveEmailFromEmailList = (index: number) => {
        let _e = [...emailList]
        _e.splice(index, 1)
        let _c = { ...config }
        _c.params.emails = _e
        if (create) {
            setConfig(config)
        } else if (_model) {
            configMutation.mutate({ modelId: _model.id, config: _c, upload: true })
        }
        setEmailList(_c.params.emails)
    }

    const saveModelDescription = () => {
        if (!_model) {
            return
        }
        let _c = { ...config }
        _c.params.description = description
        configMutation.mutate({ modelId: _model.id, config: _c, upload: true })
    }

    const onAutorunChange = () => {
        const _c = {
            ...config,
            params: {
                ...config.params,
                autoRun: !config.params.autoRun || config.params.autoRun === 'false' ? 'true' : 'false',
            },
        }
        if (create) {
            setConfig(_c)
        } else if (_model) {
            configMutation.mutate({ modelId: _model.id, config: _c, upload: true })
        }
    }

    interface ConfigStep {
        label: string
        description: string
        className: keyof typeof config.params
    }

    const modelHasStep = (step: ConfigStep): boolean => {
        const _s = config.params[step.className]

        if (_s) {
            return _s.length > 0
        }
        return false
    }

    const getModelStepClass = (step: ConfigStep): string => {
        const _s = config.params[step.className]
        if (_s && _s.length > 0) {
            if (step.label === 'Rendering' || step.label === 'Analytics') {
                return `${_s.length} ${_s.length === 1 ? 'step' : 'steps'} added`
            } else {
                return _s[0].className
            }
        }
        return ''
    }

    const onStepSelected = (index: number, step: ConfigStep) => {
        if (selectedStep.index === index) {
            setSelectedStep({ index: -1, step })
        } else {
            setSelectedStep({ index, step })
        }
    }

    const handleFieldOnChange = (e: any) => {
        setConfig({ ...config, params: { ...config.params, [e.target.name]: e.target.value } })
    }

    const onAirModelImport = async (file: File) => {
        const content = await extractContentFromAIRZip(file)
        if (content && content.config) {
            let modelCardType = content.config.params.type
            // Backwards compatibility for old Model Cards
            if (!modelCardType && content.config.params.analytics && content.config.params.analytics.length === 1) {
                modelCardType = 'leanai'
            } else if (!modelCardType && content.config.params.analytics && content.config.params.analytics.length > 1) {
                modelCardType = 'aiautomation'
            }
            let _c = { ...content.config }
            _c.params.type = modelCardType
            setConfig(_c)
            if (content.files) {
                setModelFiles(content.files)
            }
            if (content.rootFolder) {
                setRootFolder(content.rootFolder)
            }
            if (id) {
                navigate(ROUTER_PATHS.workspaceEdit.replace(':id', id) + `?modelCardType=${modelCardType}`)
            } else {
                navigate(ROUTER_PATHS.create + `?modelCardType=${modelCardType}`)
            }
        }
    }

    const onImport = (file: any) => {
        let _f = files ? [...files] : []
        for (const x of file) {
            _f.push(x)
        }
        setFiles(_f)
    }

    const onEditImport = async (index: number) => {
        if (editingImportIndex > -1) {
            const airFile = await createAIRZipFromConfig(config, modelFiles, rootFolder)
            if (airFile && files) {
                let _f = [...files]
                _f[editingImportIndex] = airFile
                setFiles(_f)
            }
        }
        if (files) {
            if (editingImportIndex === index) {
                setEditingImportIndex(-1)
                resetValues()
            } else {
                setEditingImportIndex(index)
                onAirModelImport(files[index])
            }
        }
    }

    const onConfigChange = (config: any) => {
        if (create) {
            setConfig({...config})
            toast.success('Saved Changes', {
                description: 'Configuration updated'
            })
        } else if (_model) {
            configMutation.mutate({ modelId: _model.id, config, upload: true })
        }
    }

    const renderStepComponent = () => {
        const Component = stepComponents[selectedStep.step.label as keyof typeof stepComponents]
        if (Component) {
            return <Component _model={_model ?? { ...EMPTY_AIR_MODEL, config: { ...config } }} onConfigChange={onConfigChange} />
        }
        return null
    }

    const onCreateSubmit = async () => {
        if (isImporting && files && files.length > 0) {
            let _f = [...files]
            if (editingImportIndex > -1) {
                const airFile = await createAIRZipFromConfig(config, modelFiles, rootFolder)
                if (airFile) {
                    _f[editingImportIndex] = airFile
                    setFiles(_f)
                }
            }
            uploadAirModel.mutate({ file: _f })
        } else {
            if (config.params.name === '') {
                toast.warning('Missing Requirements', {
                    description: 'Missing name for model'
                })
                return
            }
            if (!config.params.analytics || config.params.analytics.length === 0) {
                toast.warning('Missing Requirements', {
                    description: 'An air model requires at least one analytic step'
                })
                return
            }
            createAirModel.mutate({ config: { ...config, params: { ...config.params, description } }, resources: modelFiles, rootFolder })
        }
    }

    return (
        <div id='airjs-model-edit-container' className='relative h-full w-full' key={_model ? _model.id : 'new'}>
            {(currentUserQuery.isLoading || workspaceQuery.isLoading || configMutation.isLoading || createAirModel.isLoading || uploadAirModel.isLoading) && (
                <div className='absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-ai-500 opacity-25'>
                    <TwoSeventyRing color='white' height={60} width={60} />
                </div>
            )}
            <DashboardLayout title={create ? 'New Model Card' : config.params.name}>
                <div key={create ? 'create' : 'edit'} className='flex min-h-full'>
                    <div className='flex-1 p-6'>
                        {create && (
                            <div className='mb-4 flex flex-col gap-2 rounded-md bg-white p-4'>
                                <div className='flex justify-between'>
                                    <div className={`flex items-center`}>
                                        <span>
                                            <Checkbox
                                                checked={isImporting}
                                                onChange={() => {
                                                    setIsImporting(!isImporting)
                                                    setFiles([])
                                                    setEditingImportIndex(-1)
                                                    setConfig(EMPTY_AIR_MODEL_CONFIG)
                                                }}
                                            />
                                        </span>
                                        <p className='ml-2 font-bold text-ai-700'>Import AIR models</p>
                                    </div>
                                </div>
                                {isImporting && (
                                    <React.Fragment>
                                        {files && files.length > 0 && (
                                            <FileUploader
                                                multiple
                                                handleChange={onImport}
                                                name='file'
                                                maxFileSize={10}
                                                classes={'bg-white'}
                                            />
                                        )}
                                        <div className='rounded bg-ai-100'>
                                            <table aria-label='urls' className='border border-ai-200'>
                                                <thead className='border-b border-b-ai-200 bg-white text-left'>
                                                    <tr>
                                                        <th className='w-1 px-4 py-2'>
                                                            <span className='text-[16px]  text-ai-800'>#</span>
                                                        </th>
                                                        <th className='w-full'>
                                                            <span className='text-[16px]  text-ai-800'>AIR file</span>
                                                        </th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='text-left'>
                                                    {(!files || files.length === 0) && (
                                                        <tr>
                                                            <td colSpan={4}>
                                                                <FileUploader
                                                                    classes={'bg-white w-full'}
                                                                    multiple
                                                                    handleChange={onImport}
                                                                    name='file'
                                                                    maxFileSize={10}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {files &&
                                                        files.map((file: File, index: number) => {
                                                            return (
                                                                <tr
                                                                    key={file.name + index.toString() + '-airfile'}
                                                                    className={index % 2 === 0 ? 'bg-ai-200' : ''}
                                                                >
                                                                    <td className='p-4 align-top'>
                                                                        <p className=' text-ai-800'>{index + 1 + '.'}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className=' text-ai-800'>{file.name}</p>
                                                                    </td>
                                                                    <td>
                                                                        <button className='btn-icon' onClick={() => onEditImport(index)}>
                                                                            {editingImportIndex === index ? (
                                                                                <CheckOutlined />
                                                                            ) : (
                                                                                <EditOutlined fontSize='small' />
                                                                            )}
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            className='btn-icon'
                                                                            onClick={() => onRemoveImportFile(index)}
                                                                        >
                                                                            <Remove color='error' fontSize='small' />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                        <div className='flex'>
                            <div className='flex flex-1'>
                                <div
                                    onClick={() => setTab(0)}
                                    className={`flex cursor-pointer items-center rounded-b-none rounded-t-md px-4 py-2 ${
                                        tab === 0 && 'bg-white font-bold'
                                    }`}
                                >
                                    <span>
                                        <EditOutlined className='text-ai' sx={{ fontSize: '16px' }} />
                                    </span>
                                    <p className='ml-2 text-ai'>{'AIR config steps'}</p>
                                </div>
                                <div
                                    onClick={() => setTab(1)}
                                    className={`ml-2 flex cursor-pointer items-center rounded-b-none rounded-t-md px-4 py-2 ${
                                        tab === 1 && 'bg-white font-bold'
                                    }`}
                                >
                                    <span>
                                        <Code fontSize='small' className='text-ai' sx={{ fontSize: '16px' }} />
                                    </span>
                                    <p className='ml-2 text-ai'>JSON config</p>
                                </div>
                            </div>
                            {modelCardType === 'aiautomation' && (
                                <div className='self-end'>
                                    {!create && (
                                        <button
                                            disabled={!isReadyForJob() || runJob.isLoading}
                                            className='btn-primary mb-1 py-1 text-sm'
                                            onClick={onRunJob}
                                        >
                                            {runJob.isLoading ? <TwoSeventyRing height={20} width={20} color='#F1F3F8' /> : 'Run Job'}
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={`rounded-md bg-white p-4 ${tab === 0 && 'rounded-tl-none'}`}>
                            {modelCardType && (
                                <div>
                                    {tab === 0 &&
                                        filteredConfigSteps.map((step, index) => {
                                            return (
                                                <div className='my-2' key={step.className + index + '-config-step'}>
                                                    <div
                                                        id={`edit-section-${step.className}`}
                                                        onClick={onStepSelected.bind(this, index, step as ConfigStep)}
                                                        key={step.className + index}
                                                        className={`flex cursor-pointer items-center gap-3 p-3 ${
                                                            selectedStep.index === index
                                                                ? 'rounded-t-md bg-ai-700 hover:bg-ai-800'
                                                                : 'rounded-md border border-ai-200 hover:bg-ai-50'
                                                        }`}
                                                    >
                                                        <span>
                                                            {selectedStep.index === index ? (
                                                                <KeyboardArrowUpRounded
                                                                    sx={{ fontSize: '30px' }}
                                                                    className={`${
                                                                        selectedStep.index === index ? 'text-white' : 'text-ai-700'
                                                                    }`}
                                                                />
                                                            ) : (
                                                                <KeyboardArrowDownRounded
                                                                    sx={{ fontSize: '30px' }}
                                                                    className={`${
                                                                        selectedStep.index === index ? 'text-white' : 'text-ai-700'
                                                                    }`}
                                                                />
                                                            )}
                                                        </span>
                                                        <div
                                                            className={`flex flex-1 flex-col ${
                                                                selectedStep.index === index ? 'text-white' : 'text-ai-700'
                                                            }`}
                                                        >
                                                            <p className='text-[18px] font-bold'>{step.label}</p>
                                                            <p className='text-[13px]'>{step.description}</p>
                                                        </div>
                                                        {modelHasStep(step as ConfigStep) && (
                                                            <div className='flex items-center gap-2'>
                                                                <span className='h-2 w-2 rounded-full bg-green-500' />
                                                                <p
                                                                    className={`${
                                                                        selectedStep.index === index ? 'text-white' : 'text-ai'
                                                                    } text-[13px]`}
                                                                >
                                                                    {getModelStepClass(step as ConfigStep)}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {selectedStep.index === index && (
                                                        <div className='flex flex-col gap-1 rounded-b-md bg-ai-50 p-6'>
                                                            {renderStepComponent()}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    {tab === 1 && (
                                        <JSONTree
                                            shouldExpandNode={() => {
                                                return true
                                            }}
                                            theme={JSONTreeTheme}
                                            data={config}
                                        />
                                    )}
                                </div>
                            )}
                            {create && !modelCardType && (
                                <div className='my-4 text-center'>
                                    <p>Please select a model card type to proceed.</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='flex min-h-full w-4/12 flex-col gap-4 bg-white px-5 py-8 shadow-[0_0_20px_-5px_rgba(0,0,0,0.15)]'>
                        <p className='text-[18px] font-bold text-ai-700'>Details</p>
                        <div>
                            {create && (
                                <React.Fragment>
                                    <label htmlFor='model-name' className='block px-2 text-[13px] text-ai'>
                                        Model Name
                                    </label>
                                    <input
                                        id='model-name'
                                        className='input w-full'
                                        placeholder='Name'
                                        name='name'
                                        value={config.params.name}
                                        onChange={handleFieldOnChange}
                                    />
                                </React.Fragment>
                            )}
                            {!create && (
                                <React.Fragment>
                                    <label htmlFor='model-name' className='block text-[13px] text-ai-800'>
                                        Model Name
                                    </label>
                                    <label htmlFor='model-name' className='block font-bold text-ai-800'>
                                        {config.params.name}
                                    </label>
                                </React.Fragment>
                            )}
                        </div>
                        <hr className='border-ai-200' />
                        <div>
                            <label htmlFor='model-name' className={`block text-[13px] ${create ? 'px-2 text-ai' : 'text-ai-800'}`}>
                                Description
                            </label>
                            <textarea
                                onChange={onDescriptionChange}
                                id='model-name'
                                className='input h-[150px] w-full text-[13px]'
                                placeholder='Description'
                                value={description}
                            />
                            {!create && (
                                <div className='flex justify-end gap-4'>
                                    <button className='btn-primary p-2 text-sm' onClick={saveModelDescription}>
                                        Update description
                                    </button>
                                </div>
                            )}
                        </div>
                        <hr className='border-ai-200' />
                        <div>
                            <label htmlFor='model-name' className='block text-[13px] text-ai-800'>
                                Author
                            </label>
                            <div>
                                <label htmlFor='model-name' className='block text-[13px] font-bold text-ai-800'>
                                    {config.params.author}
                                </label>
                            </div>
                        </div>
                        {!create && _model && (
                            <>
                                <hr className='border-ai-200' />
                                <div className='flex gap-8'>
                                    <div>
                                        <label htmlFor='model-name' className='block text-[13px] text-ai-800'>
                                            Created
                                        </label>
                                        <label htmlFor='model-name' className='block text-[13px] font-bold text-ai-800'>
                                            {new Date(_model.createdAt).toLocaleDateString()}
                                        </label>
                                    </div>
                                    <div>
                                        <label htmlFor='model-name' className='block text-[13px] text-ai-800'>
                                            Updated
                                        </label>
                                        <label htmlFor='model-name' className='block text-[13px] font-bold text-ai-800'>
                                            {new Date(_model.modifiedAt).toLocaleDateString()}
                                        </label>
                                    </div>
                                </div>
                            </>
                        )}
                        {modelCardType === 'leanai' && (
                            <div className='flex items-center text-ai'>
                                <span className='mr-2'>
                                    <Checkbox onChange={onAutorunChange} checked={config.params.autoRun === 'true'} />
                                </span>
                                <p>Run automatically</p>
                            </div>
                        )}
                        {modelCardType === 'leanai' && (
                            <>
                                <hr className='border-ai-200' />
                                <p className='text-[18px] font-bold text-ai-700'>URL List</p>
                                <div className='flex items-center gap-2'>
                                    <div className='flex-1'>
                                        <input
                                            className='input w-full'
                                            type='text'
                                            placeholder='https://www.wikipedia.com/wiki/dog'
                                            value={url}
                                            onChange={onUrlFieldChange}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            className='btn-secondary flex items-center border border-ai p-2 text-[14px] font-bold'
                                            onClick={onAddUrlToWhitelist}
                                        >
                                            <AddCircleOutline sx={{ fontSize: '16px' }} />
                                            <span className='px-1'>Add</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='rounded bg-ai-100'>
                                    <table aria-label='urls' className='border border-ai-200'>
                                        <thead className='border-b border-b-ai-200 bg-white text-left'>
                                            <tr>
                                                <th className='w-1 px-4 py-2'>
                                                    <span className='text-[16px]  text-ai-800'>#</span>
                                                </th>
                                                <th className='w-full'>
                                                    <span className='text-[16px]  text-ai-800'>URL</span>
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className='text-left'>
                                            {(config.params.urls === undefined ||
                                                config.params.urls === '*' ||
                                                config.params.urls.length === 0) && (
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className='p-6 text-center font-[16px] text-ai-800'>
                                                            Model will run on any content by default. Add URLs to this list to allow running
                                                            the model only on specific contents.
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}

                                            {config.params.urls &&
                                                Array.isArray(config.params.urls) &&
                                                config.params.urls.map((source: string, index: number) => {
                                                    return (
                                                        <tr key={source + index} className={'bg-ai-50'}>
                                                            <td className='p-4 align-top'>
                                                                <p className=' text-ai-800'>{index + 1 + '.'}</p>
                                                            </td>
                                                            <td>
                                                                <p className=' w-[25ch] truncate text-ai-800'>{source}</p>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className='btn-icon'
                                                                    onClick={() => onRemoveUrlFromWhitelist(index)}
                                                                >
                                                                    <Remove color='error' />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                        {modelCardType === 'aiautomation' && (
                            <>
                                <hr className='border-ai-200' />
                                <p className='text-[18px] font-bold text-ai-700'>Email List</p>
                                <div className='flex items-center gap-2'>
                                    <div className='flex-1'>
                                        <input
                                            className='input w-full'
                                            type='text'
                                            placeholder='michael.jordan@squared.ai.com'
                                            value={email}
                                            onChange={onEmailFieldChange}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            className='btn-secondary flex items-center border border-ai p-2 text-[14px] font-bold'
                                            onClick={onAddToEmailList}
                                        >
                                            <AddCircleOutline sx={{ fontSize: '16px' }} />
                                            <span className='px-1'>Add</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='rounded bg-ai-100'>
                                    <table aria-label='emails' className='border border-ai-200'>
                                        <thead className='border-b border-b-ai-200 bg-white text-left'>
                                            <tr>
                                                <th className='w-1 px-4 py-2'>
                                                    <span className='text-[16px]  text-ai-800'>#</span>
                                                </th>
                                                <th className='w-full'>
                                                    <span className='text-[16px]  text-ai-800'>Email</span>
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className='text-left'>
                                            {(config.params.emails === undefined || config.params.emails.length === 0) && (
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className='p-6 text-center font-[16px] text-ai-800'>
                                                            Add email addresses to send email notifications when an ETL job finishes.
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}

                                            {config.params.emails &&
                                                Array.isArray(config.params.emails) &&
                                                config.params.emails.map((source: string, index: number) => {
                                                    return (
                                                        <tr key={source + index} className={'bg-ai-50'}>
                                                            <td className='p-4 align-top'>
                                                                <p className=' text-ai-800'>{index + 1 + '.'}</p>
                                                            </td>
                                                            <td>
                                                                <p className=' w-[25ch] truncate text-ai-800'>{source}</p>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className='btn-icon'
                                                                    onClick={() => onRemoveEmailFromEmailList(index)}
                                                                >
                                                                    <Remove color='error' />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                        {create && (
                            <div className='flex gap-6'>
                                <div className='flex-1'>
                                    <button id='Submit-model' className='btn-primary w-full' onClick={onCreateSubmit}>
                                        {files && files.length > 0 ? 'Import Models' : 'Submit model'}
                                    </button>
                                </div>
                                <div>
                                    <button className='btn-secondary' onClick={() => navigate(ROUTER_PATHS.workspace)}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </DashboardLayout>
        </div>
    )
}

export default ModelEdit
