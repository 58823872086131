import { AddCircleOutline, ExpandLess, ExpandMore, Remove } from "@mui/icons-material";
import React from "react";

interface StyleParamsProps {
    style: any
    onStyleChange: (prop: string, attrb: string, value: string | null) => void
}

const StyleParams: React.FC<StyleParamsProps> = ({ style, onStyleChange }) => {

    const [openIndex, setOpenIndex] = React.useState(-1)
    const [params, setParams] = React.useState({
        attrb: "",
        value: ""
    })

    const getSortedTitles = (arr: any[]): any[] => {
        return arr.sort();
    }

    const getFormattedTitle = (str: string): string => {
        return str
        // clean camel case
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function(str){ return str.toUpperCase(); })
    }

    const onOpenSection = (index: number) => { 
        setOpenIndex(index === openIndex ? -1 : index)
    }

    const onFieldChange = (e: any) => {
        setParams({...params, [e.target.name]: e.target.value})
    }

    const onAdd = (prop: string) => {
        if(params.attrb.length > 0 && params.value.length > 0) {
            setParams({
                attrb: "",
                value: ""
            })
            onStyleChange(prop, params.attrb, params.value)
        }
    }

    return (
        <div className="">
            <div className="flex flex-col gap-1">
                {
                    style && getSortedTitles(Object.keys(style)).map((prop, i) => {
                        return (
                            <div key={prop + i.toString()}>
                                <div className="border-b border-b-ai-200 mb-1 p-1">
                                    <button className="w-full btn-icon flex items-center gap-4" onClick={onOpenSection.bind(this, i)}>
                                        <p className="flex-1 text-ai font-bold">{getFormattedTitle(prop)}</p>
                                        { openIndex === i ?
                                            <ExpandLess />
                                            :
                                            <ExpandMore />
                                        }
                                    </button>
                                </div>
                                { openIndex === i &&
                                <div className={`flex flex-col gap-1 bg-ai-100 p-2`}>
                                    <div className="flex items-center gap-2">
                                        <div className="flex-1">
                                            <p className="text-[13px] text-ai-800">Attribute</p>
                                        </div>
                                        <div className="flex-1">
                                            <p className="text-[13px] text-ai-800">CSS value</p>
                                        </div>
                                    </div>
                                    <hr className="border-ai-200"/>
                                    <div className="flex gap-2 items-center">
                                        <div className="flex-1">
                                            <input name="attrb" value={params.attrb} onChange={onFieldChange} type={"text"} className="input p-1 text-sm w-full" placeholder="boxShadow"/>
                                        </div>
                                        <div className="flex-1">
                                            <input name="value" value={params.value} onChange={onFieldChange} type={"text"} className="input p-1 text-sm w-full" placeholder="rgba(149, 157, 165, 0.2) 0px 8px 24px"/>
                                        </div>
                                        <button onClick={onAdd.bind(this, prop)} className="btn-icon flex items-center">
                                            <AddCircleOutline sx={{ fontSize: "16px" }} />
                                        </button>
                                    </div>
                                    {
                                        style[prop] && 
                                        Object.keys(style[prop]).map((attrb, y) => {
                                            return (
                                                <div key={attrb + y} className="flex items-center gap-2">
                                                    <div className="flex-1">
                                                        <p className="text-ai text-[13px]">{attrb}</p>
                                                    </div>
                                                    <div className="flex-1">
                                                        <input type="text" name={attrb} value={style[prop][attrb]} className="input p-1 text-sm w-full" onChange={(e) => {onStyleChange(prop, attrb, e.target.value)}}/>
                                                    </div>
                                                    <button onClick={onStyleChange.bind(this, prop, attrb, null)} className="btn-icon flex items-center">
                                                        <Remove color="error" sx={{ fontSize: "16px" }} />
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default StyleParams;
