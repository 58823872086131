import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetCurrentUser, useGetUser } from '../../features/queries'
import { Group } from '../../features/types'
import DashboardLayout from '../../Layouts/DashboardLayout'
import ProtectedAction from '../../Layouts/ProtectedAction'
import { ROUTER_PATHS } from '../../Utils/constants'

const UserDetails: React.FC<{}> = () => {
    const { id } = useParams()
    const userQuery = useGetUser(id ?? '')
    const currentUserQuery = useGetCurrentUser()
    const navigate = useNavigate()

    const handleBackToUsers = () => {
        navigate(ROUTER_PATHS.users)
    }

    if (!userQuery.data && !currentUserQuery.data?.data) {
        return null
    }

    const user = id === undefined && currentUserQuery.data?.data ? currentUserQuery.data.data : userQuery.data

    if (!user) {
        return null
    }

    return (
        <DashboardLayout title={'User Management'}>
            {id && (
                <div className='mb-6 flex items-center'>
                    <p className='cursor-pointer text-sm text-ai-800 hover:underline' onClick={handleBackToUsers}>
                        Users
                    </p>
                    <p className='mx-2 text-sm text-ai-800'>/</p>
                    <p className='text-sm font-bold text-ai-800'>{`${user.givenName} ${user.familyName}`}</p>
                </div>
            )}
            <div className='flex bg-white p-4'>
                <div className='w-3/5 bg-ai-50 p-6 py-4'>
                    <h1 className='text-[24px] font-bold text-ai-700'>{id ? `${user.givenName} ${user.familyName}` : 'My Profile'}</h1>
                    <hr className='my-3 h-px border-0 bg-gray-300' />
                    <p className='font-bold text-ai-800'>User Details</p>
                    <div className='my-3'>
                        <p className='text-[12px] capitalize'>{'First Name'}</p>
                        <p className='rounded border border-gray-300 bg-ai-100 p-3 text-ai-800'>{user.givenName}</p>
                    </div>
                    <div className='my-3'>
                        <p className='text-[12px] capitalize'>{'Last Name'}</p>
                        <p className='rounded border border-gray-300 bg-ai-100 p-3 text-ai-800'>{user.familyName}</p>
                    </div>
                    <div>
                        <p className='text-[12px] capitalize'>{'Email'}</p>
                        <p className='rounded border border-gray-300 bg-ai-100 p-3 text-ai-800'>{user.email}</p>
                    </div>
                    <hr className='my-5 h-px border-0 bg-gray-300' />
                    {user.companyName && (
                        <>
                            <div>
                                <p className='text-[12px] capitalize'>{'Company'}</p>
                                <p className='rounded border border-gray-300 bg-ai-100 p-3 text-ai-800'>{user.companyName}</p>
                            </div>
                            <hr className='my-5 h-px border-0 bg-gray-300' />
                        </>
                    )}
                    {user.roleName && (
                        <div>
                            <p className='text-[12px] capitalize'>{'Role'}</p>
                            <p className='rounded border border-gray-300 bg-ai-100 p-3 text-ai-800'>{user.roleName}</p>
                        </div>
                    )}
                </div>
                <ProtectedAction permission={'List All Groups for User'}>
                    <div className='w-2/5 bg-ai-100 p-4'>
                        <p className='mb-8 font-bold text-ai-800'>User Groups</p>
                        {user.groups && user.groups.length > 0 ? (
                            user.groups.map((group: Group, index: number) => {
                                return (
                                    <p key={group.id} className='my-2 rounded border border-gray-300 bg-ai-100 p-3 text-ai-800'>{`${
                                        index + 1
                                    }. ${group.displayName}`}</p>
                                )
                            })
                        ) : (
                            <div className='flex h-full items-center'>
                                <p className='w-full text-center'>No groups to show</p>
                            </div>
                        )}
                    </div>
                </ProtectedAction>
            </div>
        </DashboardLayout>
    )
}

export default UserDetails
